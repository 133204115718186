<template>
    <el-select
        popper-class="tree-select"
        :popper-append-to-body="true"
        :value="valueTitle"
        :clearable="clearable"
        :disabled="disabled"
        @clear="clearHandle"
        :placeholder="placeholder"
        @focus="(event) => emitEventHandler('focus', event)"
    >
        <el-option :value="valueTitle" :label="valueTitle">
            <el-tree
                id="tree-option"
                ref="selectTree"
                :accordion="accordion"
                :data="options"
                :props="props"
                :node-key="props.value"
                :default-expanded-keys="expandedKeys"
                @node-click="handleNodeClick"
            >
            </el-tree>
        </el-option>
    </el-select>
</template>
<script>
export default {
    name: 'TreeSelect',
    props: {
        /* 配置项 */
        props: {
            type: Object,
            default: () => {
                return {
                    value: 'id',
                    label: 'name',
                    children: 'children',
                };
            },
        },
        /* 选项列表数据(树形结构的对象数组) */
        options: {
            type: Array,
            default: () => {
                return [];
            },
        },
        /* 初始值 */
        value: {
            default: () => {
                return null;
            },
        },
        /* 可清空选项 */
        clearable: {
            type: Boolean,
            default: () => {
                return true;
            },
        },
        /* 可编辑选项 */
        disabled: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        /* 自动收起 */
        accordion: {
            type: Boolean,
            default: () => {
                return true;
            },
        },
        title: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '请选择',
        },
    },
    data() {
        return {
            valueId: this.value,
            valueTitle: this.title,
            expandedKeys: [],
        };
    },
    mounted() {
        this.initHandle();
    },
    methods: {
        //设置初始值（反选）
        initHandle() {
            this.$nextTick(() => {
                const scrollWrap = document.querySelectorAll('.el-scrollbar .el-select-dropdown__wrap')[0];
                const scrollBar = document.querySelectorAll('.el-scrollbar .el-scrollbar__bar');
                scrollWrap.style.cssText = 'margin: 0px; max-height: none; overflow: hidden;';
                scrollBar.forEach((e) => {
                    e.style.width = 0;
                });
            });
        },
        setCurrentVlaue() {
            //只有一个节点时，默认展开
            if (this.options.length === 1) {
                this.expandedKeys = [this.options[0].id];
            }
            this.$nextTick(() => {
                if (!this.$refs.selectTree || this.options == null || this.options.length < 1) {
                    return;
                }
                if (this.valueId == null) {
                    this.valueTitle = null;
                } else {
                    this.$refs.selectTree.setCurrentKey(null);
                    const node = this.$refs.selectTree.getNode(this.valueId);
                    if (node) {
                        this.valueTitle = node.label;
                        this.$refs.selectTree.setCurrentKey(this.valueId);
                    }
                }
            });
        },
        // 切换选项
        handleNodeClick(node) {
            this.valueTitle = node[this.props.label];
            this.valueId = node[this.props.value];
            this.handleBlur();
        },
        // 清除选中
        clearHandle() {
            this.valueTitle = '';
            this.valueId = null;
            this.handleBlur();
        },
        /* 清空选中样式 */
        clearSelected() {
            const allNode = document.querySelectorAll('#tree-option .el-tree-node');
            allNode.forEach((element) => element.classList.remove('is-current'));
        },
        getSelectData() {
            if (this.valueId == null) {
                return null;
            }
            return {value: this.valueId, label: this.valueTitle};
        },
        handleBlur(event) {
            setTimeout(() => {
                this.$emit('blur', event);
                this.dispatch('ElFormItem', 'el.form.blur', [this.valueId]);
            }, 0);
        },
        emitEventHandler(event) {
            this.$emit(event, ...Array.from(arguments).slice(1));
        },
        dispatch(componentName, eventName, params) {
            let parent = this.$parent || this.$root;
            let name = parent.$options.componentName;

            while (parent && (!name || name !== componentName)) {
                parent = parent.$parent;

                if (parent) {
                    name = parent.$options.componentName;
                }
            }
            if (parent) {
                parent.$emit.apply(parent, [eventName].concat(params));
            }
        },
    },
    watch: {
        value() {
            this.valueId = this.value;
            this.setCurrentVlaue();
        },
        options() {
            this.setCurrentVlaue();
        },
        valueId(val) {
            this.$emit('input', val);
        },
    },
};
</script>

<style scoped>
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
    height: auto;
    max-height: 274px;
    padding: 0;
    overflow: hidden;
    overflow-y: auto;
}
.el-select-dropdown__item.selected {
    font-weight: normal;
}
ul li >>> .el-tree .el-tree-node__content {
    height: auto;
    padding: 0 20px;
}
.el-tree-node__label {
    font-weight: normal;
}
.el-tree >>> .is-current .el-tree-node__label {
    color: #409eff;
    font-weight: 700;
}
.el-tree >>> .is-current .el-tree-node__children .el-tree-node__label {
    color: #606266;
    font-weight: normal;
}
</style>
