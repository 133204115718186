import * as client from '@/client';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import _ from 'lodash';
import {authCheck, fileDownload, previewPdf} from '@/utils';
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);

// const $enum code =>

// Vue.prototype.$enum = (code) => store.dispatch('getEnumList', code);
// 全局定义

export default (Vue, store) => {
    Vue.prototype._ = _;
    Vue.prototype.$enum = code => store.dispatch('getEnumList', code);
    Vue.prototype.$client = client;
    Vue.prototype.$authCheck = authCheck;
    Vue.prototype.$dayjs = dayjs;
    Vue.prototype.$utils = {
        fileDownload,
        previewPdf,
    };
};
