<template>
    <div class="error-page-not-found">404 page not found</div>
</template>

<script>
export default {
    name: 'ErrorPageNotFound',
};
</script>


<style lang="less" scoped>
.error-page-not-found {
    font-size: 20px;
    text-align: center;
    color: rgb(192, 204, 218);
    padding-top: 30px;
}
</style>
