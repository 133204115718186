const socket = {
    state: {
        scheduleQueue: {},
        updateFlag: true,
    },
    mutations: {
        setSocketQueue(state, playload) {
            state.scheduleQueue[playload.id] = playload;
            state.updateFlag = !state.updateFlag;
        },
        removeSocketQueue(state, playload) {
            playload.forEach(key => {
                delete state.scheduleQueue[key];
            });
        }
    },
    getters: {
        refreshGanttQueue: state => type => {
            if (state.updateFlag === state.updateFlag) {
                return Object.values(state.scheduleQueue).filter(value =>
                    value.scheduleType === type || value.scheduleType === 0
                );
            }
        }
    },
};

export default socket;
