<template>
    <div class="block">
        <el-timeline>
            <el-timeline-item timestamp="2018/4/12" placement="top">
                <el-card>
                    <h4>1.0.1</h4>
                    <p>1.新增xxx</p>
                    <p>2.修复xxx</p>
                </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2018/4/1" placement="top">
                <el-card>
                    <h4>1.0.0</h4>
                    <p>1.新增xxx</p>
                    <p>2.修复xxx</p>
                </el-card>
            </el-timeline-item>
        </el-timeline>
    </div>
</template>

<script>
export default {
    name: 'UpdateLog'
};
</script>

<style scoped>

</style>
