/**
 * axios配置文件
 */

import axios from 'axios';
import qs from 'qs';
import isObject from 'lodash/isObject';
const axiosInstance = axios.create();

let vm, loading;
// axiosInstance.defaults.baseURL = '/api';
// axiosInstance.defaults.baseURL = process.env.NODE_ENV === 'production' ? '/elec/' : '';
//https://workflow.dev.ynlxkj.cn
axiosInstance.defaults.baseURL = process.env.BASE_URL;
axiosInstance.defaults.timeout = 0;
axios.defaults.headers.common.locale = axiosInstance.defaults.validateStatus = status => {
    return status >= 200 && status < 300;
};

// 去除所有字段的首尾空格
function trimObjValues(obj) {
    if (isObject(obj) && !(obj instanceof FormData)) {
        let isArray = Array.isArray(obj);
        return Object.keys(obj).reduce(
            (acc, curr) => {
                let item = obj[curr];
                if (typeof item === 'string') {
                    acc[curr] = item.trim();
                } else if (typeof item === 'object') {
                    acc[curr] = trimObjValues(item);
                } else {
                    acc[curr] = item;
                }
                return acc;
            },
            isArray ? [] : {}
        );
    }
    return obj;
}

axiosInstance.interceptors.request.use(config => {
    config.headers.token = vm.$store.state.token;
    config.headers.locale = vm.$store.state.app.language;
    if (!config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${vm.$store.state.jwtToken}`;
    }


    // 去除首尾空格
    config.data = trimObjValues(config.data);
    config.params = trimObjValues(config.params);

    if (config.method === 'get') {
        config.params = {
            _t: Date.now(),
            ...config.params
        };
        config.paramsSerializer = function(params) {
            return qs.stringify(params, {indices: false});
        };
    }
    if (config.ignorShowLoading === true) {
        return config;
    }
    loading = vm.$loading({
        fullscreen: true,
        text: '努力加载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });
    return config;
});

axiosInstance.interceptors.response.use(
    response => {
        if (loading) {
            loading.close();
        }

        if (response.config && (response.config.isFileDownload || response.config.skipInterceptor)) {
            return response;
        }

        if (response.data.code === 0 || response.data.code === true) {
            return response.data;
        }
        if (response.data.code === 401) {
            if (vm) {
                vm.$router.push('/login');
            } else {
                window.location.reload();
            }
            return;
        }
        //兼容flowable接口
        if (response.data.data) {
            return response.data;
        }
        if (!response.config.ignorErrorMessage) {
            vm.$message({
                message: response.data.msg || '操作失败！',
                type: 'error'
            });
        }

        return Promise.reject({
            ...response.data,
            _source: 'axios'
        });
        // return response;
    },
    error => {
        if (loading) {
            loading.close();
        }
        // eslint-disable-next-line
        if (error.response && error.response.status === 401) {
            if (vm) {
                vm.$router.push('/login');
            } else {
                window.location.reload();
            }
            // eslint-disable-next-line
        } else if (error.response && error.response.status === 912) {
            if (vm) {
                vm.$msgbox({
                    title: '提示',
                    type: 'error',
                    message: '没有相应权限',
                    callback: () => {
                        window.location.href = '/';
                    }
                });
            } else {
                window.location.href = '/';
            }
            // eslint-disable-next-line
        } else if (error.response && error.response.status === 500) {
            if (vm) {
                vm.$alert('服务器错误！请稍后再试或者联系管理员', '提示', {type: 'error'});
            }
        } else if (vm) {
            vm.$alert('发生错误！请稍后再试或者联系管理员', '提示', {type: 'error'});
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;

export const getVm = vueApp => {
    vm = vueApp;
};
