/**
 * 多语言文件
 *
 * 为了生成json，请勿修改模块导出方式
 */

module.exports = {
    // 应用级别多语言
    'app.browser.tip-text': 'For a better browsing experience, it is recommended that you use the latest version of Chrome browser',
    'app.browser.tip-text-chrome': 'Your browser version is lower, for a better browsing experience, it is recommended that you use the latest version of Chrome browser',
    'app.browser.download.appmarket': 'Click to download the Chrome browser',

    // 导航菜单
    'main.nav-menu.index': 'Index',
    'main.nav-menu.baseInfo-management': 'Basic Information Management',
    'main.nav-menu.baseInfo-management.aircraft-info': 'Aircraft Info',
    'main.nav-menu.baseInfo-management.planeType-info': 'PlaneType Info',
    'main.nav-menu.baseInfo-management.airport-info': 'Airport Info',
    'main.nav-menu.baseInfo-management.route-info': 'Route Info',
    'main.nav-menu.baseInfo-management.national-info': 'National Info',

    //common
    'common.prompt': 'Prompt',
    'common.nofiles': 'No Files',
    'common.export': 'Export',
    'common.remark': 'Remark',
    'common.time': 'Time',
    'common.refresh': 'Refresh',
    'common.company-name': 'RuiLi Airlines',
    'common.exit': 'Exit',
    'common.no-permission': 'You do not have permission to access',
    'common.login-expired': 'Login information has expired',
    'common.no-relate-permission': 'Insufficient Privileges',
    'common.server-error': '服务器错误！请稍后再试或者联系管理员',
    'common.other-error': '发生错误！请稍后再试或者联系管理员',
    'common.select': 'Select{item}',
    'schedule.form.level': 'level',
    'common.input': 'Please Entry {item}',
    'common.select.1': 'Select',
    'common.delete': 'Delete',
    'common.edit': 'Edit',
    'common.warning': 'Warning',
    'common.confirm.refresh': 'Are you sure you want to refresh?',
    'common.confirm.delete': 'Are you sure you want to Delete?',
    'common.confirm.blackList': '{item} Item In Black List Are you sure ？',
    'common.confirm.revision': 'Are you sure you want to Revision?',
    'common.confirm.commit': 'Are you sure you want to Commit Audit?',
    'common.confirm.close': 'Are you sure you want to Close?',
    'common.confirm.close.confirm': 'Are you sure you want to Close Confirm?',
    'common.confirm.cancel': 'Are you sure you want to Cancel Confirm?',
    'common.confirm.open': 'Are you sure you want to Open Confirm?',
    'common.confirm.receive': 'Are you sure you want to Receive Material?',
    'common.confirm.issue.material': 'Are you sure you want to Issue Material?',
    'common.confirm.signed': 'Are you sure you want to Sign?',
    'common.confirm.message': 'Are you sure you want to {message}?',
    'common.confirm.whether': 'Whether {message}？',
    'common.confirm.info': 'Confirm Information',
    'common.yes': 'Yes',
    'common.confirm': 'Confirm',
    'common.save': 'Save',
    'common.cancel': 'Cancel',
    'common.operate.success': 'success',
    'common.operate.failed': 'failed',
    'common.close': 'Close',
    'common.new': 'New',
    'common.detail': 'Detail',
    'common.search': 'Search',
    'common.reset': 'Reset',
    'common.comment': 'Comment',
    'common.audit': 'Audit',
    'common.signed': 'Sign',
    'common.audit.agree': 'Agree',
    'common.audit.reject': 'Reject',
    'common.upload.error': 'Upload Fail',
    'common.upload.hint.drag': 'Drop file here or ',
    'common.upload.hint.click': 'Click to upload',
    'common.upload.hint.tip': '{fileType} files with a size less than {maxSize}',
    'common.upload.exceed.error': 'Only can add up to {limit} file(s) totally',
    'common.upload.file-type.error': 'This type of file is not supported, only {fileType} files can be uploaded.',
    'common.no.data': 'No Data',
    'common.files': 'Files',
    'common.download': 'Download',
    'common.aircraft.model': 'Variant',
    'common.aircraft.family': 'Family',
    'common.approve.record': 'Approve Record',
    'common.search.panel': 'Advance Search',
    'common.view.info': 'View',
    'common.back.list': 'Go Back',
    'common.execute.man': 'Transactor',
    'common.next.execute.man': 'The Next People',
    'common.next.execute.all': 'All Transactors',
    'common.yesno.Y': 'Yes',
    'common.yesno.N': 'No',
    'common.yesno.1': 'Yes',
    'common.yesno.0': 'No',
    'common.validity.Y': 'Valid',
    'common.validity.N': 'Invalid',
    'common.approver': 'Approving Person',
    'common.approve.time': 'Approval Time',
    'common.attachment': 'Attachment',
    'common.amendment': 'Amendment',
    'common.delay': 'Delay',
    'common.type': 'Type',
    'common.upload': 'Upload',
    'common.check-all': 'All',
    'common.quart': 'Quart',
    'common.whether': 'Whether',
    'common.select.operation.data': 'Please Select the Operating Data',
    'common.selected': 'Selected',
    'common.no-select': 'No Selected',
    'common.print-tag-box': 'Box Print And TagPrint',
    'common.return': 'Return',
    'common.reason': 'Reason',
    'common.return.odd': 'Return Odd',
    'common.print': 'Print',
    'common.work-content': 'Work Content',
    'common.work-no': 'WO No.',
    'common.apply-by': 'Apply By',
    'common.apply-date': 'Apply Date',
    'common.approve-by': 'Approve By',
    'common.approve-date': 'Approve Date',
    'common.no-selected-view': 'No Package View',
    'common.select-view': 'Packaged View',
    'common.work-pack-no': 'Work Pack No.',
    'common.msg.error': 'Error Message',
    'common.is.delete': 'Deleted',
    'common.time.start': 'Start Time ',
    'common.time.end': 'End Time',
    'common.required': 'required',


    /**
     * 基础信息模块
     */
    //飞机信息管理
    'aircraft-info.table.number': 'number',
    'aircraft-info.table.type': 'type',
    'aircraft-info.table.code': 'code',
    'aircraft-info.table.layoutCode': 'layoutCode',
    'aircraft-info.table.company': 'company',
    'aircraft-info.table.sn': 'sn',
    'aircraft-info.table.product-date': 'product-date',
    'aircraft-info.table.into-date': 'into-date',
    'aircraft-info.table.conf': 'conf',
    'aircraft-info.table.update-user': 'update-user',
    'aircraft-info.table.update-date': 'update-date',
    'aircraft-info.form.keyword': 'keyword',


    //特业人员字段
    ////字段
    'staff-info.table.name': 'name',
    'staff-info.table.job-number': 'job number',
    'staff-info.table.sex': 'sex',
    'staff-info.table.nation': 'nation',
    'staff-info.table.country-name': 'country',
    'staff-info.table.country-id': 'country',
    'staff-info.table.country': 'country',
    'staff-info.table.position': 'position',
    'staff-info.table.technic-name': 'technic level',
    'staff-info.table.phone': 'phone',
    'staff-info.table.team-name': 'team',
    'staff-info.table.center': 'center',
    'staff-info.table.center-name': 'center',
    'staff-info.table.team': 'team',
    'staff-info.table.base-name': 'base',
    'staff-info.table.base': 'base',
    'staff-info.table.base-type': 'base type',
    'staff-info.table.birthday': 'birthday',
    'staff-info.table.birthplace': 'birthplace',
    'staff-info.table.updater-name': 'updater',
    'staff-info.table.update-time': 'update time',
    'staff-info.table.auth-position-name': 'auth poition',
    'staff-info.table.license-name': 'license',
    'staff-info.table.remark': 'remark',
    'staff-info.table.contact-address': 'contact address',
    'staff-info.table.enter-company-on': 'enter company time',
    'staff-info.table.domestic-region': 'domestic region',
    'staff-info.table.residential-address': 'residential address',
    'staff-info.table.post-code': 'postcode',
    'staff-info.table.action': 'action',
    'staff-info.tip.noData': 'noData',
    ////教育经历
    'staff-info.table.school': 'school',
    'staff-info.table.major': 'major',
    'staff-info.table.degree': 'degree',
    ////入职前经历
    'staff-info.table.companyName': 'company name',
    'staff-info.table.beginTime': 'begin time',
    'staff-info.table.endTime': 'end time',
    'staff-info.table.planeType': 'plane type',
    'staff-info.table.flyHour': 'fly hour',
    'staff-info.table.flyExperienceHour': 'fly experience hour',
    'staff-info.table.flyNightHour': 'fly night hour',
    'staff-info.table.raiseFallNumber': 'raise and fall number',
    ////不安全事件
    'staff-info.table.happenDate': 'happen date',
    'staff-info.table.accidentType': 'accident type',
    'staff-info.table.happenPlace': 'happen place',
    'staff-info.table.description': 'description',
    'staff-info.table.documentNo': 'document no',
    'staff-info.table.regOrFlightNo': 'register or flight No',
    'staff-info.table.uploadFile': 'upload file',
    'staff-info.table.updaterName': 'updater',
    'staff-info.table.updateTime': 'update time',
    'staff-info.button.upload': 'upload',
    'staff-info.upload.needDataId': 'please maintain the accident information first,before manage the accessory',

    ////奖惩记录
    'staff-info.table.getTime': 'get time',
    'staff-info.table.rewardOrPunishment': 'reward or punishment',
    'staff-info.table.reason': 'reason',
    'staff-info.table.acquisition': 'acquisition',
    //人员信息,tab页title
    'staff-info.tab.base-info': 'base info',
    'staff-info.tab.education': 'education experience',
    'staff-info.tab.work-experience': 'work experience',
    'staff-info.tab.accident': 'accident',
    'staff-info.tab.reward-and-punishment': 'reward and punishment',

    'calebdar.next-month': 'next-month',
    'calebdar.prev-month': 'prev-month',
};
