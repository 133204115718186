<template>
    <p class="error-page-not-found">{{ '您没有权限访问！' }}</p>
</template>

<script>
export default {
    name: 'ErrorUnauthorized',
    mounted() {
        this.$alert('您没有权限访问！', '提示', {
            type: 'warning'
        }).then(() => {
            this.$router.replace('/');
        }).catch(() => {
            this.$router.replace('/');
        });
    }
};
</script>

<style lang="less" scoped>
.error-page-not-found {
    font-size: 20px;
    text-align: center;
    color: rgb(192, 204, 218);
}
</style>
