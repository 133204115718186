/**
 * vuex状态树
 */
import {getLanguage} from '@/i18n';

export default {
    // 用户信息
    userInfo: {},
    //权限列表
    userAuth: [],
    //用户菜单
    userMenu: [],
    //登录类型
    loginType: 'custom',
    //枚举列表
    enumList: {},
    // 任务计数
    taskCount: {},

    token: sessionStorage.getItem('token'),
    jwtToken: sessionStorage.getItem('jwtToken'),

    i18nValue: getLanguage(),
    drag: {
        row: {},
        flag: false,
    },
};
