// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// 外部依赖
import Vue from 'vue';
import ElementUI from 'element-ui';
import VueAxios from 'vue-axios';
import contextmenu from 'v-contextmenu';
import '@/utils/errorLog'; // error log
// import 'el-bigdata-table';

// 组件
import AppIndex from './AppIndex';

// 辅助
import router from './router';
import store from './vuex';
import {getVm} from '@/client/axios';
import setVm from '@/utils/setVm';
import axios from './client/axios';
import {setToken} from '@/utils';

import i18n from '@/i18n';
import '@/utils/global-extends';
import '@/directives';
import icons from '@/icons';
// 样式
// 使用自定义主题
import 'element-ui/lib/theme-chalk/index.css';
import 'font-awesome/css/font-awesome.min.css';
import 'v-contextmenu/dist/index.css';

// 全局样式
import '@/assets/custom-theme/style/index.css';
import '@/assets/custom-theme/extends.less';
//dataV
import DataV from '@jiaminghi/data-view';

import ECharts from 'vue-echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/pie';
import 'echarts/lib/chart/scatter';
import 'echarts/lib/chart/effectScatter';
import 'echarts/lib/chart/radar';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/geo';

import 'echarts/extension/bmap/bmap.js';
// custom theme
import theme from '@/components/widgets/theme.js';

// registering custom theme
ECharts.registerTheme('ovilia-green', theme);

// 自定义主题扩展

// 插件使用
Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value),
    size: 'mini'
});

// register component to use
Vue.component('v-chart', ECharts);
Vue.use(VueAxios, axios);
Vue.use(contextmenu);
Vue.use(DataV);

Vue.config.productionTip = false;
setVm(Vue, store);
setToken();
/* eslint-disable no-new */
/*const vm = new Vue({
    el: '#app',
    i18n,
    router,
    store,
    components: {AppIndex},
    template: '<AppIndex/>'
});*/

const vm = new Vue({
    el: '#app',
    render: h => h(AppIndex),
    i18n,
    store,
    router,
});
getVm(vm);


//解决发送请求并没有使用catch捕获的时候报Uncaught (in promise)，为什么这么做参考axios.js
window.addEventListener('unhandledrejection', e => {
    if (e.reason && e.reason._source === 'axios') {
        e.preventDefault();
    }
});
// 挂载成功，设置全局变量。供不支持的浏览器在browser.js中判断
if (vm) {
    window.vmMounted = true;
    window.$vm = vm;
}
