/**
 * mutations
 */

import types from './mutation-types';

export default {
    [types.SET_USER_INFO](state, payload) {
        state.userInfo = payload || {};
    },
    [types.SET_TOKEN](state, payload) {
        state.token = payload || '';
    },
    [types.SET_JWTTOKEN](state, payload) {
        state.jwtToken = payload || '';
    },
    [types.SET_USER_ROLE](state, payload) {
        state.userRole = payload || '';
    },
    [types.SET_USER_MENU](state, payload) {
        state.userMenu = payload || [];
    },
    [types.SET_USER_AUTH](state, payload) {
        state.userAuth = payload || [];
    },
    [types.menuData](state, payload) {
        state.menu = payload.menu;
    },
    [types.i18nChanged](state, payload) {
        state.i18nValue = payload;
    },
    [types.updateCollapse](state, payload) {
        state.isCollapse = payload;
    },
    [types.updateHeaderHeight](state, payload) {
        state.headerHeight = payload;
    },
    [types.updateAsideWidth](state, payload) {
        state.asideWidth = payload;
    },
    [types.updateIsMobile](state, payload) {
        state.isMobile = payload;
    },
    [types.SET_ENUM_LIST](state, payload) {
        state.enumList[payload.code] = payload.data || [];
    },
    [types.SET_TASK_COUNT](state, payload) {
        state.taskCount = payload || {};
    },
    [types.clearState](state) {
        state.userInfo = {};
        state.token = '';
        state.userMenu = [];
        state.userAuth = [];
        sessionStorage.removeItem('token');
    },
};
