/**
 * 请求发送文件
 * @description 请求发送统一管理文件
 *
 * 命名规范
 * method + 资源 [+ 特性 + 条件 + 版本]
 *
 * method 包括 get、put、post、delete 等 http 的请求方法
 * 资源是 restful 的资源名词，如 app、user
 * 特性包括 list、all（其中list代表分页的列表，all代表不分页全部列表）
 * 条件用于获取特定资源，一般使用介词 by 加条件名称，如 byId
 * 版本用于标志同一个请求的不同版本，版本1可以省略，使用 V + 版本号 的形式
 *
 * 命名示例
 * getUser （获取当前用户）
 * getUserList （获取分页的用户列表）
 * getUserAll （获取全部用户列表）
 * getUserById （根据 id 获取一个用户）
 * getUserByIdV2 （根据 id 获取一个用户，使用 api v2 的版本）
 *
 * 参数规范
 * 作为路由一部分的则使用参数传入，作为请求参数的，则放置在对象中
 *
 * 参数示例
 * /api/v1/user/:id => getUserById(id)
 * /api/v1/userList?page=1&length=10 => getUserList({page, length})
 *
 */

import axios from './axios';
import qs from 'qs';
import id from 'element-ui/src/locale/lang/id';

export const serverUrl = axios.defaults.baseURL;

// 获取当前用户信息
export const getUserInfo = () => axios.get('sys/user/info');
//根据orgCode查询用户
export const queryUserByOrgCode = (orgCodes) => axios.post('sys/user/queryByOrgCode', orgCodes);
//根据角色id集合查询用户
export const queryUserByRoleCodeList = (roleCodeList) => axios.post('sys/user/queryByRoleCodeList', roleCodeList);

//菜单
export const getMenu = () => axios.get('sys/menu/nav');

export const doLogin = (params) => axios.post('sys/login', params);
export const doLogout = (params) => axios.post('sys/logout', params);
export const doUpdatePassword = (params) => axios.post('sys/user/password', params);
export const doResetPassword = (userId) => axios.post(`sys/user/resetPassword/${userId}`);
export const batchResetPassword = (ids) => axios.post(`sys/user/batchResetPassword`, ids);
//定时任务
//export const getScheduleList = data => axios.get('/base/dictionaries', {params: data});

export const getScheduleDataList = (data) => axios.get('/sys/schedule/list', {params: data});
export const deleteHandle = (ids) => axios.post('/sys/schedule/delete', ids);
export const pauseHandle = (ids) => axios.post('/sys/schedule/pause', ids);
export const resumeHandle = (ids) => axios.post('/sys/schedule/resume', ids);
export const runHandle = (ids) => axios.post('/sys/schedule/run', ids);

export const init = (id) => axios.get(`/sys/schedule/info/${id}`);
export const dataScheduleFormSubmit = (data) => axios.post(`/sys/schedule/${!data.jobId ? 'save' : 'update'}`, data);

export const getLogDataList = (data) => axios.get('/sys/scheduleLog/list', {params: data});
export const showErrorInfo = (id) => axios.get(`/sys/scheduleLog/info/${id}`);

//首页布局
export const getWidgets = () => axios.get('sys/widgets/listAll');
export const getLayouts = (groupFlag) => axios.get(`sys/layouts/getByGroup/${groupFlag}`);
export const saveLayouts = (data) => axios.post('sys/layouts', data);
export const deleteLayouts = () => axios.delete('sys/userLayouts/deleteMyLayout');

//数据字典管理
export const listDictionaryChildren = (code) => axios.get('/base/dictionaries', {params: {parentCode: code}});
export const listDictionaryChildrenArr = (codeArr) =>
    axios.get('/base/dictionaries/list', {params: {parentCode: codeArr}});
export const listDictionary = (data) => axios.get('/base/dictionaries', {params: data});
export const addDictionary = (data) => axios.post('/base/dictionaries', data);
export const editDictionary = (data) => axios.put(`/base/dictionaries/${data.id}`, data);
export const getDictionary = (id) => axios.get(`/base/dictionaries/${id}`);
export const deleteDictionary = (id) => axios.delete(`/base/dictionaries/${id}`);
//兼容老的取数据字典的方法
export const getDicData = (code) => {
    return listDictionaryChildren(code).then((result) => {
        result.data = result.data.map((d) => {
            return {itemKey: d.value, itemValue: d.name};
        });
        return result;
    });
};
export const getDicDataArr = (codeArr) => {
    return listDictionaryChildrenArr(codeArr).then((result) => {
        let data = {};
        for (let i in result.data) {
            let item = result.data[i] || [];
            data[i] = item.map((d) => ({itemKey: d.value, itemValue: d.name}));
        }
        result.data = data;
        return result;
    });
};
//获取所有可用部门
export const getDepartmentList = () => axios.get('/base/orgUnit/getDepartmentList');

//上传文件地址
export const uploadFileUrl = axios.defaults.baseURL + 'files';

//上传文件
export const uploadFile = (data) =>
    axios({
        method: 'post',
        url: 'files',
        data,
        headers: {'Content-Type': 'multipart/form-data'},
    });

// 业务管理methods
export const getAllWorkflow = (params) => axios.get('/business/workflow/allWork', {params});
export const getMyWorkflow = (params) => axios.get('/business/workflow/myCreateWork', {params});
export const getMyHandleTask = (params) => axios.get('/business/workflow/myHandleTask', {params});
export const getMyFinishTask = (params) => axios.get('/business/workflow/myFinishTask', {params});

// 删除未提交流程
export const deleteWorkflow = (id) => axios.post(`business/workflow/delete/${id}`);

export const getRoleFlow = () => axios.get('/base/flow/listByRole');

// 用户申请
export const getBusinessImg = (params) => axios.get('business/datumModel/apply', {params});
export const addBusinessApply = (data) => axios.post('business/businessApply/add', data);
export const changeBusinessApply = (cid) => axios.post('business/businessApply/change/' + cid);
export const getBusinessInfo = (id) => axios.get('business/businessApply/info/' + id);

// 供电方案
export const getElectricPlanInfo = (id) => axios.get('business/electricPlan/info/' + id);
export const addElectricPlanInfo = (data) => axios.post('business/electricPlan/add', data);

// 完成审核
export const completeReviewTask = (id, data) => axios.post('business/workflow/completeReviewTask/' + id, data);

// 完成通知任务
export const completeNoticeTask = (id) => axios.post('business/workflow/completeNotifyTask/' + id);

// 获取审核记录
export const getReviewRecords = (params) => axios.get('business/workflow/reviewLog', {params});

//WorkFlow
export const findAllWordFlow = () => axios.get('/business/workflow/allWork');
//我发起的
export const findMyCreateWork = () => axios.get('/business/workflow/myCreateWork');
//
export const getWorkFlow = (id) => axios.get(`/business/workflow/info/${id}`);

//验收申请
export const addCompletionApply = (data) => axios.post('business/completionApply/add', data);
export const getCompletionApply = (applyId) => axios.get(`business/completionApply/info/${applyId}`);

//竣工验收
export const addCompletionAcceptance = (data) => axios.post('business/completionAcceptance/add', data);
export const getCompletionAcceptance = (applyId) => axios.get(`business/completionAcceptance/info/${applyId}`);

//变电站-设备台帐
export const addSubstations = (data) => axios.post('/business/substations', data);

export const listEquipmentCustomer = () => axios.get('/business/equipments/customers');

//归档
export const addDatumArchive = (data) => axios.post('business/datumArchive/add', data);
export const getDatumArchive = (applyId) => axios.get(`business/datumArchive/info/${applyId}`);

//台账管理
export const getEquipmentList = (params) => axios.get('/business/equipments/page', {params});
export const getEquipment = (mainCategory, id) => axios.get(`/business/equipments/${mainCategory}/${id}`);
export const updateEquipmentState = (mainCategory, id, data) =>
    axios.put(`/business/equipments/${mainCategory}/${id}/state`, qs.stringify(data));
//主变电站
export const insertMainSubstation = (data) => axios.post('/business/mainSubstations/save', data);
export const updateMainSubstation = (id, data) => axios.put(`/business/mainSubstations/${id}`, data);
export const deleteMainSubstation = (id) => axios.delete(`/business/mainSubstations/${id}`);
export const getMainSubstation = (id) => axios.get(`/business/mainSubstations/${id}`);
//变电站
export const insertSubstation = (data) => axios.post('/business/substations', data);
export const updateSubstation = (id, data) => axios.put(`/business/substations/${id}`, data);
export const getSubstation = (id) => axios.get(`/business/substations/${id}`);
export const deleteSubstation = (id) => axios.delete(`/business/substations/${id}`);

//变电站-高压侧开关柜设备
export const getHighUnitEquipments = (highUnitId) =>
    axios.get(`/business/substations/highUnits/${highUnitId}/equipments`);
export const insertHighUnitEquipment = (highUnitId, data) =>
    axios.post(`/business/substations/highUnits/${highUnitId}/equipments`, data);
export const updateHighUnitEquipment = (highUnitId, equipmentId, data) =>
    axios.put(`/business/substations/highUnits/${highUnitId}/equipments/${equipmentId}`, data);
export const deleteHighUnitEquipment = (highUnitId, equipmentId) =>
    axios.delete(`/business/substations/highUnits/${highUnitId}/equipments/${equipmentId}`);
//变电站-低压侧环网柜设备盒
export const getLowUnitBoxes = (lowUnitId) => axios.get(`/business/substations/lowUnits/${lowUnitId}/boxes`);
export const insertLowUnitBox = (lowUnitId, data) =>
    axios.post(`/business/substations/lowUnits/${lowUnitId}/boxes`, data);
export const updateLowUnitBox = (lowUnitId, boxId, data) =>
    axios.put(`/business/substations/lowUnits/${lowUnitId}/boxes/${boxId}`, data);
export const deleteLowUnitBox = (lowUnitId, boxId) =>
    axios.delete(`/business/substations/lowUnits/${lowUnitId}/boxes/${boxId}`);
//变电站-低压侧环网柜设备盒
export const getLowUnitBoxEquipments = (boxId) => axios.get(`/business/substations/lowUnits/boxes/${boxId}/equipments`);
export const insertLowUnitBoxEquipment = (boxId, data) =>
    axios.post(`/business/substations/lowUnits/boxes/${boxId}/equipments`, data);
export const updateLowUnitBoxEquipment = (boxId, equipmentId, data) =>
    axios.put(`/business/substations/lowUnits/boxes/${boxId}/equipments/${equipmentId}`, data);
export const deleteLowUnitBoxEquipment = (boxId, equipmentId) =>
    axios.delete(`/business/substations/lowUnits/boxes/${boxId}/equipments/${equipmentId}`);

//杆塔
export const insertTowerPole = (data) => axios.post('/business/towerPoles/save', data);
export const updateTowerPole = (id, data) => axios.put(`/business/towerPoles/${id}`, data);
export const getTowerPole = (id) => axios.get(`/business/towerPoles/${id}`);
export const deleteTowerPole = (id) => axios.delete(`/business/towerPoles/${id}`);

//杆上开关
export const insertTowerPoleSwitch = (data) => axios.post('/business/towerPoleSwitches/save', data);
export const updateTowerPoleSwitch = (id, data) => axios.put(`/business/towerPoleSwitches/${id}`, data);
export const getTowerPoleSwitch = (id) => axios.get(`/business/towerPoleSwitches/${id}`);
export const deleteTowerPoleSwitch = (id) => axios.delete(`/business/towerPoleSwitches/${id}`);

//环网柜
export const insertRingMainUnits = (data) => axios.post('/business/ringMainUnits', data);
export const getRingMainUnits = (id) => axios.get(`/business/ringMainUnits/${id}`);
export const updateRingMainUnits = (data) => axios.put(`/business/ringMainUnits/${data.id}`, data);
export const deleteRingMainUnits = (id) => axios.delete(`/business/ringMainUnits/${id}`);

//获取环网柜的柜体列表
export const getRingMainUnitsBoxes = (id) => axios.get(`/business/ringMainUnits/${id}/boxes`);
//新增环网柜柜体信息
export const insertRingMainUnitsBoxes = (id, data) => axios.post(`/business/ringMainUnits/${id}/boxes`, data);
//更新环网柜柜体信息
export const updateRingMainUnitsBoxes = (id, boxId, data) =>
    axios.put(`/business/ringMainUnits/${id}/boxes/${boxId}`, data);
//删除环网柜柜体信息
export const deleteRingMainUnitsBoxes = (id, boxId) => axios.delete(`/business/ringMainUnits/${id}/boxes/${boxId}`);
//获取环网柜的柜体的开关列表
export const getRingMainUnitsSwitches = (id, boxId) =>
    axios.get(`/business/ringMainUnits/${id}/boxes/${boxId}/switches`);
//新增环网柜的柜体的开关
export const insertRingMainUnitsSwitches = (id, boxId, data) =>
    axios.post(`/business/ringMainUnits/${id}/boxes/${boxId}/switches`, data);
//更新环网柜的柜体的开关
export const updateRingMainUnitsSwitches = (id, boxId, switchId, data) =>
    axios.put(`/business/ringMainUnits/${id}/boxes/${boxId}/switches/${switchId}`, data);
//删除环网柜的柜体的开关
export const deleteRingMainUnitsSwitches = (id, boxId, switchId) =>
    axios.delete(`/business/ringMainUnits/${id}/boxes/${boxId}/switches/${switchId}`);

//获取电网配置树
export const getPowerGridsTree = (data) => axios.get('/business/powerGrids/tree', data);
//获取电网配置树(带设备)
export const getPowerGridsTrees = (data) => axios.get('/business/powerGrids/trees', data);
//获取电网拓扑图
export const getPowerGridsTopology = () => axios.get('/business/powerGrids/topology');
//修改电网配置节点
export const updatePowerGrids = (id, data) => axios.put(`/business/powerGrids/${id}`, data);
//获取指定电网线路信息
export const getPowerGrids = (id) => axios.get(`/business/powerGrids/${id}`);
//保存电网线路
export const savePowerGrids = (data) => axios.post(`/business/powerGrids/`, data);
//删除电网线路
export const delPowerGrids = (id) => axios.delete(`/business/powerGrids/${id}`);
//获取已经审批完成的图纸申请列表
export const getDrawingApply = () => axios.get(`/business/drawingApply/list?drawingStatus=S`);
//获取对应申请下面的图纸
export const getDrawingApplyDetail = (applyId) => axios.get(`/business/drawing/listByApplyId`, {params: {applyId}});

//电网配置-地图

//查询电网中的拆线
export const getPowerGridsLines = () => axios.get(`/business/powerGridLines/list`);
//保存电网线路
export const savePowerGridsLines = (gridLineId, data) =>
    axios.post(`/business/powerGridLines/saveBatch/${gridLineId}`, data);
//重置拆线
export const resetPowerGridsLine = (gridLineId) => axios.delete(`/business/powerGridLines/reset/${gridLineId}`);

//查询电网中的设备
export const getPowerGridsEquipments = (gridId, mainCategory) =>
    axios.get(`/business/powerGrids/equipments`, {params: {gridId, mainCategory}});
//查询电网中子网的设备
export const getPowerGridsOffspringEquipments = (gridId, mainCategory) =>
    axios.get(`/business/powerGrids/equipments/listOffspring`, {params: {gridId, mainCategory}});
//查询电网中的线路
export const getPowerGridsWires = (gridId) => axios.get(`/business/powerGrids/equipments/wires`, {params: {gridId}});
//查询未在电网中使用到的设备
export const getEquipmentsUnused = (mainCategory) => axios.get(`/business/equipments/unused`, {params: {mainCategory}});
//查询配网中可以用作入线端的设备
export const getEquipmentsInputBoxes = (id) => axios.get(`/business/powerGrids/equipments/inputBoxes`, {params: {id}});
//增加主变电站
export const insertMainSubstationsPoint = (data) => axios.post('/business/powerGrids/equipments/mainSubstation', data);
//增加变电站
export const insertSubstationsPoint = (data) => axios.post('/business/powerGrids/equipments/substations', data);
//增加环网柜
export const insertRingMainUnitPoint = (data) => axios.post('/business/powerGrids/equipments/ringMainUnits', data);
//增加环网柜
export const insertTowerPolesPoint = (data) => axios.post('/business/powerGrids/equipments/towerPoles', data);
//更新主变电站
export const updateMainSubstationPoint = (id, data) =>
    axios.put(`/business/powerGrids/equipments/mainSubstation/${id}`, data);
//更新变电站
export const updateSubstationsPoint = (id, data) =>
    axios.put(`/business/powerGrids/equipments/substations/${id}`, data);
//更新环网柜
export const updateRingMainUnitsPoint = (id, data) =>
    axios.put(`/business/powerGrids/equipments/ringMainUnits/${id}`, data);
//更新杆塔
export const updateTowerPolesPoint = (id, data) => axios.put(`/business/powerGrids/equipments/towerPoles/${id}`, data);
//查询未在电网中使用到的主变电站
export const getPowerGridsMainSubstationsUnused = (data) =>
    axios.get('/business/powerGrids/equipments/mainSubstations/unused', data);
//查询未在电网中使用到的变电站
export const getPowerGridsSubstationsUnused = (data) =>
    axios.get('/business/powerGrids/equipments/substations/unused', data);
//删除单个设备
export const deletePowerGridsequipments = (id) => axios.delete(`/business/powerGrids/equipments/${id}`);
//查询未在电网中使用到的环网柜
export const getPowerGridsRingMainUnitUnused = (data) =>
    axios.get('/business/powerGrids/equipments/ringMainUnit/unused', data);
//查询未在电网中使用到的导线
export const getPowerGridsWiresUnused = () => axios.post(`/business/powerGrids/equipments/wires/unused`, {params: {}});
//查询未在电网中使用到的杆塔
export const getPowerGridsTowerPolesUnused = () =>
    axios.get(`/business/powerGrids/equipments/towerPoles/unused`, {params: {}});
//查询未在电网中使用到的开关
export const getPowerGridsTowerPoleSwitchesUnused = () =>
    axios.get(`/business/powerGrids/equipments/towerPoleSwitches/unused`, {params: {}});
//根据ID获取电网主变电站设备展示数据
export const getPowerGridsEquipmentsMainSubstation = (id) =>
    axios.get(`/business/powerGrids/equipments/mainSubstation/${id}`);
//根据ID获取电网变电站设备展示数据
export const getPowerGridsEquipmentsDetail = (id) =>
    axios.get(`/business/powerGrids/equipments/powerGridSubstation/${id}`);
//根据ID获取环网柜设备展示数据
export const getPowerGridsEquipmentsRingMainUnits = (id) =>
    axios.get(`/business/powerGrids/equipments/ringMainUnits/${id}`);
//根据ID获取杆塔设备展示数据
export const getPowerGridsEquipmentsTowerPoles = (id) => axios.get(`/business/powerGrids/equipments/towerPoles/${id}`);
//根据ID获取电网变电站设备展示数据
export const getPowerGridsEquipmentsSubstations = (id) =>
    axios.get(`/business/powerGrids/equipments/substations/${id}`);
//环网柜详情（带实时运行数据)
export const getPowerGridsRingMainUnitDetail = (id) => axios.get(`/business/powerGrids/equipments/ringMainUnits/${id}`);
//箱式变电站详情（带实时运行数据）
export const getPowerGridsSubstationDetail = (id) =>
    axios.get(`/business/powerGrids/equipments/substations/${id}/detail`);
//环网柜实时数据（开关状态、平均电流、平局电压）
export const queryRingMainUnitRealData = (id) =>
    axios.get(`/business/powerGrids/equipments/queryRingMainUnitRealData/${id}`);

//导线
export const insertWire = (data) => axios.post('/business/wires', data);
export const updateWire = (id, data) => axios.put(`/business/wires/${id}`, data);
export const getWire = (id) => axios.get(`/business/wires/${id}`);
export const deleteWire = (id) => axios.delete(`/business/wires/${id}`);

//图纸管理
export const getDrawingApplyList = (params) => axios.get('/business/drawingApply/getList', {params});
export const getDrawingList = (params) => axios.get('/business/drawing/listByApplyId', {params});
export const getDrawingApplyInfo = (applyId) => axios.get(`/business/drawingApply/info/${applyId}`);
export const saveOrSubmitDrawingApply = (data) => axios.post('/business/drawingApply/saveOrSubmit', data);
export const restartDrawingApply = (data) => axios.post('/business/drawingApply/restartDrawingApply', data);
export const delDrawingApply = (data) => axios.post('/business/drawingApply/delete', data);
export const getListFromDrawAndCopy = (applyId) =>
    axios.get(`/business/drawingApply/getListFromDrawAndCopy/${applyId}`);

//手册管理
export const getHandbookList = (params) => axios.get('/business/handbook/list', {params});
export const getHandbookInfo = (id) => axios.get(`/business/handbook/info/${id}`);
export const saveHandbook = (data) => axios.post('/business/handbook/save', data);
export const delHandbook = (data) => axios.post('/business/handbook/delete', data);
//巡检管理
export const getPatrolList = (params) => axios.get('/business/patrols', {params});
export const getPatrolInfo = (id) => axios.get(`/business/patrols/${id}`);
export const savePatrol = (data) => axios.post('/business/patrols', data);
export const updatePatrol = (data) => axios.put(`/business/patrols/${data.id}`, data);
export const delPatrol = (id) => axios.delete(`/business/patrols/${id}`);
//巡检台账管理
export const getPatrolAssignmentList = (params) => axios.get('/business/patrolAssignments', {params});
export const getPatrolAssignmentInfo = (id) => axios.get(`/business/patrolAssignments/${id}`);
export const savePatrolAssignment = (data) => axios.post('/business/patrolAssignments', data);
export const updatePatrolAssignment = (data) => axios.put(`/business/patrolAssignments/${data.id}`, data);
export const delPatrolAssignment = (id) => axios.delete(`/business/patrolAssignments/${id}`);
//巡检台账异常详细
export const getpatrolAssignmentExpDetailList = (params) => axios.get('/business/patrolAssignmentExpDetails', {params});
export const getpatrolAssignmentExpDetailInfo = (id) => axios.get(`/business/patrolAssignmentExpDetail/${id}`);
export const savepatrolAssignmentExpDetail = (data) => axios.post('/business/patrolAssignmentExpDetail', data);
export const updatepatrolAssignmentExpDetail = (data) =>
    axios.put(`/business/patrolAssignmentExpDetail/${data.id}`, data);
export const delpatrolAssignmentExpDetail = (id) => axios.delete(`/business/patrolAssignmentExpDetail/${id}`);

export const getPatrolAssignmenMainStationInfo = (id) =>
    axios.get(`/business/patrolAssignmentMainSubstations/getByAssignmentId/${id}`);
export const savePatrolAssignmenMainStation = (data) => axios.post('/business/patrolAssignmentMainSubstations', data);
//巡检台账作业检查项管理
export const getPatrolAssignmenItemList = (params) => axios.get('/business/patrolAssignmentItems/list', {params});
export const getPatrolAssignmenItemPage = (params) => axios.get('/business/patrolAssignmentItems', {params});
export const getPatrolAssignmenItemInfo = (id) => axios.get(`/business/patrolAssignmentItems/${id}`);
export const delPatrolAssignmenItem = (data) => axios.delete('/business/patrolAssignmentItems/batch', data);
//巡检作业模版管理
export const getPatrolAssignmenModelList = (params) => axios.get('/business/patrolAssignmenModels', {params});
export const getPatrolAssignmenModelInfo = (id) => axios.get(`/business/patrolAssignmenModels/${id}`);
export const savePatrolAssignmenModel = (data) => axios.post('/business/patrolAssignmenModels', data);
export const delPatrolAssignmenModel = (data) => axios.delete('/business/patrolAssignmenModels/batch', data);
//系统管理
export const getRoleList0 = () => axios.get('/sys/role/select');
export const getUserList0 = (params) => axios.get('/sys/user/list', {params});
export const getOrgUserTree = (params) => axios.get('/base/orgUnitUser/userTreeByCondition', {params});
export const getUserListByRoleId = (params) => axios.get('/sys/user/listByRoleId', {params});
export const delUser = (data) => axios.post('/sys/user/delete', data);
//根据ID查询用户信息
export const getUserById = (id) => axios.get(`/sys/user/info/${id}`);
export const saveUser = (data) => axios.post('/sys/user/save', data);
export const updateUser = (data) => axios.post('/sys/user/update', data);
export const getUserByOrgId = (orgId) => axios.get('/base/orgUnitUser/listUserByOrgId', {params: {orgId}});
export const getUserByOrgCode = (orgCode) => axios.get('/base/orgUnitUser/listUserByOrgCode', {params: {orgCode}});
export const delUserInRole = (roleId, userIds) => axios.post(`/sys/role/deleteUser/${roleId}/${userIds}`);

export const getRoleAdminList = (params) => axios.get('/sys/role/list', {params});
export const getAllRoleList = () => axios.get('/sys/role/selectAll');
export const delRole = (data) => axios.post('/sys/role/delete', data);
export const getRoleById = (id) => axios.get(`/sys/role/info/${id}`);
export const saveRole = (data) => axios.post('/sys/role/save', data);
export const saveRoleMenus = (data) => axios.post('/sys/role/saveMenus', data);
export const addRoleUsers = (data) => axios.post('/sys/role/addUser', data);
export const updateRole = (data) => axios.post('/sys/role/update', data);
export const getMenuList = (params) => axios.get('/sys/menu/list', {params});
export const getMenuList2 = (params) => axios.get('/sys/menu/list2', {params});
export const getSelectMenuList = (params) => axios.get('/sys/menu/select', {params});
export const delMenu = (id) => axios.post(`/sys/menu/delete/${id}`);
export const delMenuBatch = (ids) => axios.post(`/sys/menu/deleteList`, ids);
export const getMenuById = (id) => axios.get(`/sys/menu/info/${id}`);
export const saveMenu = (data) => axios.post('/sys/menu/save', data);
export const updateMenu = (data) => axios.post('/sys/menu/update', data);
export const getFlowList = (params) => axios.get('/base/flow/list', {params});
export const delFlow = (data) => axios.post('/base/flow/delete', data);
export const getFlowById = (id) => axios.get(`/base/flow/info/${id}`);
export const saveFlow = (data) => axios.post('/base/flow/save', data);
export const updateFlow = (data) => axios.post('/base/flow/update', data);
export const getOrgUnitListByAdmin = (params) => axios.get('/base/orgUnit/listByAdmin', {params});
export const getOrgUnitWithUser = (params) => axios.get('/base/orgUnit/listWithUser', {params});
export const delAllOrgUnit = (data) => axios.post('/base/orgUnit/deleteAll', data);
export const getOrgUnitById = (id) => axios.get(`/base/orgUnit/info/${id}`);
export const saveOrgUnit = (data) => axios.post('/base/orgUnit/saveAll', data);
export const updateOrgUnit = (data) => axios.post('/base/orgUnit/updateAll', data);
export const getOrgUnitByOrgTypeAndCode = (params) => axios.get('/base/orgUnit/listByOrgTypeAndCode', {params});

export const getOrgUnitListByParent = (params) => axios.get('/base/orgUnit/listByParent', {params});
export const getOrgUnitUserListByOrgId = (params) => axios.get('/base/orgUnitUser/listByOrgId', {params});
export const getPositionListByUserIdAndOrgId = (params) =>
    axios.get('/base/orgPositionUser/listByUserIdAndOrgId', {params});
export const getPositionListByOrgId = (params) => axios.get('/base/orgPosition/listByOrgId', {params});
export const saveUnitUser = (data) => axios.post('/base/orgUnitUser/saveAll', data);
export const updateUnitUser = (data) => axios.post('/base/orgUnitUser/updateInfo', data);
export const savePositionUser = (data) => axios.post('/base/orgPositionUser/saveAll', data);
export const updateOrgPosition = (data) => axios.post('/base/orgPosition/update', data);
export const saveOrgPosition = (data) => axios.post('/base/orgPosition/save', data);
export const updatePositionUser = (data) => axios.post('/base/orgPositionUser/updateInfo', data);
export const delOrgUnitUser = (data) => axios.post('/base/orgUnitUser/delete', data);
export const delOrgPositionUser = (data) => axios.post('/base/orgPositionUser/delete', data);
export const delOrgPosition = (data) => axios.post('/base/orgPosition/delete', data);
export const getUserListNotIn = (params) => axios.get('/sys/user/listAllNotIn', {params});
export const getPositionListNotIn = (params) => axios.get('/base/orgPosition/listByOrgIdNotIn', params);
export const getRawFlowList = () => axios.get('/app/rest/models?filter=processes&modelType=0&sort=modifiedDesc');
export const deployRawFlow = (id) => axios.post(`/app/flowable/deploy/${id}`);

export const getDictList = (params) => axios.get('/base/dict/list', {params});
export const delDict = (data) => axios.post('/base/dict/delete', data);
export const getDictById = (id) => axios.get(`/base/dict/info/${id}`);
export const saveDict = (data) => axios.post('/base/dict/save', data);
export const updateDict = (data) => axios.post('/base/dict/update', data);
export const getDictItemList = (params) => axios.get('/base/dictItem/list', {params});
export const delDictItem = (id) => axios.post(`/base/dictItem/delete/${id}`);
export const getDictItemListById = (id) => axios.get(`/base/dictItem/listByDictId/${id}`);
export const getDictItemById = (id) => axios.get(`/base/dictItem/${id}`);
export const saveDictItem = (data) => axios.post('/base/dictItem/save', data);
export const updateDictItem = (data) => axios.post('/base/dictItem/update', data);

//购电管理
export const addPowerPurchase = (data) => axios.post('/business/powerPurchase/add', data);
export const deletePowerPurchase = (data) => axios.post('/business/powerPurchase/delete', data);
export const getPowerPurchaseList = (params) => axios.get('/business/powerPurchase/list', {params});
export const getPowerFactor = (data) => axios.get('/business/powerPurchase/powerFactorList', data);

//告警台账
export const getAlarmList = (params) => axios.get('/business/alarmAccount/list', {params});
export const getAlarmEventDetail = (params) => axios.get('/business/alarmAccount/detail/list', {params});

//客户台账
export const getCustomerAccountList = (params) => axios.get('/business/customer/list', {params});

// 电价表
export const pageElectricityPrice = (params) => axios.get('business/electricityPrices/page', {params});
export const insertElectricityPrice = (data) => axios.post('/business/electricityPrices', data);
export const updateElectricityPrice = (id, data) => axios.put(`/business/electricityPrices/${id}`, data);
export const getElectricityPrice = (id) => axios.get(`/business/electricityPrices/${id}`);
export const deleteElectricityPrice = (id) => axios.delete(`/business/electricityPrices/${id}`);
export const deleteElectricityPrices = (ids) => axios.post(`/business/electricityPrices/batchDelete`, ids);

//力率调整电费表
export const pageFactorAdjustedPowerPrice = (params) => axios.get('business/factorAdjustedPowerPrices/page', {params});
export const listFactorAdjustedPowerPrice = () => axios.get('business/factorAdjustedPowerPrices/listAll');
export const insertFactorAdjustedPowerPrice = (data) => axios.post('/business/factorAdjustedPowerPrices', data);
export const updateFactorAdjustedPowerPrice = (id, data) =>
    axios.put(`/business/factorAdjustedPowerPrices/${id}`, data);
export const getFactorAdjustedPowerPrice = (id) => axios.get(`/business/factorAdjustedPowerPrices/${id}`);
export const deleteFactorAdjustedPowerPrice = (id) => axios.delete(`/business/factorAdjustedPowerPrices/${id}`);
export const deleteFactorAdjustedPowerPrices = (ids) =>
    axios.post(`/business/factorAdjustedPowerPrices/batchDelete`, ids);

//获取客户详情
export const getCustomerInfo = (id) => axios.get(`/business/customer/info/${id}`);
//修改客户级别信息
export const saveCustomerInfo = (data) => axios.post('/business/customer/save', data);

//获取最后一次抄表记录
export const getCustomerDegreeRecord = (params) => axios.get('/business/customer/degree/list', {params});
//抄表
export const addCustomerDegreeRecord = (data) => axios.post('/business/customer/degree/add', data);
//获取当月账单
export const getCustomerCurrentPaymentBill = (params) => axios.get('/business/customer/payment/bill', {params});
//获取当月账单
export const getCustomerPaymentBills = (params) => axios.get('/business/customer/payment/list', {params});
//获取客户汇总账单
export const getCustomerPaymentBillSum = (id) => axios.get(`/business/customer/payment/bill/sum/${id}`);
//保存缴费记录
export const addCustomerPaymentRecord = (data) => axios.post('/business/customer/payment/add', data);
//根据客户id查询计量点副本
export const queryMeteringByCustomerId = (customerId) =>
    axios.get(`/business/electricPlanMeteringCopys/queryMeteringByCustomerId/${customerId}`);
//根据客户id查询供电方案id
export const queryElectricPlanByCustomerId = (customerId) =>
    axios.get(`/business/electricPlanMeteringCopys/queryElectricPlanByCustomerId/${customerId}`);
//新增计量点副本
export const saveMingCopy = (electricPlanMeteringCopy) =>
    axios.post(`/business/electricPlanMeteringCopys`, electricPlanMeteringCopy);
//编辑计量点副本
export const updateMetering = (id, electricPlanMeteringCopy) =>
    axios.put(`/business/electricPlanMeteringCopys/${id}`, electricPlanMeteringCopy);

// 勘测阶段
export const getReconnaissance = (applyId) => axios.get(`/sapp/business/reconnaissance/add/${applyId}`);

export const getRePowerPlan = (applyId) => axios.get(`business/recommend/power/${applyId}`);

export const getPowerLineWay = (applyId) => axios.get(`/sapp/business/recommend/power?gridId=${applyId}`);

export const reconnaissanceAddOrUpdate = (data) => axios.post('business/reconnaissance/add', data);

export const reconnaissanceUpdate = (data) => axios.post('/sapp/business/reconnaissance/update', data);

export const getReconnaissanceUpdate = (id) => axios.get('/sapp/business/reconnaissance/update/' + id);

//用户管理
//获取用户列表
export const getUserList = (params) => axios.get('/sys/user/list', {params});

export const errorReport = (params) => {
    console.log(params);
};

//角色管理
export const getRoleList = (params) => axios.get('/sys/role/list', params);

//组件管理
export const pageWidget = (params) => axios.get('/sys/widgets/page', {params});
export const insertWidget = (data) => axios.post('/sys/widgets', data);
export const updateWidget = (id, data) => axios.put(`/sys/widgets/${id}`, data);
export const getWidget = (id) => axios.get(`/sys/widgets/${id}`);
export const deleteWidget = (id) => axios.delete(`/sys/widgets/${id}`);
export const batchDeleteWidget = (ids) => axios.post(`/sys/widgets/batchDelete`, ids);
export const listWidget = (params) => axios.get('/sys/widgets/list', {params});

//根据用户名模糊查询用户
export const getSysUserByName = (name) => axios.get(`/sys/user/queryByName?name=${name}`);

//检修申请保存
export const saveOverhaul = (data) => axios.post('/business/overhaul/save', data);

//查询检修申请关联附件
export const getOverhaulAttach = (overhaulId) => axios.get(`/business/overhaul/queryAttach?overhaulId=${overhaulId}`);

//检修申请回执保存
export const saveOverhaulReceive = (data) => axios.post('/business/overhaulReceive/saveReceive', data);
//查询检修回执
export const queryOverhaulReceiveByApplyId = (applyId) =>
    axios.get(`/business/overhaulReceive/queryByApplyId?applyId=${applyId}`);
//查询检修申请枚举值名称（许可方式、许可人、间作地点）
export const getOverhaulEnumName = (id, equipmentId) =>
    axios.get(`/business/overhaul/getOverhaulEnumName?id=${id}&equipmentId=${equipmentId}`);
//查询检修回执
export const getOverhaulReceiveDetail = (overhaulId) =>
    axios.get(`/business/overhaulReceive/detail?overhaulId=${overhaulId}`);

//根据视图查询所有检修设备（具体设备）
export const queryAllEquipmentByView = () => axios.get('/business/overhaulEquipments/queryAllByView');
//查询检修关联设备
export const queryEquipmentByApplyId = (applyId) =>
    axios.get(`/business/overhaulEquipments/queryByApplyId?applyId=${applyId}`);

//查询检修相关角色
export const queryOverhaulRoleList = (unitType) =>
    axios.get(`business/overhaul/queryOverhaulRoleList?unitType=${unitType}`);

export const getReconnaissanceInfo = (id) => axios.get('business/reconnaissance/info/' + id);

//配网中的设备
export const getUsedEquipmentList = (params) => axios.get('/business/equipments/used', {params});
//抢修业务
//export const getFlawEquipmentList = params => axios.get('/business/equipments/troubleUsed', {params});
export const getFlawEquipmentList = (params) => axios.get('/business/equipments/troubleUsed', {params});

export const getAllUserList = () => axios.get('/sys/user/listNoPage');
export const saveOrSubmitEquipmentFlaw = (data) => axios.post('/business/equipmentFlaws/saveOrSubmit', data);
export const getEquipmentFlawInfo = (id) => axios.get(`/business/equipmentFlaws/${id}`);
export const getEquipmentFlawDetail = (id) => axios.get(`/business/equipmentFlaws/detail/${id}`);

export const saveOrSubmitFlawTask = (data) => axios.post('/business/flawTasks/saveOrSubmit', data);
export const getFlawTaskInfo = (applyId) => axios.get(`/business/flawTasks/${applyId}`);
export const getFlawTaskDetail = (applyId) => axios.get(`/business/flawTasks/detail/${applyId}`);

export const saveOrSubmitFlawTaskAccept = (data) => axios.post('/business/flawTaskAccepts/saveOrSubmit', data);
export const getFlawTaskAcceptInfo = (id) => axios.get(`/business/flawTaskAccepts/${id}`);
export const getFlawTaskAcceptDetail = (applyId) => axios.get(`/business/flawTaskAccepts/detail/${applyId}`);

export const saveOrSubmitFlawTaskCompletion = (data) => axios.post('/business/flawTaskCompletions/saveOrSubmit', data);
export const getFlawTaskCompletionInfo = (id) => axios.get(`/business/flawTaskCompletions/${id}`);
export const getFlawTaskCompletionDetail = (applyId) => axios.get(`/business/flawTaskCompletions/detail/${applyId}`);

export const queryCurrentData = (params) => axios.get('/business/currentData/currentData', {params});
export const getAllCurrentDataTime = (deviceType, deviceName) =>
    axios.get(`/business/currentData/getAllCurrentDataTime?deviceType=${deviceType}&deviceName=${deviceName}`);
export const getRingMainUnit = () => axios.get(`/business/currentData/getRingMainUnit`);

//大屏数据
//购售电收益汇总
export const sumOfPurchaseAndSale = () => axios.get('/bigScreen/sumOfPurchaseAndSale');
//用电用户缴费状态
export const getPaymentStatus = () => axios.get('/bigScreen/paymentStatus');
//获取组织机构
export const getOrgTree = () => axios.get('/bigScreen/getOrgTree');
//获取设备分布
export const getEquipmentStatistics = () => axios.get('/bigScreen/equipmentStatistics');
//用电行业情况分布
export const listDistributionElectricityIndustry = () => axios.get('/bigScreen/listDistributionElectricityIndustry');
//购售电收益/损耗率统计
export const listPurchaseAndSaleOfElectricity = (params) =>
    axios.get('/bigScreen/listPurchaseAndSaleOfElectricity', {params});
// 用户用电量排名
export const powerConsumptionRanking = (params) => axios.get('/bigScreen/powerConsumptionRanking', {params});
// 获取最新有缴费记录的月份
export const getIsPowerConsumptionRankingOfMonth = () => axios.get('/bigScreen/getIsPowerConsumptionRankingOfMonth');
//用电电压分布
export const listElectricityVoltageDistribution = () => axios.get('/bigScreen/listElectricityVoltageDistribution');

// 业扩效率信息
export const getBusinessApplyEfficiency = () => axios.get('/bigScreen/businessApplyEfficiency');
// 电网状态
export const getPowerGridStatusInfo = () => axios.get('/bigScreen/powerGridStatusInfo');
// 停电情况统计
export const getPowerCutInfo = () => axios.get('/bigScreen/powerCutInfo');
// 停电情况统计
export const getRepairInfo = () => axios.get('/bigScreen/repairInfo');
// 故障监控
export const getFaultMonitor = () => axios.get('/bigScreen/faultMonitor');
// 业扩办理进度
export const getBusinessApplyProgress = (params) => axios.get('/bigScreen/businessApplyProgress', {params});

export const getBdzTopology = () => axios.get('/business/currentData/getBdzTopology');
export const getHwgTopology = (number) => axios.get(`/business/currentData/getHwgTopology/${number}`);

//添加主变开关
export const insertMainSubSwitch = (eId, data) => axios.post(`/business/mainSubSwitch/${eId}`, data);
//编辑主变开关
export const updateMainSubSwitch = (id, data) => axios.put(`/business/mainSubSwitch/${id}`, data);
//删除主变开关
export const deleteMainSubSwitch = (id) => axios.delete(`/business/mainSubSwitch/${id}`);
//查询设备关联开关
export const queryMainSubSwitchByEquipment = (equipmentId, equipmentType) =>
    axios.get(`/business/mainSubSwitch/queryByEquipment/${equipmentId}/${equipmentType}`);
//新增/编辑主变出线侧
export const saveMainSubWireOut = (data) => axios.post(`/business/mainSubWireOuts/saveWireOutAndSwitch`, data);
//查询主变出线侧
export const queryMainSubWireOutAndSwitch = (subId, wireId) =>
    axios.get(`/business/mainSubWireOuts/queryWireOutAndSwitch/${subId}/${wireId}`);
//删除主变出线侧
export const deleteMainSubWireOutAndSwitch = (subId, id) =>
    axios.get(`/business/mainSubWireOuts/deleteWireOut/${subId}/${id}`);

//查询大屏运维工作情况 检修/抢修数据
export const queryBusinessTrackingVOList = () => axios.get('/bigScreen/queryBusinessTrackingVOList');

//投产申请保存
export const savePutIntoProduction = (data) => axios.post('/business/putIntoProductions/save', data);

//查询投产申请关联附件
export const getPutIntoProductionAttach = (putIntoProductionId) =>
    axios.get(`/business/putIntoProductions/queryAttach?putIntoProductionId=${putIntoProductionId}`);

//查询投产关联设备
export const queryPutIntoProductionEquipmentByApplyId = (applyId) =>
    axios.get(`/business/putIntoProductionEquipments/queryByApplyId?applyId=${applyId}`);
//根据视图查询所有投产设备（具体设备）
export const queryAllPutIntoProductionEquipmentByView = () =>
    axios.get('/business/putIntoProductionEquipments/queryAllByView');

//查询投产申请枚举值名称（许可方式、许可人、间作地点）
export const getPutIntoProductionEnumName = (id) =>
    axios.get(`/business/putIntoProductions/getPutIntoProductionEnumName?id=${id}`);

//查询投产回执
export const getPutIntoProductionReceiveDetail = (putIntoProductionId) =>
    axios.get(`/business/putIntoProductionReceives/detail?putIntoProductionId=${putIntoProductionId}`);

//投产申请回执保存
export const savePutIntoProductionReceive = (data) =>
    axios.post('/business/putIntoProductionReceives/saveReceive', data);

//查询投产回执
export const queryPutIntoProductionReceiveByApplyId = (applyId) =>
    axios.get(`/business/putIntoProductionReceives/queryByApplyId?applyId=${applyId}`);
//查询待我处理任务数量
export const getMyTaskCount = (config) => axios.get('/business/workflow/getMyTaskCount', config);
//操作票管理
//查询详情
export const getQueryRecords = (params) => axios.get(`/Workflow/api/v1/Instance/QueryRecords`, {params});
//操作表保存，发起，驳回
export const process = (params) => axios.post(`/Workflow/api/v1/Instance/Process`, params);
//操作票列表查询
export const getOperationOrderList = (params) => axios.get('/Workflow/api/v1/Instance/Query', {params});
//操作票导出表单
const url = 'http://localhost:8080/elec';
export const exportFrom = (data) => axios.post('/business/operationOrders/download', data);

//工作票管理
//工作票 - 厂站工作票保存不发布
export const saveWorkTicketFactory = (data) => axios.post('/business/workFactorys/save', data);
//工作票 - 厂站保存并发布
export const saveAndStartWorkTicketFactory = (data) => axios.post('/business/workFactorys/saveAndStart', data);
//工作票 - 厂站通过
export const passWorkTicketFactory = (data) => axios.post('/business/workFactorys/nextNode', data);
//工作票 - 厂站驳回
export const rejectWorkTicketFactory = (data) => axios.post('/business/workFactorys/reject', data);
//线路工作票保存不发布
export const saveWorkTicketLine = (data) => axios.post('/business/workTicketLines/save', data);
//线路工作票保存发布
export const saveAndSubmitWorkTicketLine = (data) => axios.post('/business/workTicketLines/saveAndSubmit', data);
//流程回滚
export const workFlowGoBack = (taskId, processInstanceId) =>
    axios.get(`/business/workflow/goBack/${taskId}/${processInstanceId}`);
//工作票进入下一环节
export const workTicketNextNode = (data) => axios.post(`/business/workTicketLines/nextNode`, data);

//查询实例列表
export const workflowInstanceQueryRecords = (params) => axios.get(`/Workflow/api/v1/Instance/QueryRecords`, {params});
//保存，发起，驳回
export const workflowInstanceProcess = (data) => axios.post(`/Workflow/api/v1/Instance/Process`, data);
//查询流程列表
export const workflowInstanceQuery = (params) => axios.get('/Workflow/api/v1/Instance/Query', {params});
// 查询定义列表
export const workflowDefinitionQuery = (params) => axios.get('/api/v1/Workflow/Definition/Query', {params});
// 服务器utc时间
export const workflowServerUtcNow = () => axios.get('/Workflow/api/v1/Server/UtcNow');
//删除
export const workflowDelete = (ids) => axios.post('/Workflow/api/v1/Instance/Delete', ids);

//查询下一个工作票code
export const getNextWorkTicketCode = (type) => axios.get(`/business/workTicketCodes/genCode/${type}`);

//保存日志
export const saveLog = (data) => axios.post(`/business/logs/save`, data);

//获取日志列表
export const getLogList = (params) => axios.get(`/business/logs/list`, {params});
//删除日志
export const deleteLog = (ids) => axios.post(`/business/logs/batch`, ids);
//修改日志
export const updataLog = (id, data) => axios.post(`/business/logs/${id}`, data);

// export const flasw = axios.get( "/Workflow/api/v1/Instance/Query?DefinitionCode=elec_dispatcn_flaw")
//查询流程列表
export const workflowInstanceQueryList = (params) => axios.get('/Workflow/api/v1/Instance/Query', {params});

export const getPowerMointorList = (params) => axios.get('business/elecPowerMonitors/list', {params});

export const savePowerMointor = (data) => axios.post('business/elecPowerMonitors/save', data);

export const deletePowerMointor = (id) => axios.delete(`business/elecPowerMonitors/delete/${id}`);

export const getPowerMointor = (id) => axios.get(`business/elecPowerMonitors/${id}`);

export const updatePowerMointor = (data) => axios.post('business/elecPowerMonitors/update', data);

//获取某个客户最后一条抄表记录
export const getLastInfo = (cid) => axios.get(`business/customer/degree/last/${cid}`);
//更具codes查找角色
export const getRolesBycodes = (params) => axios.post('/sys/role/listCode', params);

export const workTaskInformDownload = (params) =>
    axios.post('/business/ElecDispatchFlawManage/workTaskInformDownload', params);

//关联工作票和表单
export const addWorkTickForms = (params) => axios.post('/business/workTickForms/save', params);

export const equipmentInfo = (number) => axios.post(`/business/equipments/equipmentInfo`, number);
//保存消息
export const saveSysMsg = (data) => axios.post('/business/sysMsgs', data);
//已读消息列表
export const getReadMessageList = () => axios.get('/business/sysMsgs/selectAll');
//消息已读
export const isRead = (data) => axios.post(`/business/sysMsgs/isRead`, data);
//未读条数
export const isReadCount = () => axios.get('/business/sysMsgs/isReadCount');
//MQ消息列表
export const getMessageList = (params) => axios.get('/msg/api/v1/Message/Query', {params});
//流程作废
export const termination = (ids) => axios.post('/Workflow/api/v1/Instance/Termination', ids);
/*---------重构图纸开始-------- */
//获取重构电网树的配置列表
export const gainReviewDrawList = (any) => axios.get('/business/drawingTrees/aList?sel=' + any);
//获取重构电网树的配置树
export const gainReviewDrawTree = () => axios.get('/business/drawingTrees/list');
//获取重构电网树的配置通过id
export const gainReviewDrawTreeById = (id) => axios.get('/business/drawingTrees/' + id);
//删除重构电网树通过id
export const delReviewDrawTreeById = (id) => axios.delete('/business/drawingTrees/' + id);
//保存树
export const saveReviewDrawTree = (params) => axios.post('/business/drawingTrees/save', params);
//更新电网配置树
export const updateReviewDrawTree = (params) => axios.put('/business/drawingTrees/' + params.id, params);
//保存图纸
export const saveReviewDraw = (params) => axios.post('/business/drawingInfos/save', params);
//批量保存图纸
export const saveReviewDrawBatch = (params) =>
    axios.post('/business/drawingInfos/saveBatch?treeId=' + (params.treeId || ''), params.params);
//分页查询图纸
export const pageReviewDrawBatch = (params) => axios.get('/business/drawingInfos/pageList', {params});
//具体图纸信息通过id
export const ReviewDrawInfoById = (id) => axios.get('/business/drawingInfos/' + id);
//更新关联的树
export const updateReviewDrawRefenceTree = (treeId, id) =>
    axios.put('/business/drawingTrees/updDrawTree?drawId=' + id + '&id=' + treeId);
export const updReviewDrawInfo = (params) => axios.put('/business/drawingInfos/' + params.id, {params});
export const ReviewDrawInfolist = (params) => axios.get('/business/drawingInfos/list');
export const updReviewDrawInfoBatch = (params) =>
    axios.put('/business/drawingInfos/updBatch?ope=' + (params.ope || ''), params.data);
export const deleteDrawInfoBatch = (id) => axios.delete(`/business/drawingInfos/${id}`);
/*-----重构图纸结束------*/
//监测点列表
export const powerMonitorList = (params) => axios.get('business/powerMonitors', {params});
//监测点详情
export const powerMonitorDetil = (id) => axios.get(`business/powerMonitors/${id}`);
//监测点新增
export const powerMonitorAdd = (params) => axios.post('business/powerMonitors', params);
//监测点更新
export const powerMonitorUpdate = (id, params) => axios.put(`business/powerMonitors/${id}`, params);
//监测点删除
export const powerMonitorDelete = (id) => axios.delete(`business/powerMonitors/${id}`);
//监测点所有详情数据
export const powerMonitorAll = () => axios.get('business/powerMonitors/allMonitor');

export const listDictionaryChildrenTrees = (codeArr) =>
    axios.get('/base/dictionaries/trees', {params: {parentCode: codeArr}});
//
export const listMointorInfo = (params) =>
    axios.post('/es/elec_data_power_quality-*/_search', params, {
        skipInterceptor: true,
        headers: {'Content-Type': 'application/json'},
    });

//异常原因列表
export const powerMonitorBaseInformationsList = (params) =>
    axios.get('business/powerMonitorBaseInformations', {params});
//异常原因详情
export const powerMonitorBaseInformationsDetil = (id) => axios.get(`business/powerMonitorBaseInformations/${id}`);
//异常原因新增
export const powerMonitorBaseInformationsrAdd = (params) => axios.post('business/powerMonitorBaseInformations', params);
//异常原因更新
export const powerMonitorBaseInformationsUpdate = (id, params) =>
    axios.put(`business/powerMonitorBaseInformations/${id}`, params);
//异常原因删除
export const powerMonitorBaseInformationsDelete = (id) => axios.delete(`business/powerMonitorBaseInformations/${id}`);
//设备预警
export const gainListConfPage = (params) => axios.get(`business/deviceMsgConfs`, {params});
export const gainListDevice = () => axios.get(`business/deviceMsgConfs/device`);
export const gainListGeneral = () => axios.get(`business/deviceMsgConfs/general`);
export const gainById = (id) => axios.get(`business/deviceMsgConfs/${id}`);
export const saveDeviceMsgConf = (params) => axios.post(`business/deviceMsgConfs/save`, params);
export const updDeviceMsgConf = (params) => axios.put(`business/deviceMsgConfs/${params.id}`, params);
export const delDeviceMsgConf = (id) => axios.delete(`business/deviceMsgConfs/${id}`);
export const delDeviceMsgConfBatch = (ids) => axios.delete(`business/deviceMsgConfs/batch`, {data: [...ids]});
export const pauseSchedulerConf = (key) => axios.put(`business/deviceMsgConfs/pause?trigger=${key}`);
export const restartSchedulerConf = (key) => axios.put(`business/deviceMsgConfs/restart?trigger=${key}`);

export const gainListMsgPage = (params) => axios.get(`business/deviceMsgs`, {params});
export const gainByIdMsg = (id) => axios.get(`business/deviceMsgs/${id}`);
export const saveDeviceMsg = (params) => axios.post(`business/deviceMsgs/save`, params);
export const updDeviceMsg = (params) => axios.put(`business/deviceMsgs/${params.id}`, params);
export const delDeviceMsg = (id) => axios.delete(`business/deviceMsgs/${id}`);
export const delDeviceMsgBatch = (ids) => axios.delete(`business/deviceMsgs/batch`, {data: [...ids]});

//批量查询列表
export const getBatchQuery = (params, config) => axios.post(`/Workflow/api/v1/Instance/BatchQuery`, params, config);
//培训台账
//新增
export const saveMaterialForTraining = (data) => axios.post('/business/materialForTrainings/save', data);
//列表
export const getMaterialForTrainingList = (params) => axios.get('/business/materialForTrainings/list', {params});
//详情
export const getMaterialForTrainingInfo = (id) => axios.get(`/business/materialForTrainings/info/${id}`);
//删除
export const delMaterialForTraining = (data) => axios.post('/business/materialForTrainings/delete', data);
//配变监测终端监测列表
export const terminalEquipmentList = (params) =>
    axios.post('/es/elec_power_quality_*/_search', params, {
        skipInterceptor: true,
        headers: {'Content-Type': 'application/json'},
    });
//配变监测终端维护
//新增
export const saveTerminalEquipments = (data) => axios.post('/business/terminalEquipments/save', data);
//列表
export const getTerminalEquipmentsList = (params) => axios.get('/business/terminalEquipments/list', {params});
//详情
export const getTerminalEquipmentsInfo = (id) => axios.get(`/business/terminalEquipments/info/${id}`);
//删除
export const delTerminalEquipments = (data) => axios.post('/business/terminalEquipments/delete', data);
//修改
export const updateTerminalEquipments = (id, data) => axios.put(`/business/terminalEquipments/update/${id}`, data);
//查询所有
export const allTerminalEquipments = () => axios.get('/business/terminalEquipments/all');

//根据instanceId查询缺陷code
export const queryByInstanceId = (instanceId) => axios.get(`/business/flawCodes/queryByInstanceId/${instanceId}`);
//查询本月缺陷最大的一个code
export const queryThisMonthMaxCode = () => axios.get('/business/flawCodes/queryThisMonthMaxCode');
//保存缺陷code
export const saveFlawCode = (data) => axios.post('/business/flawCodes', data);

