// import zipIcon from '@/assets/icons/fileType/zip.svg';
import audioIcon from '@/assets/icons/fileType/audio.svg';
import codeIcon from '@/assets/icons/fileType/code.svg';
import excelIcon from '@/assets/icons/fileType/excel.svg';
import genericIcon from '@/assets/icons/fileType/generic.svg';
import imageIcon from '@/assets/icons/fileType/image.svg';
import pdfIcon from '@/assets/icons/fileType/pdf.svg';
import pptIcon from '@/assets/icons/fileType/ppt.svg';
import videoIcon from '@/assets/icons/fileType/video.svg';
import wordIcon from '@/assets/icons/fileType/word.svg';
export const FileTypes = {
    IMAGE: 'image',
    AUDIO: 'audio',
    VIDEO: 'video',
    SPREADSHEET: 'spreadsheet',
    CODE: 'code',
    WORD: 'word',
    PRESENTATION: 'presentation',
    PDF: 'pdf',
    PATCH: 'patch',
    SVG: 'svg',
    OTHER: 'other'
};
export const Constants = {
    IMAGE_TYPES: ['jpg', 'gif', 'bmp', 'png', 'jpeg', 'tiff', 'tif'],
    AUDIO_TYPES: ['mp3', 'wav', 'wma', 'm4a', 'flac', 'aac', 'ogg', 'm4r'],
    VIDEO_TYPES: ['mp4', 'avi', 'webm', 'mkv', 'wmv', 'mpg', 'mov', 'flv'],
    PRESENTATION_TYPES: ['ppt', 'pptx'],
    SPREADSHEET_TYPES: ['xlsx', 'csv'],
    WORD_TYPES: ['doc', 'docx'],
    CODE_TYPES: 'applescript,as,atom,bas,bash,boot,c,c++,cake,cc,cjsx,cl2,clj,cljc,cljs,cljs.hl,cljscm,cljx,_coffee,coffee,cpp,cs,csharp,cson,css,d,dart,delphi,dfm,di,diff,django,docker,dockerfile,dpr,erl,ex,exs,f90,f95,freepascal,fs,fsharp,gcode,gemspec,go,groovy,gyp,h,h++,handlebars,hbs,hic,hpp,hs,html,html.handlebars,html.hbs,hx,iced,irb,java,jinja,jl,js,json,jsp,jsx,kt,ktm,kts,lazarus,less,lfm,lisp,log,lpr,lua,m,mak,matlab,md,mk,mkd,mkdown,ml,mm,nc,obj-c,objc,osascript,pas,pascal,perl,php,php3,php4,php5,php6,pl,plist,podspec,pp,ps,ps1,py,r,rb,rs,rss,ruby,scala,scm,scpt,scss,sh,sld,sql,st,styl,swift,tex,thor,txt,v,vb,vbnet,vbs,veo,xhtml,xml,xsl,yaml,zsh'.split(
        ','
    ),
    PDF_TYPES: ['pdf'],
    SVG_TYPES: ['svg'],
    ICON_FROM_TYPE: {
        image: imageIcon,
        audio: audioIcon,
        video: videoIcon,
        spreadsheet: excelIcon,
        presentation: pptIcon,
        pdf: pdfIcon,
        code: codeIcon,
        word: wordIcon,
        other: genericIcon
    }
};
export const getFileType = extin => {
    const ext = extin && extin.toLowerCase();
    if (Constants.IMAGE_TYPES.indexOf(ext) > -1) {
        return FileTypes.IMAGE;
    }
    if (Constants.AUDIO_TYPES.indexOf(ext) > -1) {
        return FileTypes.AUDIO;
    }
    if (Constants.VIDEO_TYPES.indexOf(ext) > -1) {
        return FileTypes.VIDEO;
    }
    if (Constants.SPREADSHEET_TYPES.indexOf(ext) > -1) {
        return FileTypes.SPREADSHEET;
    }
    if (Constants.CODE_TYPES.indexOf(ext) > -1) {
        return FileTypes.CODE;
    }
    if (Constants.WORD_TYPES.indexOf(ext) > -1) {
        return FileTypes.WORD;
    }
    if (Constants.PRESENTATION_TYPES.indexOf(ext) > -1) {
        return FileTypes.PRESENTATION;
    }
    if (Constants.PDF_TYPES.indexOf(ext) > -1) {
        return FileTypes.PDF;
    }
    if (Constants.SVG_TYPES.indexOf(ext) > -1) {
        return FileTypes.SVG;
    }
    return FileTypes.OTHER;
};

function getFileIconPath(fileInfo) {
    const fileType = getFileType(fileInfo.ext);
    let icon;
    if (fileType in Constants.ICON_FROM_TYPE) {
        icon = Constants.ICON_FROM_TYPE[fileType];
    } else {
        icon = Constants.ICON_FROM_TYPE.other;
    }
    return icon;
}

export function addThumbUrl(list, serverUrl, token, uid) {
    if (!list) {
        return [];
    }
    if (!Array.isArray(list)) {
        list = [list];
    }
    return list.map(it => {
        let thumbUrl;
        if (it.thumbnailPath) {
            thumbUrl = `${serverUrl}files/${it.id}/thumbnail?token=${token}`;
        } else {
            thumbUrl = getFileIconPath(it);
        }
        it.thumbUrl = thumbUrl;
        if (uid) {
            it.uid = uid;
        }

        return it;
    });
}
