<template>
    <el-card :class="['card-pub-notice collapse-transition', collapse ? 'card-hide' : '']">
        <div v-if="headerName != ''" slot="header" class="card-pub-notice__title" @click="changeCollapse">
            <span>{{ headerName }} </span>
            <span
                >{{ collapse ? '展开' : '收起' }} <i :class="collapse ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"></i
            ></span>
        </div>
        <el-collapse-transition>
            <div v-show="!collapse" class="card-pub-notice__conent-wrap">
                <slot></slot>
            </div>
        </el-collapse-transition>
    </el-card>
</template>

<script>
export default {
    name: 'ExCard',
    props: {
        headerName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            collapse: false,
        };
    },
    methods: {
        changeCollapse() {
            this.collapse = !this.collapse;
        },
    },
};
</script>
<style lang="less" scoped>
.card-pub-notice {
    /deep/ .el-card__body{
        padding: 20px;
        transition: .3s padding linear;
    }
    &.card-hide {
        /deep/ .el-card__body{
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    &__title {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
    }
    &__conent-wrap {
    }
}
</style>
