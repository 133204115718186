import Vue from 'vue';
import Router from 'vue-router';
import store from '@/vuex';
import {authCheck, iframeRuntime} from '@/utils';

// 组件
import ErrorUnauthorized from '@/components/error/ErrorUnauthorized';
import ErrorPageNotFound from '@/components/error/ErrorPageNotFound';
import schedule from '../pages/sys/schedule';
import Version from '../pages/version/Version';

// 首页
const Login = () => import('@/pages/login/Login');
const MainIndex = () => {
    if (iframeRuntime()) {
        return import('@/pages/MainIndexWithoutLayout');
    }
    return import('@/pages/MainIndex');
};
// const MainHomeIndex = () => import('@/pages/home/MainHomeIndex');
// 新增首页菜单
const MainHomeMenu = () => import('@/pages/home/MainHomeMenu');

/**
 * 大屏.
 * @constructor
 */
const BigScreen = () => import('@/pages/home/BigScreen');

// 测试
const SignalRTest = () => import('@/pages/test/TestIndex');
// const GanttTest = () => import('@/pages/devPlayGround/GanttTest');

// 图纸台账
const Drawing = () => import('@/pages/drawing/drawingApply');
// 设备台账
const EquipmentIndex = () => import('@/pages/equipment/index');

// 售电管理
// 用户用电管理
const CustomerAccountIndex = () => import('@/pages/SellingElectricity/CustomerAccount/index');
// 电价管理
const ElectricityPrice = () => import('@/pages/SellingElectricity/ElectricityPrice');
// 利率调整电费
const FactorAdjustedPowerPrice = () => import('@/pages/SellingElectricity/FactorAdjustedPowerPrice');

// 业扩

// 用户申请
const BusinessApplyForm = () => import('@/pages/businessApply/businessApplyForm');
const BusinessApplyShow = () => import('@/pages/businessApply/businessApplyShow');
// 供电方案
const ElectricPlanForm = () => import('@/pages/electricPlan/electricPlanForm');
// 供电方案审核
const ElectricPlanReview = () => import('@/pages/electricPlan/electricPlanReview');

const ReconnaissanceForm = () => import('@/pages/reconnaissance/reconnaissanceForm');

const PowerSupplyPlan = () => import('@/pages/powerSupplyPlan/index');

//验收申请
const CompletionApply = () => import('@/pages/completionApply/completionApply');
//竣工验收
const CompletionAcceptance = () => import('@/pages/completionAcceptance/completionAcceptance');
//资料归档
const DatumArchive = () => import('@/pages/datumArchive/datumArchive');

//业务管理
const WorkflowList = () => import('@/pages/workflowList/workflowList');

//购电管理
const PowerPurchase = () => import('@/pages/powerPurchase/index');

//告警台账
const AlarmAccount = () => import('@/pages/alarmAccount/index');

// 配置
const ConfigInfo = () => import('@/pages/systemSettings/ConfigInfo/ConfigInfo');

// 数据字典
const DictionaryIndex = () => import('@/pages/systemSettings/Dictionary/DictionaryIndex');

//角色管理
const RoleManagement = () => import('@/pages/systemSettings/RoleManagement/RoleManagement');

//角色授权
const RoleAuthorization = () => import('@/pages/systemSettings/RoleAuthorization/RoleAuthorization');

//菜单授权
const MenuAuthorization = () => import('@/pages/systemSettings/MenuAuthorization/MenuAuthorization');

//权限管理
const AuthManagement = () => import('@/pages/systemSettings/AuthManagement/AuthManagement');

//用户管理
const UserManagement = () => import('@/pages/systemSettings/UserManagement/UserManagement');

//用户授权
const UserAuthorization = () => import('@/pages/systemSettings/UserAuthorization/UserAuthorization');
//菜单管理
const MenuManagement = () => import('@/pages/systemSettings/MenuManagement/MenuManagement');
//组件管理
const ComponentManagementInfo = () => import('@/pages/systemSettings/ComponentManagementInfo/ComponentManagementInfo');

//角色数据权限管理
const RoleDataManagement = () => import('@/pages/systemSettings/RoleDataManagement/RoleDataManagement');

//消息推送配置
const MessagePushConfiguration = () =>
    import('@/pages/systemSettings/MessagePushConfiguration/MessagePushConfiguration');

//个人中心
const PersonalCenter = () => import('@/pages/PersonalCenterInfo/PersonalCenterInfo');

//版本更新
const VersionUpdate = () => import('@/components/layout/version');

//同步任务触发菜单
const Trigger = () => import('@/pages/systemSettings/Trigger/Trigger');

// 电网监控
const powerGridMonitor = () => import('@/pages/powerGridMonitor/index');

// 电网配置 设备配置页入口
const powerGridConfig = () => import('@/pages/powerGridMonitor/Editing.vue');

// 手册台账
const Handbook = () => import('@/pages/handbook/index');
// 巡检管理
const Patrol = () => import('@/pages/patrol/patrol');
// 巡检台账管理
const PatrolAssignment = () => import('@/pages/patrol/patrolAssignment');
// 系统管理
const Dict = () => import('@/pages/sys/dict');
const User = () => import('@/pages/sys/user');
const Role = () => import('@/pages/sys/role');
const Menu = () => import('@/pages/sys/menu');
const Flow = () => import('@/pages/sys/flow');
const FlowDeploy = () => import('@/pages/sys/flowDeploy');
const OrgUnit = () => import('@/pages/sys/orgUnit');
const OrgUnitUser = () => import('@/pages/sys/orgUnitUser');
const OrgPosition = () => import('@/pages/sys/orgPosition');
const Modeler = () => import('@/pages/sys/modeler');
const Widget = () => import('@/pages/sys/Widget');
const htmlPanel = () => import('@/pages/HtmlPanel');

const Bdz = () => import('@/pages/currentData/bdz');
const HwgQcy1 = () => import('@/pages/currentData/hwgQcy1');
const HwgQcy2 = () => import('@/pages/currentData/hwgQcy2');
const HwgYj1 = () => import('@/pages/currentData/hwgYj1');
const HwgYj2 = () => import('@/pages/currentData/hwgYj2');
const HwgYk1 = () => import('@/pages/currentData/hwgYk1');
const HwgYk2 = () => import('@/pages/currentData/hwgYk2');
const HwgYk3 = () => import('@/pages/currentData/hwgYk3');
const HwgYx1 = () => import('@/pages/currentData/hwgYx1');
const HwgYx2 = () => import('@/pages/currentData/hwgYx2');
const HwgYx3 = () => import('@/pages/currentData/hwgYx3');
const HwgYx4 = () => import('@/pages/currentData/hwgYx4');
//定时任务
const Schedule = () => import('@/pages/sys/schedule');
//计划停电
const Planned = () => import('@/pages/dispatchingManagement/planned/planned');
//非计划停电
const UnPlanned = () => import('@/pages/dispatchingManagement/unPlanned/unPlanned');
//投产
const Production = () => import('@/pages/dispatchingManagement/production/production');
//版本信息
const Vsersion = () => import('@/pages/version/Version');
//操作票管理
const OperationOrderList = () => import('@/pages/twoTickets/operationOrder/list/operationOrderList');
//工作票管理
const WorkTicketList = () => import('@/pages/twoTickets/workTicket/list/workTicketList');
//停电申请
const PowerCut = () => import('@/pages/powerCut/powerCut');
//培训台账
const Train = () => import('@/pages/teamConstruction/train');
//电压监测
const VoltageMonitoring = () => import('@/pages/powerQualityMonitoring/voltageMonitoring');
//频率监测
const FrequencyMonitoring = () => import('@/pages/powerQualityMonitoring/frequencyMonitoring');
//谐波监测
const HarmonicMonitoring = () => import('@/pages/powerQualityMonitoring/harmonicMonitoring');
//三项不平衡电压监测
const ThreePhaseUnbalancedVoltageMonitoring = () =>
    import('@/pages/powerQualityMonitoring/threePhaseUnbalancedVoltageMonitoring');
//保护定值
const ProtectionFixedValue = () => import('@/pages/dispatchingManagement/protectionFixedValue/protectionFixedValue');
//紧急抢修工作票
const UrgentRepairs = () => import('@/pages/twoTickets/urgentRepairs/urgentRepairs');
//外围运维单位管理
const PeripheralUnits = () => import('@/pages/peripheralUnits/peripheralUnits');

const IncomeAndExpenditure = () => import('@/pages/visualization/IncomeAndExpenditure');
const Running = () => import('@/pages/visualization/Running');
const Profitandsell = () => import('@/pages/visualization/Profitandsell');
const Business = () => import('@/pages/visualization/Business');
//调度日志
const Log = () => import('@/pages/log/log');
//任务下发
const IssuedTask = () => import('@/pages/dispatchingManagement/issuedTask/issuedTaskList');
//定值业务
const ConstantValue = () => import('@/pages/dispatchingManagement/constantValue/constantValueList');

//电能监测
const MintorPiontEdit = () => import('@/pages/powerMassMonit/MonitorPointEdit');
const MintorPiont = () => import('@/pages/powerMassMonit/MonitrePointPreserve');

const DefectManagement = () => import('@/pages/dispatchingManagement/defectManagement/index');

const HiddenManagement = () => import('@/pages/dispatchingManagement/hiddenManagement/index');
//设备短信
const DeviceMsg = () => import('@/pages/deviceMsg/deviceMsg');
const DeviceMsgConf = () => import('@/pages/deviceMsg/deviceMsgConf');

//计划停电新建
const Handle = () => import('@/pages/dispatchingManagement/planned/handle');
//计划停电审核
const Review = () => import('@/pages/dispatchingManagement/planned/review');
//计划停电详情
const Info = () => import('@/pages/dispatchingManagement/planned/info');
//非计划停电新建
const UnplannedHandle = () => import('@/pages/dispatchingManagement/unPlanned/handle');
//非计划停电审核
const UnplannedReview = () => import('@/pages/dispatchingManagement/unPlanned/review');
//非计划停电详情
const UnplannedInfo = () => import('@/pages/dispatchingManagement/unPlanned/info');
//投产新建
const ProductionHandle = () => import('@/pages/dispatchingManagement/production/handle');
//投产审核
const ProductionReview = () => import('@/pages/dispatchingManagement/production/review');
//投产详情
const ProductionInfo = () => import('@/pages/dispatchingManagement/production/info');
//工作票新建
const WorkTicketHandle = () => import('@/pages/twoTickets/workTicket/list/workTicketHandle');
//工作票处理
const WorkTicketInfo = () => import('@/pages/twoTickets/workTicket/list/workTicketInfo');
//工作牌详情
//培训台账
const MaterialForTraining = () => import('@/pages/teamConstruction/materialForTraining/index');
//配变监测终端监测台账
const TerminalEquipment = () => import('@/pages/terminalEquipment/terminalEquipment.vue');

const UrgentRepairsHandle = () => import('@/pages//twoTickets/urgentRepairs/handler');

Vue.use(Router);
const router = new Router({
    mode: 'hash',
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/SignalR/Test',
            name: 'SignalRTest',
            component: SignalRTest
        },
        {
            path: '/',
            component: MainIndex,
            children: [
                {
                    path: '/',
                    name: 'MainHomeMenu',
                    component: MainHomeMenu,
                    meta: {
                        tabTitle: '首页',
                        padding: 0
                    }
                },
                {
                    path: '/bigScreen',
                    name: 'BigScreen',
                    component: BigScreen,
                    meta: {
                        tabTitle: '企业画像',
                    }
                },
                {
                    path: '/systemSettings/ConfigInfo',
                    name: 'ConfigInfo',
                    component: ConfigInfo,
                    meta: {
                        tabTitle: '配置信息'
                    }
                },
                {
                    path: '/base/dictionary',
                    name: 'DictionaryIndex',
                    component: DictionaryIndex,
                    meta: {
                        tabTitle: '数据字典'
                    }
                },
                {
                    path: '/systemSettings/role',
                    name: 'RoleManagement',
                    component: RoleManagement,
                    meta: {
                        tabTitle: '角色管理'
                    }
                },
                {
                    path: '/systemSettings/RoleAuthorization',
                    name: 'RoleAuthorization',
                    component: RoleAuthorization,
                    meta: {
                        tabTitle: '角色授权'
                    }
                },
                {
                    path: '/systemSettings/MenuAuthorization',
                    name: 'MenuAuthorization',
                    component: MenuAuthorization,
                    meta: {
                        tabTitle: '菜单授权'
                    }
                },
                {
                    path: '/systemSettings/AuthManagement',
                    name: 'AuthManagement',
                    component: AuthManagement,
                    meta: {
                        tabTitle: '权限管理'
                    }
                },
                {
                    path: '/systemSettings/user',
                    name: 'UserManagement',
                    component: UserManagement,
                    meta: {
                        tabTitle: '用户管理'
                    }
                },
                {
                    path: '/systemSettings/UserAuthorization',
                    name: 'UserAuthorization',
                    component: UserAuthorization,
                    meta: {
                        tabTitle: '用户授权'
                    }
                },
                {
                    path: '/systemSettings/ComponentManagementInfo',
                    name: 'ComponentManagementInfo',
                    component: ComponentManagementInfo,
                    meta: {
                        tabTitle: '组件管理'
                    }
                },
                {
                    path: '/systemSettings/RoleDataManagement',
                    name: 'RoleDataManagement',
                    component: RoleDataManagement,
                    meta: {
                        tabTitle: '角色数据权限管理'
                    }
                },
                {
                    path: '/systemSettings/MenuManagement',
                    name: 'MenuManagement',
                    component: MenuManagement,
                    meta: {
                        tabTitle: '菜单管理'
                    }
                },

                {
                    path: '/systemSettings/MessagePushConfiguration',
                    name: 'MessagePushConfiguration',
                    component: MessagePushConfiguration,
                    meta: {
                        tabTitle: '消息推送配置'
                    }
                },
                {
                    path: '/systemSettings/Trigger',
                    name: 'Trigger',
                    component: Trigger,
                    meta: {
                        tabTitle: '同步任务触发菜单',
                        padding: 0
                    }
                },
                {
                    path: '/PersonalCenter/PersonalCenter',
                    name: 'PersonalCenter',
                    component: PersonalCenter,
                    meta: {
                        tabTitle: '个人中心'
                    }
                },
                {
                    path: '/layout/Version',
                    name: 'VersionUpdate',
                    component: VersionUpdate,
                    meta: {
                        tabTitle: '版本更新'
                    }
                },
                {
                    path: '/Workflow/BusinessApplyForm/:id?',
                    name: 'BusinessApplyForm',
                    component: BusinessApplyForm,
                    meta: {
                        tabTitle: '用户申请',
                        auth: ''
                    },
                    props: true
                },
                {
                    path: '/Workflow/BusinessApplyShow/:id',
                    name: 'BusinessApplyShow',
                    component: BusinessApplyShow,
                    meta: {
                        tabTitle: '用户申请',
                        auth: ''
                    },
                    props: true
                },
                {
                    path: '/Workflow/ReconnaissanceForm/:id',
                    name: 'ReconnaissanceForm',
                    component: ReconnaissanceForm,
                    meta: {
                        tabTitle: '勘测阶段'
                    },
                    props: true
                },
                {
                    path: '/Workflow/PowerSupplyPlan/:id',
                    name: 'PowerSupplyPlan',
                    component: PowerSupplyPlan,
                    meta: {
                        tabTitle: '勘测阶段'
                    },
                    props: true
                },
                {
                    path: '/Workflow/WorkflowList',
                    name: 'WorkflowList',
                    component: WorkflowList,
                    meta: {
                        tabTitle: '业务管理'
                    }
                },
                {
                    path: '/business/powerPurchase',
                    name: 'PowerPurchase',
                    component: PowerPurchase,
                    meta: {
                        tabTitle: '购电管理'
                    }
                },
                {
                    path: '/business/alarmAccount',
                    name: 'AlarmAccount',
                    component: AlarmAccount,
                    meta: {
                        tabTitle: '告警台账'
                    }
                },
                {
                    path: '/Workflow/ElectricPlanForm/:id',
                    name: 'ElectricPlanForm',
                    component: ElectricPlanForm,
                    meta: {
                        tabTitle: '供电方案',
                        auth: ''
                    },
                    props: true
                },
                {
                    path: '/Workflow/ElectricPlanReview/:id',
                    name: 'ElectricPlanReview',
                    component: ElectricPlanReview,
                    meta: {
                        tabTitle: '供电方案审核',
                        auth: ''
                    },
                    props: true
                },
                {
                    path: '/Workflow/CompletionApply',
                    name: 'CompletionApply',
                    component: CompletionApply,
                    meta: {
                        tabTitle: '验收申请',
                        auth: ''
                    }
                },

                {
                    path: '/Workflow/CompletionAcceptance',
                    name: 'CompletionAcceptance',
                    component: CompletionAcceptance,
                    meta: {
                        tabTitle: '竣工验收',
                        auth: ''
                    }
                },
                {
                    path: '/Workflow/datumArchive',
                    name: 'DatumArchive',
                    component: DatumArchive,
                    meta: {
                        tabTitle: '资料归档',
                        auth: ''
                    }
                },
                {
                    path: '/Drawing/DrawingApply',
                    name: 'Drawing',
                    component: Drawing,
                    meta: {
                        tabTitle: '图纸台账',
                        auth: ''
                    }
                },
                {
                    path: '/equipment/index',
                    name: 'EquipmentIndex',
                    component: EquipmentIndex,
                    meta: {
                        tabTitle: '设备台帐',
                        auth: ''
                    }
                },
                {
                    path: '/powerGridMonitor/index',
                    name: 'powerGridMonitor',
                    component: powerGridMonitor,
                    meta: {
                        tabTitle: '电网配置',
                        auth: ''
                    }
                },
                {
                    path: '/powerGridMonitor/edit/:id?',
                    name: 'powerGridConfig',
                    component: powerGridConfig,
                    meta: {
                        tabTitle: '电网配置',
                        auth: ''
                    }
                },
                {
                    path: '/business/customerAccount',
                    name: 'CustomerAccountIndex',
                    component: CustomerAccountIndex,
                    meta: {
                        tabTitle: '用电用户管理',
                        auth: ''
                    }
                },
                {
                    path: '/business/electricityPrice',
                    name: 'Electricity Price',
                    component: ElectricityPrice,
                    meta: {
                        tabTitle: '电价表',
                        auth: ''
                    }
                },
                {
                    path: '/business/factorAdjustedPowerPrice',
                    name: 'factorAdjustedPowerPrice',
                    component: FactorAdjustedPowerPrice,
                    meta: {
                        tabTitle: '力率调整电费表',
                        auth: ''
                    }
                },
                {
                    path: '/handbook/index',
                    name: 'Handbook',
                    component: Handbook,
                    meta: {
                        tabTitle: '制度台账',
                        auth: ''
                    }
                },
                {
                    path: '/patrol/patrol',
                    name: 'Patrol',
                    component: Patrol,
                    meta: {
                        tabTitle: '巡检任务管理',
                        auth: ''
                    }
                },
                {
                    path: '/patrol/patrolAssignment',
                    name: 'PatrolAssignment',
                    component: PatrolAssignment,
                    meta: {
                        tabTitle: '巡检台账',
                        auth: ''
                    }
                },
                {
                    path: '/sys/dict',
                    name: 'Dict',
                    component: Dict,
                    meta: {
                        tabTitle: t => t('main.nav-menu.dict'),
                        auth: ''
                    }
                },
                {
                    path: '/sys/user',
                    name: 'User',
                    component: User,
                    meta: {
                        tabTitle: t => t('main.nav-menu.user'),
                        auth: ''
                    }
                },
                {
                    path: '/sys/role',
                    name: 'Role',
                    component: Role,
                    meta: {
                        tabTitle: t => t('main.nav-menu.role'),
                        auth: ''
                    }
                },
                {
                    path: '/sys/menu',
                    name: 'Menu',
                    component: Menu,
                    meta: {
                        tabTitle: t => t('main.nav-menu.menu'),
                        auth: ''
                    }
                },
                {
                    path: '/sys/flow',
                    name: 'Flow',
                    component: Flow,
                    meta: {
                        tabTitle: t => t('main.nav-menu.flow'),
                        auth: ''
                    }
                },
                {
                    path: '/sys/flowDeploy',
                    name: 'FlowDeploy',
                    component: FlowDeploy,
                    meta: {
                        tabTitle: t => t('main.nav-menu.flowDeploy'),
                        auth: ''
                    }
                },
                {
                    path: '/sys/orgUnit',
                    name: 'OrgUnit',
                    component: OrgUnit,
                    meta: {
                        tabTitle: t => t('main.nav-menu.orgUnit'),
                        auth: ''
                    }
                },
                {
                    path: '/sys/orgUnitUser',
                    name: 'OrgUnitUser',
                    component: OrgUnitUser,
                    meta: {
                        tabTitle: t => t('main.nav-menu.orgUnitUser'),
                        auth: ''
                    }
                },
                {
                    path: '/sys/orgPosition',
                    name: 'OrgPosition',
                    component: OrgPosition,
                    meta: {
                        tabTitle: t => t('main.nav-menu.orgPosition'),
                        auth: ''
                    }
                },
                {
                    path: '/sys/modeler',
                    name: 'Modeler',
                    component: Modeler,
                    meta: {
                        tabTitle: t => t('main.nav-menu.modeler'),
                        auth: ''
                    }
                },
                {
                    path: '/sys/widget',
                    name: 'Widget',
                    component: Widget,
                    meta: {
                        tabTitle: '组件管理',
                        auth: ''
                    }
                },
                {
                    path: '/currentData/bdz',
                    name: 'Bdz',
                    component: Bdz,
                    meta: {
                        tabTitle: '变电站',
                        auth: ''
                    }
                },
                {
                    path: '/currentData/hwgQcy1',
                    name: 'HwgQcy1',
                    component: HwgQcy1,
                    meta: {
                        tabTitle: '10kV汽车园I回线1号户外开关站',
                        auth: ''
                    }
                },
                {
                    path: '/currentData/hwgQcy2',
                    name: 'HwgQcy2',
                    component: HwgQcy2,
                    meta: {
                        tabTitle: '10KV汽车园2回1号户外开关站',
                        auth: ''
                    }
                },
                {
                    path: '/currentData/hwgYj1',
                    name: 'HwgYj1',
                    component: HwgYj1,
                    meta: {
                        tabTitle: '10KV园江线1户外开关站',
                        auth: ''
                    }
                },
                {
                    path: '/currentData/hwgYj2',
                    name: 'HwgYj2',
                    component: HwgYj2,
                    meta: {
                        tabTitle: '10KV园江线2号户外开关站',
                        auth: ''
                    }
                },
                {
                    path: '/currentData/hwgYk1',
                    name: 'HwgYk1',
                    component: HwgYk1,
                    meta: {
                        tabTitle: '10KV园卡线1号户外开关站',
                        auth: ''
                    }
                },
                {
                    path: '/currentData/hwgYk2',
                    name: 'HwgYk2',
                    component: HwgYk2,
                    meta: {
                        tabTitle: '10KV园卡线2号户外开关站',
                        auth: ''
                    }
                },
                {
                    path: '/currentData/hwgYk3',
                    name: 'HwgYk3',
                    component: HwgYk3,
                    meta: {
                        tabTitle: '10KV园卡线3号户外开关站',
                        auth: ''
                    }
                },
                {
                    path: '/currentData/hwgYx1',
                    name: 'HwgYx1',
                    component: HwgYx1,
                    meta: {
                        tabTitle: '10KV园小线1号户外开关站',
                        auth: ''
                    }
                },
                {
                    path: '/currentData/hwgYx2',
                    name: 'HwgYx2',
                    component: HwgYx2,
                    meta: {
                        tabTitle: '10KV园小线2号户外开关站',
                        auth: ''
                    }
                },
                {
                    path: '/currentData/hwgYx3',
                    name: 'HwgYx3',
                    component: HwgYx3,
                    meta: {
                        tabTitle: '10KV园小线3号户外开关站',
                        auth: ''
                    }
                },
                {
                    path: '/currentData/hwgYx4',
                    name: 'HwgYx4',
                    component: HwgYx4,
                    meta: {
                        tabTitle: '10KV园小线4号户外开关站',
                        auth: ''
                    }
                },
                {
                    path: '/sys/schedule',
                    name: 'Schedule',
                    component: Schedule,
                    meta: {
                        tabTitle: '定时任务',
                        auth: ''
                    }
                },
                {
                    path: '/dispatchingManagement/planned/planned',
                    name: 'Planned',
                    component: Planned,
                    meta: {
                        tabTitle: '计划停电',
                        auth: ''
                    }
                },
                {
                    path: '/dispatchingManagement/unPlanned/unPlanned',
                    name: 'UnPlanned',
                    component: UnPlanned,
                    meta: {
                        tabTitle: '非计划停电',
                        auth: ''
                    }
                },
                {
                    path: '/dispatchingManagement/production/production',
                    name: 'Production',
                    component: Production,
                    meta: {
                        tabTitle: '投产申请',
                        auth: ''
                    }
                },
                {
                    path: '/twoTickets/operationOrder/list/operationOrderList',
                    name: 'operationOrderList',
                    component: OperationOrderList,
                    meta: {
                        tabTitle: '操作票管理',
                        auth: ''
                    }
                },
                {
                    path: '/twoTickets/workTicket/list/workTicketList',
                    name: 'workTicketList',
                    component: WorkTicketList,
                    meta: {
                        tabTitle: '工作票管理',
                        auth: ''
                    }
                },
                {
                    path: '/powerCut/powerCut',
                    name: 'powerCut',
                    component: PowerCut,
                    meta: {
                        tabTitle: '停电申请',
                        auth: ''
                    }
                },
                {
                    path: '/teamConstruction/train',
                    name: 'train',
                    component: Train,
                    meta: {
                        tabTitle: '培训台账',
                        auth: ''
                    }
                },
                {
                    path: '/powerQualityMonitoring/voltageMonitoring',
                    name: 'voltageMonitoring',
                    component: VoltageMonitoring,
                    meta: {
                        tabTitle: '电压监测',
                        auth: ''
                    }
                },
                {
                    path: '/powerQualityMonitoring/frequencyMonitoring',
                    name: 'frequencyMonitoring',
                    component: FrequencyMonitoring,
                    meta: {
                        tabTitle: '频率监测',
                        auth: ''
                    }
                },
                {
                    path: '/powerQualityMonitoring/harmonicMonitoring',
                    name: 'harmonicMonitoring',
                    component: HarmonicMonitoring,
                    meta: {
                        tabTitle: '谐波监测',
                        auth: ''
                    }
                },
                {
                    path: '/powerQualityMonitoring/threePhaseUnbalancedVoltageMonitoring',
                    name: 'threePhaseUnbalancedVoltageMonitoring',
                    component: ThreePhaseUnbalancedVoltageMonitoring,
                    meta: {
                        tabTitle: '三相不平衡电压监测',
                        auth: ''
                    }
                },
                {
                    path: '/dispatchingManagement/protectionFixedValue/protectionFixedValue',
                    name: 'protectionFixedValue',
                    component: ProtectionFixedValue,
                    meta: {
                        tabTitle: '保护定值',
                        auth: ''
                    }
                },
                {
                    path: '/twoTickets/urgentRepairs/urgentRepairs',
                    name: 'urgentRepairs',
                    component: UrgentRepairs,
                    meta: {
                        tabTitle: '紧急抢修工作票',
                        auth: ''
                    }
                },
                {
                    path: '/peripheralUnits/peripheralUnits',
                    name: 'peripheralUnits',
                    component: PeripheralUnits,
                    meta: {
                        tabTitle: '外围运维单位管理',
                        auth: ''
                    }
                },
                {
                    path: '/version/Version',
                    name: 'Version',
                    component: Version,
                    meta: {
                        tabTitle: '版本信息',
                        auth: ''
                    }
                },
                {
                    path: '/external/HtmlPanel',
                    name: 'External-html-panel',
                    component: htmlPanel,
                    meta: {
                        tabTitle: '实时数据',
                        auth: ''
                    }
                },
                {
                    path: '/visualization/IncomeAndExpenditure',
                    name: 'IncomeAndExpenditure',
                    component: IncomeAndExpenditure,
                    meta: {
                        tabTitle: '收支情况',
                        auth: ''
                    }
                },
                {
                    path: '/visualization/running',
                    name: 'Running',
                    component: Running,
                    meta: {
                        tabTitle: '运行情况',
                        auth: ''
                    }
                },
                {
                    path: '/visualization/profitandsell',
                    name: 'Profitandsell',
                    component: Profitandsell,
                    meta: {
                        tabTitle: '营收情况',
                        auth: ''
                    }
                },
                {
                    path: '/visualization/Business',
                    name: 'Business',
                    component: Business,
                    meta: {
                        tabTitle: '购售电业情况',
                        auth: ''
                    }
                },
                {
                    path: '/log/log',
                    name: 'Log',
                    component: Log,
                    meta: {
                        tabTitle: '调度日志',
                        auth: ''
                    }
                },
                {
                    path: '/dispatchingManagement/issuedTask/issuedTaskList',
                    name: 'IssuedTask',
                    component: IssuedTask,
                    meta: {
                        tabTitle: '任务下发',
                        auth: ''
                    }
                },
                {
                    path: '/dispatchingManagement/constantValue/constantValueList',
                    name: 'ConstantValue',
                    component: ConstantValue,
                    meta: {
                        tabTitle: '定值业务',
                        auth: ''
                    }
                },
                {
                    path: '/dispatchingManagement/defectManagement/index',
                    name: 'DefectManagement',
                    component: DefectManagement,
                    meta: {
                        tabTitle: '缺陷管理',
                        auth: ''
                    }
                },
                {
                    path: '/dispatchingManagement/hiddenManagement/index',
                    name: 'DefectManagement',
                    component: HiddenManagement,
                    meta: {
                        tabTitle: '隐患管理',
                        auth: ''
                    }
                },
                {
                    path: '/MintorPiont/MintorPiont',
                    name: 'MintorPiont',
                    component: MintorPiont,
                    meta: {
                        tabTitle: '监测点维护',
                        auth: ''
                    }
                },
                {
                    path: '/dispatchingManagement/planned/handle',
                    name: 'Handle',
                    component: Handle,
                    meta: {
                        tabTitle: '计划停电申请',
                        auth: ''
                    }
                },
                {
                    path: '/dispatchingManagement/planned/review',
                    name: 'Review',
                    component: Review,
                    meta: {
                        tabTitle: '计划停电申请',
                        auth: ''
                    }
                },
                {
                    path: '/dispatchingManagement/planned/info',
                    name: 'Info',
                    component: Info,
                    meta: {
                        tabTitle: '计划停电申请',
                        auth: ''
                    }
                },
                {
                    path: '/dispatchingManagement/unPlanned/handle',
                    name: 'UnplannedHandle',
                    component: UnplannedHandle,
                    meta: {
                        tabTitle: '非计划停电申请',
                        auth: ''
                    }
                },
                {
                    path: '/dispatchingManagement/unPlanned/review',
                    name: 'UnplannedReview',
                    component: UnplannedReview,
                    meta: {
                        tabTitle: '非计划停电申请',
                        auth: ''
                    }
                },
                {
                    path: '/dispatchingManagement/unPlanned/info',
                    name: 'UnplannedInfo',
                    component: UnplannedInfo,
                    meta: {
                        tabTitle: '非计划停电申请',
                        auth: ''
                    }
                },
                {
                    path: '/dispatchingManagement/production/handle',
                    name: 'ProductionHandle',
                    component: ProductionHandle,
                    meta: {
                        tabTitle: '投产申请',
                        auth: ''
                    }
                },
                {
                    path: '/dispatchingManagement/production/review',
                    name: 'ProductionReview',
                    component: ProductionReview,
                    meta: {
                        tabTitle: '投产申请',
                        auth: ''
                    }
                },
                {
                    path: '/dispatchingManagement/production/info',
                    name: 'ProductionInfo',
                    component: ProductionInfo,
                    meta: {
                        tabTitle: '投产申请',
                        auth: ''
                    }
                },
                {
                    path: '/twoTickets/workTicket/list/workTicketInfo',
                    name: 'WorkTicketInfo',
                    component: WorkTicketInfo,
                    meta: {
                        tabTitle: '工作票申请',
                        auth: ''
                    }
                },
                {
                    path: '/twoTickets/workTicket/list/workTicketHandle',
                    name: 'WorkTicketHandle',
                    component: WorkTicketHandle,
                    meta: {
                        tabTitle: '工作票申请',
                        auth: ''
                    }
                },
                {
                    path: '/deviceMsg/deviceMsg',
                    name: 'DeviceMsg',
                    component: DeviceMsg,
                    meta: {
                        tabTitle: '设备预警发送短信信息',
                        auth: ''
                    }
                }, {
                    path: '/deviceMsg/msgConf',
                    name: 'DeviceMsgConf',
                    component: DeviceMsgConf,
                    meta: {
                        tabTitle: '设备预警配置',
                        auth: ''
                    }
                },
                {
                    path: '/teamConstruction/materialForTraining/index',
                    name: 'MaterialForTraining',
                    component: MaterialForTraining,
                    meta: {
                        tabTitle: '培训台账',
                        auth: ''
                    }
                },
                {
                    path: '/terminalEquipment/terminalEquipment',
                    name: 'TerminalEquipment',
                    component: TerminalEquipment,
                    meta: {
                        tabTitle: '配变监测终端监测台账',
                        auth: ''
                    }
                }, {
                    path: '/twoTickets/urgentRepairs/handler',
                    name: 'UrgentRepairsHandle',
                    component: UrgentRepairsHandle,
                    meta: {
                        tabTitle: '紧急抢修工作票新增',
                        auth: ''
                    }
                }
            ]
        },
        {
            path: '/unauthorized',
            name: 'ErrorUnauthorized',
            component: ErrorUnauthorized
        },
        {
            path: '*',
            name: 'ErrorPageNotFound',
            component: ErrorPageNotFound
        }
    ]
});
// 当前Vue实例
const whiteList = ['/login'];
// 导航守卫
router.beforeEach(async (to, from, next) => {
    const {userAuth, userInfo, userMenu} = store.state;
    //如果路由在白名单里，则不需要验证权限，直接next()
    if (whiteList.includes(to.path)) {
        next();
        return;
        // eslint-disable-next-line no-prototype-builtins
    } else if (!userInfo.hasOwnProperty('userId')) {
        try {
            await store.dispatch('getUserInfo');
        } catch (e) {
            next('/login');
        }
    }
    if (userMenu == null || userMenu.length === 0) {
        await store.dispatch('getUserMenu');
    }
    if (userAuth == null || userAuth.length === 0) {
        await store.dispatch('getMyAuth');
    }
    if (authCheck(to.meta.auth)) {
        next();
    } else {
        next('/unauthorized');
    }
});

// 解决在升级了Vue-Router版本到到3.1.0及以上之后，页面在跳转路由控制台会报Uncaught (in promise)的问题
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-534952949
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch(err => err);
};

export default router;
