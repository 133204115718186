/**
 * Vuex Store
 */

import Vue from 'vue';
import Vuex from 'vuex';
import state from './initial-state';
import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

Vue.use(Vuex);

const modulesFiles = require.context('./modules', true, /\.js$/);
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    // set './app.js' => 'app'
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
    const value = modulesFiles(modulePath);
    modules[moduleName] = value.default;
    return modules;
}, {});

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules,
    state,
    mutations,
    actions,
    getters,
    strict: debug
});
