/**
 * getters
 */

export const userInfo = (state) => state.userInfo;

export const userAuth = (state) => state.userAuth;

export const userMenu = (state) => state.userMenu;

export const enumList = (state) => state.enumList;

export const sidebar = (state) => state.app.sidebar;
export const device = (state) => state.app.device;

export const language = (state) => state.app.language;

export const drag = (state) => state.drag;

export const token = (state) => state.token;

export const jwtToken = (state) => state.jwtToken;

export const taskCount = (state) => state.taskCount;
