/**
 * actions
 */

import * as Client from '@/client';
import types from './mutation-types';
import {forEach} from 'lodash';
//用户登录
export const doLogin = async ({commit}, params) => {
    return new Promise((resolve, reject) => {
        Client.doLogin(params)
            .then((data) => {
                commit(types.SET_TOKEN, data.token);
                commit(types.SET_JWTTOKEN, data.jwtToken);
                sessionStorage.setItem('token', data.token);
                sessionStorage.setItem('jwtToken', data.jwtToken);
                resolve();
            })
            .catch((e) => {
                reject(e);
            });
    });
};

//用户登出
export const doLogout = async ({commit}, params) => {
    return new Promise((resolve, reject) => {
        Client.doLogout()
            .then((data) => {
                commit(types.clearState);
                resolve(data);
            })
            .catch((e) => {
                reject(e);
            });
    });
};

export const getUserInfo = async ({commit}) => {
    try {
        const data = await Client.getUserInfo();
        commit(types.SET_USER_INFO, data.user);
        const user = await Client.getUserById(data.user.userId);
        commit(types.SET_USER_ROLE, user.user.roleIdList);
    } catch (error) {
        // eslint-disable-next-line
        console.log('getUserInfo', error);
    }
};
//获取用户的权限列表
export const getMyAuth = async ({commit}) => {
    try {
        const data = await Client.getMenu();
        commit(types.SET_USER_AUTH, data.permissions);
    } catch (error) {
        // eslint-disable-next-line
        console.log('getMyAuth error', error);
    }
};

// 获取菜单信息
export const getUserMenu = async ({commit}) => {
    try {
        const data = await Client.getMenu();
        // 所有menuItem的url增加前缀'/'
        const addSlash = (data) => {
            forEach(data, (val, index) => {
                if (index === 'url' && val) {
                    data[index] = '/' + val;
                }
                if (typeof val === 'object') {
                    addSlash(val);
                }
            });
        };
        addSlash(data.menuList);
        commit(types.SET_USER_MENU, data.menuList);
        commit(types.SET_USER_AUTH, data.permissions);
    } catch (error) {
        // eslint-disable-next-line
        console.log('getMenu error', error);
    }
};

// 获取任务计数
export const getTaskCount = async ({commit}) => {
    return new Promise((resolve, reject) => {
        const params = [
            {
                pageNumber: 999,
                pageSize: 1,
                definitionCode: [
                    'elec_overhaul_planned', //计划检修
                ],
                type: 'pending',
            },
            {
                pageNumber: 999,
                pageSize: 1,
                definitionCode: [
                    'elec_overhaul_unplanned', //非计划检修
                ],
                type: 'pending',
            },
            {
                pageNumber: 999,
                pageSize: 1,
                definitionCode: [
                    'elec_dispatcn_hidden', // 隐患管理
                ],
                extraProp: {
                    curNodeCode: 'elec_dispatcn_hidden_add_receipt',
                },
            },
            {
                pageNumber: 999,
                pageSize: 1,
                definitionCode: [
                    'elec_dispatcn_flaw', //缺陷管理
                ],
                extraProp: {
                    curNodeCode: 'elec_dispatcn_flaw_add_receipt',
                },
            },
            {
                pageNumber: 999,
                pageSize: 1,
                definitionCode: [
                    'elec_production', // 投产申请
                ],
                type: 'pending',
            },

            {
                pageNumber: 999,
                pageSize: 1,
                definitionCode: [
                    'elec_task_issue', //任务下发
                ],
                type: 'pending',
            },
            {
                pageNumber: 999,
                pageSize: 1,
                definitionCode: [
                    'elec_line_work_ticket_1', //线路一工作票
                ],
                type: 'pending',
            },
            {
                pageNumber: 999,
                pageSize: 1,
                definitionCode: [
                    'elec_line_work_ticket_2', // 线路2
                ],
                type: 'pending',
            },
            {
                pageNumber: 999,
                pageSize: 1,
                definitionCode: [
                    'elec_factory_work_ticket_1', // 厂站1
                ],
                type: 'pending',
            },
            {
                pageNumber: 999,
                pageSize: 1,
                definitionCode: [
                    'elec_factory_work_ticket_2', // 厂站2
                ],
                type: 'pending',
            },
            {
                pageNumber: 999,
                pageSize: 1,
                definitionCode: [
                    'elec_factory_work_ticket_3', // 厂站3
                ],
                type: 'pending',
            },
            {
                pageNumber: 999,
                pageSize: 1,
                definitionCode: [
                    'elec_urgentRepairs_ticket', // 紧急抢修工作票
                ],
                type: 'pending',
            },
        ];
        Promise.all([
            Client.getMyTaskCount({ignorShowLoading: true}),
            Client.getBatchQuery(params, {ignorShowLoading: true}),
        ])
            .then((res) => {
                const taskCount = res[0].data;
                taskCount.elecOverhaulPlanned = res[1].data[0].totalItemCount;
                taskCount.elecOverhaulUnplanned = res[1].data[1].totalItemCount;
                taskCount.elecDispatcnHidden = res[1].data[2].totalItemCount;
                taskCount.elecDispatcnFlaw = res[1].data[3].totalItemCount;
                taskCount.elecProduction = res[1].data[4].totalItemCount;
                taskCount.elecTaskIssue = res[1].data[5].totalItemCount;
                taskCount.elecWorkTicket = res[1].data[6].totalItemCount;
                taskCount.elecWorkTicket += res[1].data[7].totalItemCount;
                taskCount.elecWorkTicket += res[1].data[8].totalItemCount;
                taskCount.elecWorkTicket += res[1].data[9].totalItemCount;
                taskCount.elecWorkTicket += res[1].data[10].totalItemCount;
                taskCount.elecUrgentRepairs = res[1].data[11].totalItemCount;
                commit(types.SET_TASK_COUNT, taskCount);
                resolve();
            })
            .catch((e) => {
                reject(e);
            });
    });
};
