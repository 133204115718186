/**
 * mutation types
 */

export default {
    SET_USER_INFO: 'SET_USER_INFO',
    SET_USER_AUTH: 'SET_USER_AUTH',
    SET_USER_ROLE: 'SET_USER_ROLE',
    SET_USER_MENU: 'SET_USER_MENU',
    SET_TOKEN: 'SET_TOKEN',
    SET_JWTTOKEN: 'SET_JWTTOKEN',
    SET_LOGIN_TYPE: 'SET_LOGIN_TYPE',
    SET_ENUM_LIST: 'SET_ENUM_LIST',
    i18nChanged: 'i18nChanged',
    updateCollapse: 'updateCollapse',
    updateHeaderHeight: 'updateHeaderHeight',
    updateAsideWidth: 'updateAsideWidth',
    updateIsMobile: 'updateIsMobile',
    clearState: 'clearState',
    SET_TASK_COUNT: 'SET_TASK_COUNT',
};
