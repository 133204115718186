<template>
    <div class="empty-block">
        <div class="empty-block__img"><el-image :src="url" :fit="fit"></el-image></div>
        <div class="empty-block__text">暂无数据~</div>
    </div>
</template>
<script>
import emptyUrl from '@/assets/icons/empty.png';
export default {
    data() {
        return {
            url: emptyUrl,
            fit: 'contain',
        };
    },
};
</script>
<style lang="less" scoped>
.empty-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    &__img {
        width: 130px;
        height: 120px;
    }
    &__text {
        color: #a1a1a1;
    }
}
</style>