<template>
    <component :is="item.span ? 'el-col' : 'span'" :span="item.span" v-show="!item.hidden" v-if="!item.nonExit">
        <el-form-item :label="item.label" :prop="item.prop">
            <el-input
                v-if="item.itemType === 'input'"
                v-bind="item.options"
                v-on="item.on"
                v-model="fieldValue"
            ></el-input>
            <el-input
                v-if="item.itemType === 'textarea'"
                v-bind="item.options"
                type="textarea"
                v-on="item.on"
                :autosize="{minRows: 3, maxRows: 20}"
                v-model="fieldValue"
            ></el-input>
            <el-input-number
                v-if="item.itemType === 'number'"
                v-bind="item.options"
                v-on="item.on"
                v-model="fieldValue"
            ></el-input-number>
            <el-radio-group v-bind="item.options" v-if="item.itemType === 'radio'" v-model="fieldValue" v-on="item.on">
                <el-radio
                    v-for="(option, i) in item.options.option"
                    v-bind="option.options"
                    :label="option.value"
                    :key="i"
                    >{{ item.label }}</el-radio
                >
            </el-radio-group>
            <template v-if="item.itemType == 'checkbox'">
                <el-checkbox-group v-bind="item.options" v-model="fieldValue" v-on="item.on">
                    <el-checkbox
                        v-for="(option, j) in item.options.option"
                        v-bind="option.options"
                        :label="option.value"
                        :key="j"
                        >{{ itm.label }}</el-checkbox
                    >
                </el-checkbox-group>
            </template>
            <el-select v-if="item.itemType === 'select'" v-bind="item.options" v-model="fieldValue" v-on="item.on">
                <el-option
                    v-for="(option, i) in item.options.option"
                    v-bind="option.options"
                    :key="i"
                    :label="typeof option === 'object' ? option[item.options.valueKey || 'key'] : option"
                    :value="typeof option === 'object' ? option[item.options.labelKey || 'value'] : option"
                ></el-option>
            </el-select>
            <el-switch
                v-if="item.itemType === 'switch'"
                v-bind="item.options"
                active-color="#13ce66"
                inactive-color="#EEEEEE"
                v-model="fieldValue"
                v-on="item.on"
            ></el-switch>
            <template v-if="item.itemType === 'time'">
                <el-time-select
                    v-bind="item.options"
                    v-model="fieldValue"
                    v-on="item.on"
                    :value-format="item.options['value-format'] || timeFormat"
                ></el-time-select>
            </template>
            <template v-if="item.itemType === 'date'">
                <el-date-picker
                    v-bind="item.options"
                    v-on="item.on"
                    v-model="fieldValue"
                    :format="item.options.format || dateFormat"
                    :value-format="item.options['value-format'] || dateFormat"
                ></el-date-picker>
            </template>
            <template v-if="item.itemType === 'datetime'">
                <el-date-picker
                    v-bind="item.options"
                    v-on="item.on"
                    type="datetime"
                    v-model="fieldValue"
                    :format="item.options.format || datetimeFormat"
                    :value-format="item.options['value-format'] || datetimeFormat"
                ></el-date-picker>
            </template>
            <template v-if="item.itemType === 'upload'">
                <ex-uploader v-bind="item.options" v-model="fieldValue" v-on="item.on"></ex-uploader>
            </template>
        </el-form-item>
    </component>
</template>
<script>
import {getPropByPath} from 'element-ui/src/utils/util';
export default {
    props: {
        item: {
            type: Object,
        },
        formData: {
            type: Object,
        },
    },
    data() {
        return {
            dateFormat: 'yyyy-MM-dd',
            timeFormat: 'HH:mm:ss',
            datetimeFormat: 'yyyy-MM-dd HH:mm',
        };
    },
    computed: {
        fieldValue: {
            get() {
                const model = this.formData;
                if (!model || !this.item.prop) {
                    return;
                }

                let path = this.item.prop;
                if (path.indexOf(':') !== -1) {
                    path = path.replace(/:/, '.');
                }

                return getPropByPath(model, path, true).v;
            },
            set(value) {
                const model = this.formData;
                if (!model || !this.item.prop) {
                    return;
                }

                let path = this.item.prop;
                if (path.indexOf(':') !== -1) {
                    path = path.replace(/:/, '.');
                }

                let {o, k, v} = getPropByPath(model, path, true);
                if (o && k && typeof o == 'object') {
                    this.$set(o, k, value);
                }
            },
        },
    },
    methods: {},
    mounted() {},
};
</script>
<style scoped>
.hidden {
    display: none;
}
</style>