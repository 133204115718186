/**
 * utils
 */

import isPlainObject from 'lodash/isPlainObject';
import Vue from 'vue';
import path from 'path';
import _ from 'lodash';
import store from '@/vuex';
import axiosInstance from '@/client/axios.js';
// import localI18n from '@/i18n/index.js';

const arrayLogicalAnd = (arr, arrCompare) => {
    return arrCompare.every(item => {
        return arr.includes(item);
    });
};

const arrayLogicalOr = (arr, arrCompare) => {
    return arrCompare.some(item => {
        return arr.includes(item);
    });
};

export const authCheck = authToken => {
    const auth = store.state.userAuth || [];
    if (authToken === null || authToken === undefined || authToken === '') {
        // 无需权限
        return true;
    } else if (Array.isArray(authToken)) {
        // 权限是数组，则只需要数组中包含一个权限即认为有权限
        return arrayLogicalOr(auth, authToken);
    } else if (isPlainObject(authToken)) {
        const logical = authToken.logical || 'and';
        const authTokenVal = authToken.value || [];
        if (logical === 'and') {
            // 数组中需包含全部权限
            return arrayLogicalAnd(auth, authTokenVal);
        }
        // 只需要数组中包含一个权限即认为有权限
        return arrayLogicalOr(auth, authTokenVal);
    }

    return auth.includes(authToken);
};

export const checkPropExists = obj => {
    return !(obj === undefined || obj === null);
};

export const checkEmpty = obj => {
    if (obj === undefined || obj === null) {
        return true;
    }
    if (typeof obj === 'boolean') {
        return false;
    }
    if (typeof obj === 'number') {
        return isNaN(obj);
    }
    if (typeof obj === 'string') {
        return obj.trim() === '';
    }
    return _.isEmpty(obj);
};

export const formatMoney = (str, num) => {
    /*
     * 参数说明：
     * str：要格式化的数字
     * num：保留几位小数
     * */
    let n = num,
        s = str;
    // eslint-disable-next-line
    n = n > 0 && n <= 20 ? n : 2;
    s = parseFloat((s + '').replace(/[^\d.-]/g, '')).toFixed(n) + '';
    const l = s
        .split('.')[0]
        .split('')
        .reverse();
    const r = s.split('.')[1];
    let t = '';
    for (let i = 0; i < l.length; i++) {
        // eslint-disable-next-line
        t += l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? ',' : '');
    }
    return (
        t
            .split('')
            .reverse()
            .join('') +
        '.' +
        r
    );
};

export const optionDateEnd = startDate => {
    return {
        disabledDate(time) {
            return startDate ? time.getTime() < new Date(startDate).getTime() : false;
        }
    };
};

export const optionDateStart = endDate => {
    return {
        disabledDate(time) {
            return endDate ? time.getTime() >= new Date(endDate).getTime() : false;
        }
    };
};

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'parentId') {
    const res = [];
    const temp = {};
    for (let i = 0; i < data.length; i++) {
        temp[data[i][id]] = data[i];
    }
    for (let k = 0; k < data.length; k++) {
        data[k].isShow = data[k].isShow === 1 ? true : false;
        if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
            if (!temp[data[k][pid]].children) {
                temp[data[k][pid]].children = [];
            }
            if (!temp[data[k][pid]]._level) {
                temp[data[k][pid]]._level = 1;
            }
            data[k]._level = temp[data[k][pid]]._level + 1;
            temp[data[k][pid]].children.push(data[k]);
        } else {
            res.push(data[k]);
        }
    }
    return res;
}
//获取字典数据
export const getDictData = (dictCode) => {
    return new Promise(function(resolve, reject) {
        const res = {
            list: [],
            map: {}
        };
        axiosInstance({
            url: '/base/dict/infoByDictCode',
            method: 'GET',
            params: {dictCode}
        }).then((data) => {
            for (const item of data.dictItems) {
                res.map[item.itemKey] = item.itemValue;
            }
            res.list = data.dictItems;
            resolve(res);
        }).catch(() => {
            reject(res);
        });
    });
};
//获取字典数据
export const getDictList = (dictCodes) => {
    return new Promise(function(resolve, reject) {
        axiosInstance({
            url: '/base/dict/listByDictCodes',
            method: 'GET',
            params: [dictCodes]
        }).then((data) => {
            const res = {};
            const codes = dictCodes.split(',');
            for (const code of codes) {
                res[code] = {
                    list: [],
                    map: {}
                };
                for (const dict of data.list) {
                    for (const item of dict.dictItems) {
                        res[dict.dictCode].map[item.itemKey] = item.itemValue;
                    }
                    res[dict.dictCode].list = dict.dictItems;
                }
            }

            resolve(res);
        }).catch((e) => {
            reject(e);
        });
    });
};
// 文件下载方法通用
export const fileDownload = (url, options = {method: 'GET', data: null}) => {
    options.method = options.method || 'GET';
    return new Promise((res, rej) => {
        axiosInstance({
            url,
            method: options.method,
            responseType: 'blob', // important
            ignorShowLoading: false,
            isFileDownload: true,
            params: options.method === 'GET' ? options.data || {} : {},
            data: options.method !== 'GET' ? options.data || {} : {},
        })
            .then(response => {
                const contentDisposition =
                    response.headers['content-disposition'] || response.headers['Content-Disposition'];
                const resMessage = decodeURIComponent(
                    response.headers['x-custom-message'] || response.headers['X-Custom-Message'] || ''
                );

                if (contentDisposition) {
                    // 下载文件，优先以filename*=xxx的名字为下载的文件名，如果没有时，再取filename=xxxx的文件名。参考：https://developer.mozilla.org/zh-CN/docs/Web/HTTP/Headers/Content-Disposition
                    let filename =
                        (contentDisposition.match(/filename\*=[\w\W]*''([\w\W]*)/) || [])[1] ||
                        (contentDisposition.match(/filename="?([\s\S]*?)"?$/) || [])[1];

                    if (filename) {
                        filename = decodeURIComponent(filename);
                    }

                    //避免filename解析失败时，下载的文件格式不对的问题
                    if (!filename || filename === 'undefined') {
                        if (response.headers['content-type'] && response.headers['content-type'].indexOf('excel') > 0) {
                            filename = '文件.xls';
                        }
                    }

                    const blobModel = new Blob([response.data]);

                    if (!window.navigator.msSaveOrOpenBlob) {
                        // 非IE浏览器
                        const url = window.URL.createObjectURL(blobModel);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', filename);
                        document.body.appendChild(link);
                        link.click();
                        setTimeout(() => {
                            document.body.removeChild(link);
                        }, 0);
                    } else {
                        // IE 11
                        window.navigator.msSaveOrOpenBlob(blobModel, filename);
                    }

                    res(filename);
                } else if (resMessage) {
                    // 提示错误信息
                    rej(new Error(resMessage));
                } else {
                    rej(new Error('服务器错误！请稍后再试或者联系管理员'));
                }
            })
            .catch(error => {
                rej(error);
            });
    });
};

// pdf文件预览通用方法，参数必须为pdf文件的绝对路径
export const previewPdf = (url, title = 'PDF预览') => {
    let i18nValue = store.state.i18nValue;

    if (i18nValue === 'en') {
        i18nValue = 'en-US';
    }
    const pdfViewerUrl =
        axiosInstance.defaults.baseURL +
        `/pdf/web/viewer.html?file=${encodeURIComponent(url)}&title=${title}#locale=${i18nValue}`;

    // 为了防止浏览器阻止弹出新窗口，使用创建a元素点击的方式实现
    const link = document.createElement('a');
    link.href = pdfViewerUrl;
    link.title = title;
    link.style.visibility = 'hidden';
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
        document.body.removeChild(link);
    }, 0);
};
// pdf文件预览通用方法，参数必须为pdf文件的绝对路径
//在弹出框中预览
export const previewPdfInBox = (title, url) => {
    let i18nValue = store.state.i18nValue;

    if (i18nValue === 'en') {
        i18nValue = 'en-US';
    }

    const pdfViewerUrl =
        axiosInstance.defaults.baseURL + `/pdfjs/web/viewer.html?file=${encodeURIComponent(url)}#locale=${i18nValue}`;

    Vue.prototype
        .$msgbox({
            title,
            center: true,
            message: `<iframe width="100%" class="img-show" src="${pdfViewerUrl}"/>`,
            dangerouslyUseHTMLString: true,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: '下载',
            cancelButtonText: '关闭',
            customClass: 'img-show-box'
        })
        .then(action => {
            if (action === 'confirm') {
                const a = document.createElement('a'); //创建一个<a></a>标签
                a.href = url; // response is a blob
                a.download = decodeURIComponent(title + path.extname(url)); //文件名称
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        });
};

export const previewOffice = fileId => {
    return new Promise((res, rej) => {
        axiosInstance({
            url: `/web/v1/preview/${fileId}`,
            method: 'GET',
            ignorShowLoading: true,
            isFileDownload: true
        })
            .then(response => {
                if (response.data.succeed) {
                    const previewUrl = response.data.data;

                    const link = document.createElement('a');
                    link.href = previewUrl;
                    link.style.visibility = 'hidden';
                    link.setAttribute('target', '_blank');
                    link.setAttribute('rel', 'noopener noreferrer');
                    document.body.appendChild(link);
                    link.click();
                    setTimeout(() => {
                        document.body.removeChild(link);
                    }, 0);
                    res();
                } else {
                    rej(new Error(response.data.msg));
                }
            })
            .catch(error => {
                rej(error);
            });
    });
};

//自定义获取图片本地显示地址
export const getImageLocalUrl = url => {
    return new Promise(function(resolve, reject) {
        axiosInstance({
            url,
            method: 'get',
            responseType: 'blob',
            ignorShowLoading: false,
            isFileDownload: true
        })
            .then(({data}) => {
                const blob = new Blob([data]);
                resolve(window.URL.createObjectURL(blob));
            })
            .catch(e => {
                Vue.prototype.$message.error(e.toString());
                reject(e);
            });
    });
};

//预览并下载图片
export const previewAndDownImage = (title, url) => {
    getImageLocalUrl(url).then(res => {
        Vue.prototype
            .$msgbox({
                title,
                center: true,
                message: `<img class="img-show" src="${res}"/>`,
                dangerouslyUseHTMLString: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: '下载',
                cancelButtonText: '关闭',
                customClass: 'img-show-box'
            })
            .then(action => {
                if (action === 'confirm') {
                    const a = document.createElement('a'); //创建一个<a></a>标签
                    a.href = res; // response is a blob
                    a.download = decodeURIComponent(title + path.extname(url)); //文件名称
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }
            });
    });
};

// 修改Tab标题，关闭Tab
export const setVmForTabModify = vm => {
    return {
        changeTabTitle({titleKey, params = {}, routeName}) {
            let routeNameStr = routeName;
            if (!routeNameStr) {
                routeNameStr = vm.$route.name;
            }

            vm.$root.$emit('fe-framework:tab:change-title', titleKey, params, routeNameStr);
        },
        recoverTabTitle(routeName) {
            let routeNameStr = routeName;
            if (!routeNameStr) {
                routeNameStr = vm.$route.name;
            }

            vm.$root.$emit('fe-framework:tab:recover-title', routeNameStr);
        },
        closeTab(routeName) {
            let routeNameStr = routeName;
            if (!routeNameStr) {
                routeNameStr = vm.$route.name;
            }

            vm.$root.$emit('fe-framework:tab:close', routeNameStr);
        }
    };
};

/**
 * 判断是否在被iframe嵌套
 */
export function iframeRuntime() {
    return !(window.self === window.top);
}
function parseQuery(url) {
    const o = {};
    const queryString = url.split('?')[1];
    if (queryString) {
        queryString.split('&').forEach(item => {
            const [key, val] = item.split('=');
            const value = val ? decodeURI(val) : true;
            //          转码         无值赋值true
            // eslint-disable-next-line no-prototype-builtins
            if (o.hasOwnProperty(key)) {
                //   已有属性转为数组
                o[key] = [].concat(o[key], value);
            } else {
                o[key] = value;
            }
        });
    }
    return o;
}

export function setToken() {
    if (iframeRuntime()) {
        const query = parseQuery(window.location.href);
        if (query && query.access_token) {
            sessionStorage.setItem('token', query.access_token);
        }
    }
}

export function getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        return (c === 'x' ? (Math.random() * 16) | 0 : 'r&0x3' | '0x8').toString(16);
    });
}
