<template>
    <div class="uploader">
        <el-upload
            :action="uploadUrl"
            :headers="uploadHeaders"
            :list-type="listType"
            :on-preview="handlePictureCardPreview"
            :show-file-list="singleFileMode ? (disabled ? true: false) : $attrs['show-file-list']"
            :limit="singleFileMode ? 1: $attrs['limit']"
            :auto-upload="true"
            :on-success="fileUploadSucceedHandler"
            :file-list="uploadFiles"
            :class="{'uploader-disabled':disabled,'single-file-mode':singleFileMode}"
            :on-exceed="handleExceed"
            v-bind="$attrs"
        >
            <!-- 单文件上传 -->
            <template slot="default">
                <div
                    class="el-upload-list el-upload-list--picture-card"
                    v-if="singleFile && singleFileMode"
                    @click.stop="noop"
                >
                    <div class="el-upload-list__item is-success">
                        <img
                            :class="['el-upload-list__item-thumbnail',{'el-upload-list__item-thumbnail-icon':isIconPreview(singleFile)}]"
                            :src="singleFile.thumbUrl"
                            alt
                        />
                        <a
                            class="el-upload-list__item-name"
                            @click="handleClick(singleFile)"
                        >{{singleFile.name}}</a>
                        <el-progress
                            v-if="singleFile.status === 'uploading'"
                            :type="listType === 'picture-card' ? 'circle' : 'line'"
                            :stroke-width="listType === 'picture-card' ? 6 : 2"
                            :percentage="parsePercentage(singleFile.percentage)"
                        ></el-progress>
                        <span class="el-upload-list__item-actions">
                            <span
                                class="el-upload-list__item-preview"
                                @click="handlePictureCardPreview(singleFile)"
                                v-if="isImage(singleFile)"
                                title="预览"
                            >
                                <i class="el-icon-zoom-in"></i>
                            </span>
                            <span
                                class="el-upload-list__item-delete"
                                @click="handleDownload(singleFile)"
                                title="下载"
                            >
                                <i class="el-icon-download"></i>
                            </span>
                            <span
                                v-if="!disabled"
                                class="el-upload-list__item-delete"
                                @click="handleRemove(singleFile)"
                                title="删除"
                            >
                                <i class="el-icon-delete"></i>
                            </span>
                        </span>
                    </div>
                </div>
                <i class="el-icon-plus" v-else></i>
            </template>
            <!-- 多文件上传 -->
            <div slot="file" slot-scope="{file}">
                <img
                    :class="['el-upload-list__item-thumbnail',{'el-upload-list__item-thumbnail-icon':isIconPreview(file)}]"
                    :src="file.thumbUrl"
                    alt
                />
                <a class="el-upload-list__item-name" @click="handleClick(file)">{{file.name}}</a>
                <el-progress
                    v-if="file.status === 'uploading'"
                    :type="listType === 'picture-card' ? 'circle' : 'line'"
                    :stroke-width="listType === 'picture-card' ? 6 : 2"
                    :percentage="parsePercentage(file.percentage)"
                ></el-progress>
                <span class="el-upload-list__item-actions">
                    <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                        v-if="isImage(file)"
                        title="预览"
                    >
                        <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                        class="el-upload-list__item-delete"
                        @click="handleDownload(file)"
                        title="下载"
                    >
                        <i class="el-icon-download"></i>
                    </span>
                    <span
                        v-if="!disabled"
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                        title="删除"
                    >
                        <i class="el-icon-delete"></i>
                    </span>
                </span>
            </div>
        </el-upload>
        <template v-if="showEmpty">
            <div class="empty-box">
                <i class="el-icon-picture empty-box__image"></i>
                <p class="empty-box__title">没有附件</p>
            </div>
        </template>
        <el-dialog :visible.sync="previewDialogVisible" :append-to-body="true">
            <img
                width="100%"
                :src="previewUrl"
                :class="{previewFail:previewFail}"
                @error="handleImagePreviewError"
                alt
            />
            <p class="preview-title">{{previewName}}</p>
        </el-dialog>
    </div>
</template>

<script>
import {addThumbUrl, getFileType, Constants} from './utils';
import {fileDownload} from '@/utils';
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Array,
            default() {
                return [];
            },
        },
        onChange: {
            type: Function,
            default: null,
        },
        singleFileMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            uploadHeaders: {
                token: this.$store.state.token,
            },
            uploadUrl: this.$client.uploadFileUrl,
            uploadFiles: [],
            previewDialogVisible: false,
            previewUrl: '',
            previewName: '',
            previewFail: false,
            listType: 'picture-card',
        };
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                let uploadFiles = addThumbUrl(value, this.$client.serverUrl, this.$store.state.token);
                this.uploadFiles = uploadFiles;
            },
        },
    },
    computed: {
        singleFile() {
            return this.uploadFiles[0];
        },
        showEmpty() {
            if (this.singleFileMode && this.disabled && !this.uploadFiles.length) {
                return true;
            } else if (this.disabled && !this.uploadFiles.length) {
                return true;
            }
            return false;
        },
    },
    methods: {
        noop() {},
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择${this.$attrs.limit}个文件，本次选择了 ${files.length} 个文件，共选择了 ${
                    files.length + fileList.length
                } 个文件`
            );
        },
        handleImagePreviewError() {
            console.log('handleImagePreviewError -> Constants', Constants);
            this.previewUrl = Constants.ICON_FROM_TYPE.image;
            this.previewName = '预览失败';
            this.previewFail = true;
        },
        parsePercentage(val) {
            return parseInt(val, 10);
        },
        isImage(file) {
            let ext = file.ext;
            if (file.raw && file.raw.name) {
                ext = file.raw.name.slice(file.raw.name.lastIndexOf('.') + 1);
            }
            let type = getFileType(ext);
            return type === 'image';
        },
        isIconPreview(file) {
            let ext = file.ext;
            if (file.raw && file.raw.name) {
                ext = file.raw.name.slice(file.raw.name.lastIndexOf('.') + 1);
            }
            let type = getFileType(ext);
            return !['image', 'pdf'].includes(type);
        },
        handlePictureCardPreview(file) {
            if (this.isImage(file)) {
                let url = `${this.$client.serverUrl}files/${file.id}?token=${this.$store.state.token}`;
                this.previewUrl = url;
                this.previewName = file.name;
                this.previewFail = false;
                this.previewDialogVisible = true;
            } else {
                this.$message({
                    message: '暂不支持预览此文件，请下载查看',
                    type: 'error',
                });
            }
        },
        handleDownload(file) {
            fileDownload(`files/${file.id}`).catch((e) => {
                this.$message({
                    message: '操作失败！',
                    type: 'error',
                });
                console.log('file download error' + e.msg);
            });
        },
        handleRemove(file) {
            this.uploadFiles = this.uploadFiles.filter((item) => item.uid !== file.uid);
            this.$emit('input', this.uploadFiles);
        },
        fileUploadSucceedHandler(res, file) {
            if (!res.code) {
                let uploadFiles = addThumbUrl(res.data, this.$client.serverUrl, this.$store.state.token, file.uid);
                this.uploadFiles = [...this.uploadFiles, ...uploadFiles];
                this.$emit('input', this.uploadFiles);
            } else {
                this.$message({
                    message: res.msg,
                    type: 'error',
                });
            }
            // console.log('fileUploadSucceedHandler -> res, file, fileList', res, file, fileList);
            // this.fileList = fileList;
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-upload-list--picture-card .el-upload-list__item {
    position: relative;
}
/deep/ .el-upload--picture-card .el-upload-list--picture-card i {
    font-size: unset;
    color: unset;
}
.empty-box {
    width: 148px;
    height: 148px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #c0ccda;
    border-radius: 10px;
    color: #757575;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__image {
        font-size: 42px;
    }
    &__title {
        font-size: 12px;
    }
}
.el-upload-list__item-thumbnail-icon {
    width: 36px;
    margin-left: calc(50% - 18px);
    margin-top: 20px;
}
.el-upload-list__item-name {
    position: absolute;
    bottom: 0;
    background: #fff3;
    white-space: normal;
    display: block;
    width: 100%;
    color: #144775;
    font-size: 12px;
    text-align: center;
    padding: 0 5px;
    box-sizing: border-box;
    [class^='el-icon'] {
        color: #000;
    }
}
.preview-title {
    text-align: center;
}
.previewFail {
    width: 60px;
    /* padding: 100px; */
    margin-left: calc(50% - 30px);
    margin-top: 30px;
    margin-bottom: 30px;
}
.uploader-disabled /deep/ .el-upload {
    display: none;
}
</style>>
