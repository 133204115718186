<template>
    <el-table-column
        v-if="column.children && column.children.length > 0"
        :label="column.label"
        :align="column.align || 'center'"
        :header-align="column.headerAlign || column.align"
        :class-name="column.className"
        :label-class-name="column.labelClassName"
    >
        <template v-for="(child, childIndex) in column.children">
            <ex-search-table-column :column="child" :key="childIndex"></ex-search-table-column>
        </template>
    </el-table-column>
    <el-table-column
        :column-key="column.columnKey"
        :prop="column.prop"
        :label="column.label"
        :width="column.minWidth ? '-' : (column.width || 'auto')"
        :minWidth="column.minWidth || column.width || 'auto'"
        :fixed="column.fixed"
        :render-header="column.renderHeader"
        :sortable="column.sortable"
        :sort-by="column.sortBy"
        :sort-method="column.method"
        :resizable="column.resizable"
        :formatter="column.formatter"
        :show-overflow-tooltip="column.showOverflowTooltip == null ?  showOverflowTooltip : column.showOverflowTooltip "
        :align="column.align || 'center'"
        :header-align="column.headerAlign || column.align"
        :class-name="column.className"
        :label-class-name="column.labelClassName"
        :selectable="column.selectable"
        :reserve-selection="column.reserveSelection"
        :filters="column.filters"
        :filter-placement="column.filterPlacement"
        :filter-multiple="column.filterMultiple"
        :filter-method="column.filterMethod"
        :filtered-value="column.filteredValue"
        v-else-if="column.type === undefined"
    >
        <template slot-scope="scope" :scope="newSlotScope ? 'scope' : false">
            <span
                v-if="column.filter"
            >
                {{ Vue.filter(column['filter'])(scope.row[column.prop]) }}
            </span>
            <span v-else-if="column.slotName">
                <slot
                    :name="column.slotName"
                    :row="scope.row"
                    :$index="scope.$index"
                />
            </span>
            <span
                v-else-if="column.render">
                {{ column.render(scope.row) }}
            </span>
            <span
                v-else-if="column.renderHtml"
                v-html="column.renderHtml(scope.row)"
            ></span>
            <span
                v-else-if="column.formatter"
            >
                {{ column.formatter(scope.row, scope.column, scope.row[column.prop], scope.$index) }}
            </span>
            <span v-else>
                {{ scope.row[column.prop] }}
            </span>
        </template>
    </el-table-column>
    <el-table-column v-bind="column" v-else></el-table-column>
</template>

<script>
export default {
    name: 'ExSearchTableColumn',
    props: {
        column: {
            type: Object,
            default: () => {
                return {};
            },
        },
        showOverflowTooltip: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style scoped>

</style>
