/**
 * 自定义扩展组件、功能等
 */

import Vue from 'vue';

import ExDrawer from '@/components/global-extends/ExDrawer';
import ExEditTableForm from '@/components/global-extends/ExEditTableForm';
import ExDateRangePicker from '@/components/global-extends/ExDateRangePicker';
import ExInputSuffixClear from '@/components/global-extends/ExInputSuffixClear';
import ExSearchTablePagination from '@/components/global-extends/ExSearchTablePagination/ExSearchTablePagination';
import ExTable from '@/components/global-extends/ExTable/ExTable';
import ExUploader from '@/components/global-extends/ExUploader/ExUploader.vue';
import ExSearchForm from '@/components/global-extends/ExSearchForm/ExSearchForm';
import ExFormItem from '@/components/global-extends/ExFormItem/ExFormItem.vue';
import UploadFile from '@/components/global-extends/UploadFile';
import TreeSelect from '@/components/global-extends/TreeSelect';
import HtmlPanel from '@/components/global-extends/HtmlPanel';
import Empty from '@/components/global-extends/Empty';
import ExCard from '@/components/global-extends/ExCard';
// import '@/components/BigdataTable';
Vue.component('ExDrawer', ExDrawer);
Vue.component('ExEditTableForm', ExEditTableForm);
Vue.component('ExDateRangePicker', ExDateRangePicker);
Vue.component('ExInputSuffixClear', ExInputSuffixClear);
Vue.component('ExSearchTablePagination', ExSearchTablePagination);
Vue.component('ExSearchForm', ExSearchForm);
Vue.component('ExFormItem', ExFormItem);
Vue.component('ExTable', ExTable);
Vue.component('ExUploader', ExUploader);
Vue.component('UploadFile', UploadFile);
Vue.component('TreeSelect', TreeSelect);
Vue.component('HtmlPanel', HtmlPanel);
Vue.component('Empty', Empty);
Vue.component('ExCard', ExCard);
