<template>
    <div id="app">
        <router-view />
        <footer style="z-index: 999;position: absolute;bottom: 0;text-align: center;left: 50%;transform: translateX(-50%);">
            <a style="color:white" href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2021003507号</a>
        </footer>
        <el-dialog :title="'提示'" append-to-body :visible.sync="browser.dialogShow" width="600px">
            <div
                class="ui-margin-10--bottom"
            >{{ browser.isChrome ? '您的浏览器版本较低，为了更好的浏览体验，建议您使用最新版的 Chrome 浏览器' : '为了更好的浏览体验，建议您使用最新版的 Chrome 浏览器' }}</div>

            <div class="ui-padding-5--wide ui-font--small">
                <a class="ui-color--primary" target="_blank" :href="browser.appmarketDownloadUrl">
                    <i class="el-icon-download"></i>
                    {{ '点击下载 Chrome 浏览器' }}
                </a>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as UserAgent from '@/utils/user-agent.js';
export default {
    name: 'AppIndex',
    data() {
        return {
            browser: {
                // 最低建议Chrome版本
                chromeVersion: 63,
                dialogShow: false,
                isChrome: true,
                appmarketDownloadUrl: 'https://appmarket.rlair.net/app/Chrome',
            },
        };
    },
    methods: {
        browserDetect() {
            this.browser.isChrome = UserAgent.isChrome();
            if (
                !UserAgent.isMobile() &&
                (!this.browser.isChrome || UserAgent.getChromeVersion() < this.browser.chromeVersion)
            ) {
                this.browser.dialogShow = true;
            }
        },
    },
    mounted() {
        this.browserDetect();
    },
};
</script>

<style>
html,
body,
#app {
    padding: 0;
    margin: 0;
    height: 100%;
    color: #fff;
}
.img-show-box {
    width: 40%;
    max-height: 100%;
}

.img-show {
    max-width: 100%;
}
</style>
