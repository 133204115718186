<!--
 /**
  *
  * 表格行内表单编辑组件
  *
  * author: lanbo
  *
  * version: 0.1
  *
  * description: 表格行内表单编辑组件，由 el-form 及 el-table 组件组成，因此可以设置 el-form 及 el-table 组件的 props 和 events
  *
  * props:
  *     * 接收 el-form 的 props
  *     table-props: Object | el-table 的 props
  *     table-events: Object | el-table 的 events
  *     table-class: String, Array, Object | el-table 的 class
  *     table-style: String, Array, Object | el-table 的 style
  *
  * methods:
  *     validateEditingRow(function callback(valid, invalidFields) {}) | 验证正在编辑行，回调第一个参数为 true 则验证成功，否则验证失败，当需要添加行时，需要调用此方法验证通过后再添加
  *     validateAllRow(function callback(valid, invalidFields) {}) | 验证所有的行，当有编辑行时先验证编辑行，回调第一个参数为 true 则验证成功，否则验证失败
  *     setEditingRow(row, force) | 设置 row 为编辑行，row 必须为 data 中的某一行，也可以设置为 null（不显示编辑行），force 是否强制换（默认 false），若 froce 为 true 则直接设置，否则若有编辑行则会先验证，通过后才设置
  *
  * changelog
  *     v0.1 2018-11-21 by lanbo | 基本功能
  *
  */
-->
<template>
    <el-form class="ex-edit-table-form" ref="exEditTableForm" v-bind="$attrs" :model="editingRow">
        <el-table
            ref="exEditTable"
            class="ui-table--edit"
            :class="tableClass"
            :style="tableStyle"
            v-bind="tablePropsComputed"
            v-on="tableEventsComputed"
        >
            <slot name="prepend" />
            <slot name="default" :editingRow="editingRow"></slot>
            <el-table-column v-if="showTableIndex" type="index" width="50"></el-table-column>
            <template v-for="(column, columnIndex) in columns">
                <el-table-column :key="columnIndex" v-bind="column">
                    <template slot-scope="scope">
                        <template v-if="scope.row === editingRow && !column.nonExit">
                            <ex-form-item :item="formItems[columnIndex]" :formData="editingRow"></ex-form-item>
                        </template>
                        <template
                            v-else-if="column.type === undefined"
                            slot-scope="scope"
                            :scope="newSlotScope ? 'scope' : false"
                        >
                            <span v-if="column.filter">{{ Vue.filter(column['filter'])(scope.row[column.prop]) }}</span>
                            <span v-else-if="column.slotName">
                                <slot :name="column.slotName" :row="scope.row" :$index="scope.$index" />
                            </span>
                            <span v-else-if="column.render">{{ column.render(scope.row) }}</span>
                            <span v-else-if="column.formatter">{{
                                column.formatter(scope.row, scope.column, scope.row[column.prop], scope.$index)
                            }}</span>
                            <span v-else>{{ scope.row[column.prop] }}</span>
                        </template>
                    </template>
                </el-table-column>
            </template>
            <slot name="append" />
        </el-table>
    </el-form>
</template>

<script>
import AsyncValidator from 'async-validator';
import {isEqual, assign, isEqualWith, cloneDeep} from 'lodash';
function noop() {}
export default {
    name: 'ExEditTableForm',
    inheritAttrs: false,
    props: {
        tableProps: {
            type: Object,
            default: () => ({}),
        },
        tableEvents: {
            type: Object,
            default: () => ({}),
        },
        tableClass: {
            type: [String, Array, Object],
            default: '',
        },
        tableStyle: {
            type: [String, Array, Object],
            default: '',
        },
        rowSave: {
            type: Function,
            default: noop,
        },
        columns: {
            type: Array,
            default: () => [],
        },
        formItems: {
            type: Array,
            default: () => [],
        },
        showTableIndex: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        'tableProps.data'(next, pre) {
            // 插入新编辑行时，滚动到最下面
            if (next && Array.isArray(next) && this._.isEmpty(next[next.length - 1])) {
                this.$nextTick(() => {
                    let dom = this.$refs.exEditTable.$el.querySelector('.el-table__body-wrapper');
                    dom && (dom.scrollTop = dom.scrollHeight);
                });
            }
        },
    },
    data() {
        return {
            editingRow: null,
        };
    },
    computed: {
        tablePropsComputed() {
            return {
                stripe: true,
                ...this.tableProps,
            };
        },
        tableEventsComputed() {
            return {
                ...this.tableEvents,
                'row-click': this.handleRowClick,
            };
        },
        tableData() {
            return this.tableProps.data || [];
        },
        rules() {
            return this.$attrs.rules || {};
        },
    },
    methods: {
        // 提供给外部校验使用
        validateEditingRow(callback) {
            let cb = callback;
            let promise;
            if (!this._.isFunction(cb)) {
                promise = new window.Promise((resolve, reject) => {
                    cb = function (valid, invalidFields) {
                        valid ? resolve(valid) : reject(invalidFields);
                    };
                });
            }

            if (this.editingRow) {
                this.handleValidateEditingRow()
                    .then(() => {
                        cb(true);
                    })
                    .catch((invalidFields) => {
                        cb(false, invalidFields);
                    });
            } else {
                cb(true);
            }

            return promise;
        },
        // 提供给外部进行全部校验
        validateAllRow(callback) {
            let cb = callback;
            let promise;

            if (!this._.isFunction(cb)) {
                promise = new window.Promise((resolve, reject) => {
                    cb = function (valid, invalidFields) {
                        valid ? resolve(valid) : reject(invalidFields);
                    };
                });
            }

            if (this.editingRow) {
                this.handleValidateEditingRow()
                    .then(() => {
                        // 验证通过，验证所有的row
                        this.handleValidateAllRow().then(({valid, invalidFields}) => {
                            cb(valid, invalidFields);
                        });
                    })
                    .catch((invalidFields) => {
                        cb(false, invalidFields);
                    });
            } else {
                // 验证所有的row
                this.handleValidateAllRow().then(({valid, invalidFields}) => {
                    cb(valid, invalidFields);
                });
            }

            return promise;
        },
        // 提供给外部设置编辑行
        setEditingRow(row, force = false) {
            if (force) {
                this.setEditingRowAndUpdateSlot(row);
            } else {
                this.validateEditingRow((valid) => {
                    if (valid) {
                        this.setEditingRowAndUpdateSlot(row);
                    }
                });
            }
        },
        async handleValidateAllRow() {
            for (let index = 0; index < this.tableData.length; index++) {
                const model = this.tableData[index];

                try {
                    await this.asyncValidator(model);
                } catch (invalidFields) {
                    this.setEditingRow(model);
                    setTimeout(() => {
                        this.handleValidateEditingRow();
                    }, 0);
                    return {valid: false, invalidFields};
                }
            }

            return {valid: true};
        },
        asyncValidator(model) {
            return new Promise((res, rej) => {
                const validator = new AsyncValidator(this.rules);
                validator.validate(model, {firstFields: true}, (errors, invalidFields) => {
                    if (errors) {
                        rej(invalidFields);
                    } else {
                        res();
                    }
                });
            });
        },
        async handleRowClick(...args) {
            const [row, column, event] = args;
            event.stopPropagation();
            if (this.disabled) {
                return;
            }
            if (column && column.type === 'action') {
                return;
            }
            this.emitEventRowClick(...args);

            // 如果是当前行，则不做任何修改
            if (this.editingRow === row) {
                return;
            }

            await this.changeEditingRow(row);
        },
        emitEventRowClick(...args) {
            if (this.tableEvents['row-click']) {
                this.tableEvents['row-click'](...args);
            }
        },
        async handleDocumentClick() {
            await this.changeEditingRow(null);
        },
        isRowChanged(row1, row2) {
            return !isEqualWith(row1, row2, function (va, vb, oa, ob, stack) {
                //当一个数字和一个字符串数字相等时判断数据为没改变
                if (typeof va !== 'object' && typeof vb !== 'object' && String(va) === String(vb)) {
                    return true;
                }
            });
        },
        isRowInData(row) {
            let index = this.tableProps.data.findIndex((it) => it === row);
            if (index !== -1) {
                return true;
            }
        },
        handleValidateEditingRow() {
            return new Promise((res, rej) => {
                this.$refs.exEditTableForm.validate((valid, invalidFields) => {
                    if (valid) {
                        res();
                        // 当前行验证通过
                        // 编辑行的数据已修改并且还在编辑状态
                        if (this.isRowChanged(this._editingRow, this.editingRow) && this.isRowInData(this.editingRow)) {
                            this.rowSave(this.editingRow);
                        }
                    } else {
                        // 验证不通过
                        rej(invalidFields);
                        return false;
                    }
                });
            });
        },
        async changeEditingRow(row) {
            if (this.editingRow) {
                try {
                    await this.handleValidateEditingRow();
                    this.setEditingRowAndUpdateSlot(row);
                } catch (error) {
                    return;
                }
            } else {
                this.setEditingRowAndUpdateSlot(row);
            }
        },
        //增加行数据比较是否编辑过
        setEditingRowAndUpdateSlot(row) {
            this.editingRow = row;
            this._editingRow = cloneDeep(row);
            this.updateSlot();
        },
        updateSlot() {
            const that = this.$refs.exEditTable;
            that.store.commit('setData', that.data);
            if (that.$ready) {
                that.$nextTick(() => {
                    that.doLayout();
                });
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.handleDocumentClick);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleDocumentClick);
    },
};
</script>
<style lang="less" scoped>
</style>

