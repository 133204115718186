//创建iframe
const _createIframe = () => {
    const iframe = document.createElement('iframe');
    iframe.style =
        'width: 100%; height: 100%; border: none; overflow: hidden; position: absolute; left:0; top: 0; z-index:-999';
    iframe.src = 'about:blank';
    return iframe;
};
/**
 * 判断一个变量是否是iframe
 * @param {*}} val
 */
const _isFunction = val => {
    return Object.prototype.toString.call(val) === '[object Function]';
};

/**
 * 自定义指定，实时监听某个dom元素的width/height变化
 */
const resize = {
    inserted(el, binding) {
        const {value} = binding;
        if (!_isFunction(value)) {
            throw new Error('directives v-resize must give a function');
        }
        const obj = {
            prevWidth: null,
            prevHeight: null,
            curWidth: el.clientWidth,
            curHeight: el.clientHeight
        };
        el.style.position = 'relative';
        const iframe = _createIframe();
        el.appendChild(iframe);
        setTimeout(() => {
            iframe.contentWindow.addEventListener('resize', () => {
                obj.prevHeight = obj.curHeight;
                obj.prevWidth = obj.curWidth;
                obj.curWidth = el.clientWidth;
                obj.curHeight = el.clientHeight;
                value(obj);
            });
        });
    }
};
export default resize;
