/**
 * 多语言文件
 *
 * 为了生成json，请勿修改模块导出方式
 */

module.exports = {
    // 应用级别多语言
    'app.browser.tip-text': '为了更好的浏览体验，建议您使用最新版的 Chrome 浏览器',
    'app.browser.tip-text-chrome': '您的浏览器版本较低，为了更好的浏览体验，建议您使用最新版的 Chrome 浏览器',
    'app.browser.download.appmarket': '点击下载 Chrome 浏览器',
    'app.socket.failed': 'websocket 连接失败！',
    'app.copyright': 'Copyright © 瑞丽航空 版权所有',
    'app.version-update': '版本更新',
    //头部多语言文件
    'header.staff.report': '员工自愿报告',
    'header.message': '消息',
    'header.sso': '瑞航通',
    'header.logout': '退出',
    'header.message.set-read': '设为已读',
    'header.message.set-all-read': '全部已读',
    'header.message.list': '消息列表',
    'tab.context-menu.close-left': '关闭左侧',
    'tab.context-menu.close-right': '关闭右侧',
    'tab.context-menu.close-other': '关闭其他',
    'tab.context-menu.close-all': '关闭所有',
    'tab.count.limit': '打开页面过多，请先尝试关闭一些页面，再打开新页面。',

    'tree-table.no-children': '无子节点',
    'logo.system': '滇中新区配售电一体化管理平台',
    'logo.company': '云南滇中新区配售电有限公司',

    // 导航菜单
    'main.nav-menu.index': '首页',
    'main.nav-menu.baseInfo-management': '基础信息管理',
    'main.nav-menu.baseInfo-management.aircraft-info': '飞机信息',
    'main.nav-menu.baseInfo-management.airport-group-info': '机场分组信息',
    'main.nav-menu.baseInfo-management.planeType-info': '机型信息',
    'main.nav-menu.baseInfo-management.airport-info': '机场信息',
    'main.nav-menu.baseInfo-management.route-info': '航线信息',
    'main.nav-menu.baseInfo-management.national-info': '国家信息',
    'main.nav-menu.baseInfo-management.maintainDepartment-info': '车间管理',

    'main.nav-menu.scheduleManagement-management': '排班管理',
    'main.nav-menu.personalCenter-management': '个人中心',
    'main.nav-menu.scheduleManagement-management.SecureSchedule': '安全排班',
    'main.nav-menu.scheduleManagement-management.FlySchedule': '飞行排班',
    'main.nav-menu.scheduleManagement-management.DispatchSchedule': '业务班排班',
    'main.nav-menu.scheduleManagement-management.AttendSchedule': '乘务排班',
    'main.nav-menu.scheduleManagement-management.FlightMonitoring': '航班监控',
    'main.nav-menu.scheduleManagement-management.ScheduleOperationalLog': '机组排班操作日志',
    'main.nav-menu.scheduleManagement-management.AircrewDynamicOperationalLog': '机组动态操作日志',
    'main.nav-menu.dev-playGround': '训练场',
    'main.nav-menu.dev-playGround.gantt-map': '甘特图',
    'main.nav-menu.dev-playGround.uplod-file': '附件',
    'main.nav-menu.scheduleManagement-management.SeatScheduleOperationalLog': '业务班排班操作日志',
    'main.nav-menu.scheduleManagement-management.WorkshopComponents': '车间人员安排',

    'main.nav-menu.scheduleManagement-management.MaintainMission': '维修任务设置',
    //人员信息管理
    'main.nav-menu.specialty-staff-management': '人员信息管理',
    'main.nav-menu.specialty-staff-management.other-staff-info': '其他人员信息',
    'main.nav-menu.specialty-staff-management.fly-staff-info': '飞行员信息',
    'main.nav-menu.specialty-staff-management.attendant-staff-info': '乘务员信息',
    'main.nav-menu.specialty-staff-management.secure-staff-info': '安全员信息',
    'main.nav-menu.specialty-staff-management.dispatch-staff-info': '签派员信息',
    'main.nav-menu.specialty-staff-management.maintain-staff-info': '维修员信息',
    'main.nav-menu.specialty-staff-management.other-companies-info': '外司人员信息',

    //授权管理
    'main.nav-menu.authorization-management.person-post-authorization-info': '人员岗位授权',
    'main.nav-menu.authorization-management.person-project-authorization-info': '独立项目授权',
    'main.nav-menu.authorization-management': '授权管理',
    'main.nav-menu.authorization-management.project': '授权项目配置',
    'main.nav-menu.authorization-management.post': '授权岗位配置',

    //资质证照管理
    'main.nav-menu.qualification-certificate-management': '资质证照管理',
    'main.nav-menu.license-management': '证照管理',
    'main.nav-menu.qualification-management': '资质管理',
    'main.nav-menu.qualification-technical-gradeInfo': '技术等级管理',
    'main.nav-menu.qualificationcertificate-flightExperienceInfo': '经历时间组',
    'main.nav-menu.qualificationcertificate-custom': '自定义管理',
    'main.nav-menu-credential-monitor': '资质管理监控中心',
    'main.nav-menu-technic-check': '技术检查管理',
    'main.nav-menu-credential-management': '技术资质证照管理',

    //报表监控
    'main.nav-menu.report-monitoring-info': '报表监控',
    'main.nav-menu.report-monitoring-info.monthly-hour-statistics': '机组月小时统计',
    'main.nav-menu.report-monitoring-info.hour-statistics': '机组小时统计',
    'main.nav-menu.report-monitoring-info.health-status-record': '机组健康状况记录',
    'main.nav-menu.report-monitoring-info.flight-hour-statistics': '飞行小时统计',
    'main.nav-menu.report-monitoring-info.aircraft-flight-hour-statistics': '航班飞行小时统计',
    'main.nav-menu.report-monitoring-info.days-fly-hours-statistics': '120天100小时经历',
    'main.nav-menu.report-monitoring-info.days-fly-nums-statistics': '90天3次起落监控',
    'main.nav-menu.report-monitoring-info.fly-experience-monitor': '经历监控管理',
    'main.nav-menu.report-monitoring-info.fatigue-coefficient': '乘务疲劳系数统计',
    'main.nav-menu.report-monitoring-info.safety-fatigue-coefficient': '安全疲劳系数统计',
    'main.nav-menu.report-monitoring-info.crew-fly-duty': '机组飞行值勤统计',
    'main.nav-menu.report-monitoring-info.halal-meal-config': '清真餐食维护',
    'main.nav-menu.report-monitoring-info.halal-meal-statistics': '清真餐食统计',
    'main.nav-menu.report-monitoring-info.crew-strength-report': '客舱实力报表',
    'main.nav-menu.LogisticService-management.continuous-calendar-day-monitor': '连续工作日监控报表',

    //航卫管理
    'main.nav-menu.aviationInfo-airborneFirstAidFacilitiesInfo-management': '机载急救设施',
    'main.nav-menu.aviationInfo-management': '航卫管理',
    'main.nav-menu.aviationInfo-recuperation-management': '疗养管理',
    'main.nav-menu.aviationInfo-stopRecoverFly-management': '停复飞管理',

    //排班设置
    'main.nav-menu.ScheduleSets-management': '排班设置',
    'main.nav-menu.ScheduleSets-management.CrewSchedulePost': '机组号位管理',
    'main.nav-menu.ScheduleSets-management.CrewSchedulePostGroup': '号位组管理',
    'main.nav-menu.ScheduleSets-management.SeatConfig': '业务班信息管理',
    'main.nav-menu.ScheduleSets-management.SpecialRelationship': '机组特殊关系',
    'main.nav-menu.ScheduleSets-management.FlightCrewScheduleRuleConfig': '机组排班规则',
    'main.nav-menu.ScheduleSets-management.SpecialRestrictionInfo': '特殊限制维护',
    'main.nav-menu.ScheduleSets-management.WhiteList': '时刻冲突校验白名单',
    'main.nav-menu.ScheduleSets-management.CredentialRuleConfig': '凭证规则配置',
    'main.nav-menu.ScheduleSets-management.MaintainPost': '维修岗位管理',
    'main.nav-menu.ScheduleSets-management.MaintainPostGroup': '航班岗位组管理',

    //车间轮换安排 WorkshopRotationInfo
    'main.nav-menu.ScheduleSets-management.MaintainDepartmentScheduleRule': '车间轮换安排',
    'main.nav-menu.ScheduleSets-management.DayShift': '白班',
    'main.nav-menu.ScheduleSets-management.NightShift': '夜班',
    'main.nav-menu.ScheduleSets-management.MaintainDepartmentNotNull': '车间排班不允许为空',
    'main.nav-menu.ScheduleSets-management.MaintainDepartmentRepeat': '车间排班重复',
    'main.nav-menu.ScheduleSets-management.StartTimeNotNull': '开始调整时间不能为空',
    'main.nav-menu.ScheduleSets-management.Airport': '站点',
    'main.nav-menu.ScheduleSets-management.StartTime': '开始调整时间',
    'main.nav-menu.ScheduleSets-management.Scheduling': '排班',
    'main.nav-menu.ScheduleSets-management.MaintainDepartment': '车间',

    //车间管理
    'maintain-department.table.code': '车间编号',
    'maintain-department.table.name': '车间名称',
    'maintain-department.table.baseAirport': '所属基地',
    'maintain-department.table.remark': '备注',
    'maintain-department.table.category': '车间类型',
    'maintain-department.table.airport': '站点',
    'maintain-department.table.dayShift': '白班业务班',
    'maintain-department.table.nightShift': '夜班业务班',

    //后勤管理
    'main.nav-menu.LogisticService-management': '后勤管理',
    'main.nav-menu.LogisticService-management.unit-overnight-statistics': '机组过夜统计',
    'main.nav-menu.LogisticService-management.virtual-recharge-statistics': '虚拟充值统计',
    'main.nav-menu.LogisticService-management.flight-task-book': '飞行任务书',

    // 系统设置
    'main.nav-menu.system-settings': '系统设置',
    'main.nav-menu.system-settings.config-info': '配置信息',
    'main.nav-menu.system-settings.data-dictionary-info': '数据字典',
    'main.nav-menu.system-settings.role-management': '角色管理',
    'main.nav-menu.system-settings.role-authorization': '角色授权',
    'main.nav-menu.system-settings.menu-authorization': '菜单授权',
    'main.nav-menu.system-settings.auth-management': '权限管理',
    'main.nav-menu.system-settings.user-management': '用户管理',
    'main.nav-menu.system-settings.user-authorization': '用户授权',
    'main.nav-menu.system-settings.component-management': '组件管理',
    'main.nav-menu.system-settings.role-data-management': '角色数据权限管理',
    'main.nav-menu.system-settings.message-push-configuration': '消息推送配置',
    'main.nav-menu.system-settings.synchronized-task-trigger-menu': '同步任务触发菜单',

    //业扩
    'main.nav-menu.business-apply.business-apply': '用户申请',
    'main.nav-menu.business-apply.reconnaissance': '勘测阶段',
    'main.nav-menu.business-apply.manage': '业务管理',
    'main.nav-menu.business-apply.electric-plan': '供电方案',
    'main.nav-menu.business-apply.completion-apply': '验收申请',
    'main.nav-menu.business-apply.completion-acceptance': '竣工验收',
    'main.nav-menu.business-apply.datum-archive': '资料归档',
    //图纸
    'main.nav-menu.drawing.drawing-apply': '图纸台账',
    'main.nav-menu.elec.monitor': '电网监控',
    'main.nav-menu.elec.config': '电网配置',
    'main.nav-menu.handbook': '手册台账',
    //系统
    'main.nav-menu.dict': '字典管理',
    'main.nav-menu.user': '用户管理',
    'main.nav-menu.role': '角色管理',
    'main.nav-menu.menu': '菜单管理',
    'main.nav-menu.flow': '流程管理',
    'main.nav-menu.flowDeploy': '流程部署',
    'main.nav-menu.roleFlow': '流程权限',
    'main.nav-menu.orgUnit': '组织机构管理',
    'main.nav-menu.orgUnitUser': '组织用户关系',
    'main.nav-menu.orgPosition': '职务管理',
    'main.nav-menu.modeler': '流程设计',

    'main.nav-menu.system-settings.menu-management': '菜单管理',
    // 人员动态
    'main.nav-menu.aircrew-dynamic.aircrew-dynamic': '人员动态',

    // 酒测
    'main.nav-menu.alcohol-check.alcohol-check-info': '酒精检测监控',

    // 飞行训练应用
    'main.nav-menu.fly-train': '飞行训练应用',
    'main.nav-menu.fly-train.oper-rate-config': '操纵比配置',
    'main.nav-menu.fly-train.check-oper-rate': '带飞检查操纵比',

    //通用


    common: {
        'range-separator': '至',
        'common.prompt': '提示',
        more: '更多条件',
        moreInformation: '更多>>',
        fold: '折叠',
        required: '必填',
        loading: '加载中...',
        nofiles: '暂无文件',
        export: '导出',
        'manul-match': '人工匹配',
        remark: '备注',
        time: '时间',
        refresh: '刷新',
        'refresh.success': '刷新成功',
        'refresh.failed': '刷新失败',
        'refresh-all-cache': '刷新所有权限缓存',
        'company-name': '瑞丽航空有限公司',
        exit: '退出',
        'no-permission': '您没有权限访问！',
        'login-expired': '登录信息已过期',
        'no-relate-permission': '没有相应权限',
        'no-credential': '没有相应凭证类型',
        'server-error': '服务器错误！请稍后再试或者联系管理员',
        'other-error': '发生错误！请稍后再试或者联系管理员',
        select: '请选择{item}',
        'select.a': '请选择',
        input: '请输入{item}',
        'select.1': '选择',
        delete: '删除',
        edit: '编辑',
        detail: '详情',
        warning: '提示',
        'confirm.refresh': '确认刷新？',
        'confirm.delete': '确认删除？',
        'confirm.commit': '确认提交？',
        'confirm.cancel': '确认取消？',
        'confirm.close': '确认关闭？',
        'confirm.open': '确认打开？',
        'confirm.close.confirm': '是否执行关闭确认？',
        'confirm.message': '确认{message}？',
        'confirm.whether': '是否{message}？',
        yes: '确定',
        confirm: '确认',
        save: '保存',
        'save-first': '请先保存修改数据',
        'finish-first': '请先完善数据',
        cancel: '取消',
        'cancel-delete': '取消删除',
        close: '关闭',
        new: '新建',
        'add-same-level': '添加（同级）',
        'add-next-level': '添加（下级）',
        operate: {
            success: '操作成功！',
            failed: '操作失败！'
        },
        handle: '处理',
        search: '查询',
        'add-new': '新增',
        reset: '重置',
        open: '展开',
        comment: '意见',
        'upload.error': '上传失败',
        'upload.hint.tip': '只能上传{fileType}文件，且不超过{maxSize}',
        'upload.exceed.error': '最多只能上传{limit}个文件',
        'upload.file-type.error': '不支持上传该类型文件，只能上传 {fileType} 文件',
        'no.data': '暂无数据',
        files: '文件',
        download: '下载',
        'search.panel': '高级搜索',
        'view.info': '查看',
        'back.list': '返回',
        'yesno.Y': '是',
        'yesno.N': '否',
        'yesno.1': '是',
        'yesno.0': '否',
        'validity.Y': '有效',
        'validity.N': '无效',
        'check-all': '全选',
        'select.nodata': '请先选择待操作数据',
        'select.more': '请选择多条操作数据',
        'select.multiple': '只允许对一条进行操作',
        'table.creater': '创建人',
        'table.create-time': '创建时间',
        'table.update-user': '更新人',
        'table.update-date': '更新时间',
        'table.is-deleted': '是否删除',
        'table.uplod-file': '附件',
        'table.keyword': '关键字',
        mon: '星期一',
        tues: '星期二',
        wed: '星期三',
        thur: '星期四',
        fri: '星期五',
        sat: '星期六',
        sun: '星期日',
        add: '增加',
        update: '更新',
        'show-detail': '查看详情',
        'bulk-update-effective-date': '批量更新失效日期',
        'select-staff-type-first': '请先选择人员类型',
        'select-time-right': '结束时间应大于开始时间',
        'show-history': '查看历史',
        'select-post': '请选择机组号位',
        import: '导入',
        'select-file': '选择文件',
        'download-template': '下载模板',
        'download-success': '下载成功',
        'upload.success': '上传成功',
        'download.timeOut': '时间超过限制!',
        'input-int-workday': '请输入正整数个工作日',
        'input-int': '请输入100以内的正整数',
        'input-positive-number': '请输入正数',
        'only-select-excel-file': '只能上传xls/xlsx文件',
        'confirm.recovery': '确认恢复?',
        recovery: '恢复',
        'can-recovery-data-that-was-deleted': '只能对已删除数据进行恢复操作！',
        'can-delete-data-that-was-not-deleted': '只能对未删除数据进行删除操作！',
        'serial-number': '序号',
        addSameLevel: '添加（同级）',
        addNextLevel: '添加（下级）',
        refreshAllCache: '刷新所有权限缓存',
        year: '年',
        month: '月',
        day: '日',
        'start-month': '开始月份',
        'end-month': '结束月份',
        'current-month': '本月',
        'current-year-to-now': '今年至今',
        'lastest-six-month': '最近六个月'
    },

    //飞机信息管理模块
    'aircraft-info.table.number': '注册机号',
    'aircraft-info.table.type': '机型中文名',
    'aircraft-info.table.code': '性能编码',
    'aircraft-info.table.layoutCode': '布局编码',
    'aircraft-info.table.company': '所属公司',
    'aircraft-info.table.sn': '序列号',
    'aircraft-info.table.product-date': '出厂日期',
    'aircraft-info.table.into-date': '引进日期',
    'aircraft-info.table.conf': '机组配置',
    'aircraft-info.table.update-user': '更新人',
    'aircraft-info.table.update-date': '更新时间',
    'aircraft-info.form.keyword': '关键字',
    'aircraft-info.table.resting-facility-level': '休息设施等级',
    'aircraft-info.table.Chinese-name': '中文名',
    'aircraft-info.table.functional-code': '性能编码',

    //授权管理
    'projectAuthorization-info.form.name': '授权岗位',
    'projectAuthorization-info.form.qualificationName': '资质要求',
    'projectAuthorization-info.form.licenseName': '证照要求',
    'projectAuthorization-info.form.updater': '更新人',
    'projectAuthorization-info.form.updateTime': '更新时间',

    //文件上传和下载
    'file-upload.button.select-file': '添加文件',
    'file-upload.button.upload': '开始上传',
    'file-upload.button.delete-file': '删除文件',
    'file-upload.button.show-result': '显示上传结果',
    'file-upload.button.hidden-result': '隐藏上传结果',
    'file-upload.table.file-dscription': '文件描述',
    'file-upload.table.file-size': '文件大小',
    'file-upload.table.upload-time': '上传时间',
    'file-upload.tip.delete-tip': '请选择需要的删除文件!',

    //组件管理
    'component-management-info.table.aircrew-dynamic': '组件',
    'component-management-info.table.component-name-Chinese': '小部件中文名',
    'component-management-info.table.component-describetion-Chinese': '小部件示例描述',
    'component-management-info.table.component-name-English': '小部件英文名',
    'component-management-info.table.component-describetion-English': '小部件英文描述',
    'component-management-info.table.authority': '权限',
    'component-management-info.table.width': '默认宽',
    'component-management-info.table.height': '默认高',

    //国家信息
    'national-info.table.English-name': '英文名',
    'national-info.table.Chinese-name': '中文名',
    'national-info.table.code': '国家代码',
    'national-info.table.continent': '洲',
    'national-info.form.keyword': '关键字',
    //机型
    'plane-type.table.international-code': '国际代码',
    'plane-type.table.functional-code': '性能编码',
    'plane-type.table.layout-code': '布局编码',
    'plane-type.table.level-of-rest-facility': '机上休息设施等级',
    'plane-type.table.update-user': '更新人',
    'plane-type.table.update-date': '更新时间',
    'plane-type.table.base-info': '基础信息',
    'plane-type.table.English-name': '机型英文名',
    'plane-type.table.Chinese-name': '机型中文名',
    'plane-type.table.producer': '生产厂家',
    'plane-type.table.restricted-info': '限制信息',
    'plane-type.table.height-kind': '高度分类',
    'plane-type.table.wake-kind': '尾流分类',
    'plane-type.table.other-info': '其他信息',
    'plane-type.table.layout-info': '布局信息',
    'plane-type.table.first-class-num': '头等舱座位数',
    'plane-type.table.business-class-num': '商务舱座位数',
    'plane-type.table.economy-class-num': '经济舱座位数',
    'plane-type.table.space-of-rest-facility': '机上休息设施说明',

    //航线信息管理
    'route-info.table.routeName': '航线名称',
    'route-info.table.departureAirport': '起飞站',
    'route-info.table.arriveAirport': '到达站',
    'route-info.table.flyDistance': '飞行距离(海里)',
    'route-info.table.effectiveTimeStart': '有效开始时间',
    'route-info.table.effectiveTimeEnd': '有效结束时间',
    'route-info.table.isUseForForeign': '外籍可用',
    'route-info.table.remark': '备注信息',
    'route-info.table.routeType': '航路类型',

    'route-info.form.routeName': '航线名称',
    'route-info.form.departureAirport': '起飞站',
    'route-info.form.arriveAirport': '到达站',
    'route-info.form.isUseForForeign': '外籍可用',

    //机场信息管理
    'airport-info.table.icaoCode': '四字码',
    'airport-info.table.iataCode': '三字码',
    'airport-info.table.name': '机场名称',
    'airport-info.table.countryId': '国家',
    'airport-info.table.domesticRegion': '国内地区',
    'airport-info.table.cityShortName': '城市简称',
    'airport-info.table.cityName': '城市名称',
    'airport-info.table.caSubarea': '民航分区',
    'airport-info.table.baseType': '基地分类',
    'airport-info.table.isEnabled': '启用状态',
    'airport-info.table.timeZone': '时区',
    'airport-info.table.crewDriveBeginTime': '首车程时间',
    'airport-info.table.crewDriveEndTime': '尾车程时间',
    'airport-info.table.domesticDutyStartTime': '国内值勤期开始',
    'airport-info.table.internationalDutyStartTime': '国际值勤期开始',
    'airport-info.table.alcoholDetectionBeginTime': '酒测开始时间',
    'airport-info.table.alcoholDetectionEndTime': '酒测截止时间',
    'airport-info.table.specialAirdromeKind': '特殊机场分类',
    'airport-info.table.rank': '排序',
    'airport-info.form.keyword': '关键字',
    'airport-info.form.isDeleted': '是否删除',
    'airport-info.table.reference-line-information': '参考线信息',
    'airport-info.table.reference-line-type': '参考线类型',
    'airport-info.table.reference-line-name': '参考线名称',
    'airport-info.table.runway-number': '跑道号',
    'airport-info.table.formula': '公式',
    'airport-info.table.x-range': 'X值范围',
    'airport-info.tip.formula': '公式示例：y=a*x+b',
    'airport-info.tip.x-range': '格式：a,b',
    'airport-info.table.airportGroup': '机场分组',

    //机场分组信息
    'airport-group-info.table.airportName': '机场',
    'airport-group-info.table.groupName': '分组名称',
    'airport-group-info.table.beginTime': '进入开始时间',
    'airport-group-info.table.endTime': '进入结束时间',
    'airport-group-info.table.status': '是否有效',
    'airport-group-info.table.remark': '备注',

    //飞行员信息管理
    'staff-info.form.name': '姓名',
    'staff-info.form.team-name': '所属机队',
    'staff-info.form.post': '职务',
    'staff-info.form.phone': '移动电话',

    //特业人员字段
    ////字段
    'staff-info.table.name': '姓名',
    'staff-info.table.kind': '人员类型',
    'staff-info.table.job-number': '工号',
    'staff-info.table.sex': '性别',
    'staff-info.table.nation': '民族',
    'staff-info.table.country-name': '国籍',
    'staff-info.table.country-id': '国籍',
    'staff-info.table.country': '国籍',
    'staff-info.table.position': '职务',
    'staff-info.table.technic-name': '技术等级',
    'staff-info.table.phone': '移动电话',
    'staff-info.table.team-name': '所属机队',
    'staff-info.table.center': '所属中心',
    'staff-info.table.center-name': '所属中心',
    'staff-info.table.team': '所属机队',
    'staff-info.table.base-name': '所属基地',
    'staff-info.table.base': '所属基地',
    'staff-info.table.base-type': '基地类别',
    'staff-info.table.birthday': '出生日期',
    'staff-info.table.birthplace': '出生地',
    'staff-info.table.updater-name': '更新人',
    'staff-info.table.update-time': '更新时间',
    'staff-info.table.auth-position-name': '授权岗位',
    'staff-info.table.license-name': '运行资质',
    'staff-info.table.remark': '备注',
    'staff-info.table.contact-address': '通讯地址',
    'staff-info.table.enter-company-on': '入职日期',
    'staff-info.table.domestic-region': '国内地区',
    'staff-info.table.residential-address': '居住地址',
    'staff-info.table.post-code': '邮政编码',
    'staff-info.table.action': '操作',
    'staff-info.tip.noData': '暂无数据',
    'staff-info.table.is-administration': '自动排行政班',
    'staff-info.table.dimissionDate': '离职日期',
    'staff-info.table.department': '所属车间',
    ////教育经历
    'staff-info.table.school': '院校',
    'staff-info.table.major': '专业',
    'staff-info.table.degree': '学历',
    ////入职前经历
    'staff-info.table.companyName': '公司/单位名称',
    'staff-info.table.beginTime': '起始时间',
    'staff-info.table.endTime': '截止时间',
    'staff-info.table.planeType': '机型',
    'staff-info.table.flyHour': '飞行时间',
    'staff-info.table.flyExperienceHour': '其中飞行经历时间',
    'staff-info.table.flyNightHour': '其中夜间飞行时间',
    'staff-info.table.raiseFallNumber': '操作起落次数',
    'staff-info.tip.begin-time-and-end-time-are-not-null': '开始和结束时间不能都为空',
    ////不安全事件
    'staff-info.table.happenDate': '发生日期',
    'staff-info.table.accidentType': '事件种类',
    'staff-info.table.happenPlace': '发生地点',
    'staff-info.table.description': '简况和结论',
    'staff-info.table.documentNo': '公文号',
    'staff-info.table.regOrFlightNo': '飞机注册/航班号',
    'staff-info.table.uploadFile': '附件',
    'staff-info.table.viewFile': '查看附件',
    'staff-info.table.updaterName': '记录人',
    'staff-info.table.updateTime': '记录时间',
    'staff-info.button.upload': '点击上传',
    'staff-info.button.view': '查看附件',
    'staff-info.upload.needDataId': '请先增加信息，再维护附件',

    ////奖惩记录
    'staff-info.table.getTime': '奖惩时间',
    'staff-info.table.rewardOrPunishment': '奖惩类型',
    'staff-info.table.reason': '奖惩原因',
    'staff-info.table.acquisition': '奖惩内容',

    //人员信息,tab页title
    'staff-info.tab.base-info': '基本信息',
    'staff-info.tab.education': '教育经历',
    'staff-info.tab.work-experience': '入职前经历',
    'staff-info.tab.accident': '不安全事件',
    'staff-info.tab.reward-and-punishment': '奖惩记录',
    'staff-info.tab.credential-management': '资质证照',

    //授权岗位、授权项目
    'projectAuthorization-info.table.name': '授权岗位',
    'projectAuthorization-info.table.postName': '授权岗位',
    'projectAuthorization-info.table.projectName': '授权项目',
    'projectAuthorization-info.table.qualificationName': '资质要求',
    'projectAuthorization-info.table.code': '编码',
    'projectAuthorization-info.table.licenseName': '证照要求',
    'projectAuthorization-info.table.updater': '更新人',
    'projectAuthorization-info.table.updateTime': '更新时间',
    'projectAuthorization-info.table.effectiveDuration': '有效期限',
    'projectAuthorization-info.table.qualificationCredentialIds': '资质要求',
    'projectAuthorization-info.table.licenseCredentialIds': '证照要求',
    'projectAuthorization-info.table.projectAuthorizationIds': '关联项目授权',
    'projectAuthorization-info.table.securityEventDuration': '安全事件要求',
    'projectAuthorization-info.table.punishmentDuration': '惩罚记录要求',

    //人员岗位/项目授权
    'personauthorization-info.table.staffId': '姓名',
    'personauthorization-info.table.organization': '所属中心',
    'personauthorization-info.table.authorizationNo': '授权编号',
    'personauthorization-info.table.typePost': '授权岗位',
    'personauthorization-info.table.typeProject': '授权项目',
    'personauthorization-info.table.awardTime': '颁发日期',
    'personauthorization-info.table.effectiveTime': '失效日期',
    'personauthorization-info.table.remark': '备注',
    'personauthorization-info.table.status': '状态',
    'personauthorization-info.table.remainingDate': '剩余天数',
    'personauthorization-info.table.planeTypeId': '机型',
    'personauthorization-info.table.creater': '创建人',
    'personauthorization-info.table.createTime': '创建时间',
    'personauthorization-info.table.updater': '更新人',
    'personauthorization-info.table.updateTime': '更新时间',
    'personauthorization-info.table.isDelete': '是否删除',
    'personauthorization-info.table.addPerson': '添加人员',
    'personauthorization-info.table.set-history': '置为历史',
    'personauthorization-info.confirm.set-history': '确认置为历史？',

    //资质/证照管理/技术等级/飞行经历/自定义
    'licence-info.button.uploadLicence': '证照上传',
    'licence-info.table.licenceInfo': '证照信息',
    'licence-info.table.staffName': '机组姓名',
    'licence-info.table.licenceCredentialName': '证照名称',
    'licence-info.table.credentialNo': '证照号',
    'licence-info.table.planeType': '机型',
    'licence-info.table.level': '证照等级',
    'licence-info.table.getTime': '颁发日期',
    'licence-info.table.expireTime': '失效日期',
    'licence-info.table.remark': '备注',
    'licence-info.table.status': '状态',
    'licence-info.table.name': '姓名',
    'licence-info.table.staffType': '人员类型',
    'licence-info.table.classification': '凭证机组类型',
    'licence-info.table.remainderTime': '剩余时长（天）',
    'licence-info.table.releaseCountry': '签发国',
    'licence-info.table.staffEnglishName': '护照英文名',
    'licence-info.table.passportType': '护照类型',
    'qualification-info.table.qualificationCredentialName': '资质名称',
    'qualification-info.table.airport': '机场',
    'qualification-info.table.route': '航线',
    'qualification-info.table.isValid': '是否有效',
    'qualification-info.table.internationalRoute': '国际航线资质',
    'qualification-info.table.specialAirport': '特殊机场',
    'qualification-info.table.qualificationInfo': '资质信息',
    'technical-gradeInfo-info.table.staffType': '人员类型',
    'technical-gradeInfo-info.table.technical-grade': '技术等级',
    'technical-gradeInfo-info.table.technical-grade-name': '技术等级名称',
    'technical-gradeInfo-info.table.grading-date': '定级日期',
    'licence-info.button.addLicence': '证照新增',
    'licence-info.button.editLicence': '证照编辑',
    'qualification-info.table.addQualification': '资质新增',
    'qualification-info.table.editQualification': '资质编辑',
    'technical-gradeInfo-info.table.addTechnic': '技术等级新增',
    'technical-gradeInfo-info.table.editTechnic': '技术等级编辑',
    'flightExperience-info.table.flightExperienceCredentialName': '经历时间组',
    'FlightExperience-gradeInfo-info.table.grading-date': '进入开始时间',
    'flightExperience-info.table.expireTime': '进入结束时间',

    //自定义管理
    'custom-info.table.custom-credential-name': '分组名称',
    'custom-info.table.grading-date': '进入时间',
    'custom-info.table.grading-start-time': '进入开始时间',
    'custom-info.table.grading-end-time': '进入结束时间',
    'custom-info.table.expire-time': '失效时间',


    //凭证表多语言
    'credential.table.credential-type': '类型',
    'credential.table.credential-name': '名称',
    'credential.table.credential-englishName': '英文名称',
    'credential.table.rank': '排序',
    'credential.table.credential-english-name': '英文名',
    'credential.table.credential-code': '代码',
    'credential.table.credential-classification': '凭证分类',
    'credential.table.gain-trains': '授予训练',
    'credential.table.trains': '关联训练',
    'credential.table.maintain-train': '保持训练',
    'credential.table.remind-day': '提醒天数',
    'credential.table.remind-ways': '提醒方式',
    'credential.management': '技术资质证照配置',
    'credential.base': '基本信息',
    'credential.train': '训练关联',
    'credential.remind': '提醒信息',
    'credential.remind-label': '提醒时间为：有效期前xx个工作日',
    'credential.remind-before': '有效期前',
    'credential.remind-after': '工作日',
    'credential.remind-day-number': '多少天',
    'credential.remind-info': '（此处有效期如配置了保持训练，则为保持训练的有效期；否则为证照资质本身有效期）',
    'credential.table.hours': '飞行小时上限',
    'credential.table.technicalGrade': '技术级别',
    'credential.table.sameTechnicalGradeRank': '同技术级别排序',
    'credential.table.flightExperience': '经历详情',

    ////用户凭证表字段多语言
    'user-credential.table.staff-id': '特业人员',
    'user-credential.table.credential-id': '凭证ID',
    'user-credential.table.credential-No': '凭证号',
    'user-credential.table.get-time': '获得日期',
    'user-credential.table.expire-time': '失效日期',
    'user-credential.table.plane-type': '机型',
    'user-credential.table.remark': '备注',
    'qualification-info.table.is-valid': '是否有效',
    'user-credential.table.level': '等级',
    'user-credential.table.release-country': '签发国',
    'user-credential.table.staff-english-name': '护照英文名',
    'user-credential.table.airport': '机场',
    'user-credential.table.route': '航线',
    'user-credential.table.credential-type': '凭证类型',
    'user-credential.table.credential-classification': '人员类型',

    //技术检查管理
    ////技术检查管理功能相关的多语言配置
    'technic-check.flight-list': '已检查详情',
    'technic-check.add-flight': '新增手工关联',
    'technic-check.delete-relation': '删除关联',
    'technic-check.recover-relation': '撤销删除',
    'technic-check.manul-match': '新增手工关联',
    'technic-check.calculate': '重新匹配并计算',
    'technic-check.calculate-only-result-unknown': '只能对未设置结果的记录进行重新匹配并计算',
    'technic-check.manul-match-only-result-unknown': '只能对未设置结果的记录进行手动并联',
    'technic-check.calculate-tip': '重新匹配航班并计算已完成起/落次数，航段数，飞行小时数',
    'technic-check.cannot-deal-with-waiting': '不能对待录入状态的记录进行此操作，请先维护好开始和结束时间',

    ////技术检查表字段
    'technic-check.table.check-staff-id': '检查人',
    'technic-check.table.checked-staff-id': '被检查人',
    'technic-check.table.check-type-id': '检查类型',
    'technic-check.table.airport': '机场',
    'technic-check.table.route': '航线',
    'technic-check.table.begin-time': '检查开始时间',
    'technic-check.table.end-time': '检查结束时间',
    'technic-check.table.need-raise-number': '需检查起飞次数',
    'technic-check.table.need-fall-number': '需检查降落次数',
    'technic-check.table.actual-raise-number': '已完成起飞次数',
    'technic-check.table.actual-fall-number': '已完成降落次数',
    'technic-check.table.remain-number': '待检查次数',
    'technic-check.table.check-result': '检查结果',
    'technic-check.table.check-status': '检查状态',
    'technic-check.table.plane-type': '机型',
    'technic-check.table.crew-schedule-post': '关联号位',
    'technic-check.table.need-route-number': '需航段数',
    'technic-check.table.actual-route-number': '已完成航段数',
    'technic-check.table.remain-route-number': '剩余航段数',
    'technic-check.table.need-number': '机场起落数',
    'technic-check.table.remark': '备注说明',
    'technic-check.table.need-fly-hour': '需检查飞行小时数',
    'technic-check.table.actual-fly-hour': '已完成飞行小时数',
    'technic-check.base-info': '技术检查信息',
    'technic-check.flight': '已检查详情',

    ////技术检查关联航班字段
    'technic-check-flight.table.flight-dynamic-id': '航班动态id',
    'technic-check-flight.table.ATA': '到港时间',
    'technic-check-flight.table.ATD': '离港时间',
    'technic-check-flight.table.flight-date': '航班日期',
    'technic-check-flight.table.flight-no': '航班号',
    'technic-check-flight.table.flight-type': '航班类型',
    'technic-check-flight.table.route': '航线',
    'technic-check-flight.table.departure-ap': '起飞站',
    'technic-check-flight.table.arrival-ap': '到达站',
    'technic-check-flight.table.captain': '机长',
    'technic-check-flight.table.registration-num': '机号',
    'technic-check-flight.table.fly-hour': '飞行小时',
    'technic-check-flight.table.instructor': '教员',
    'technic-check-flight.table.inspector': '检查员',
    'technic-check-flight.result-setting': '技术检查结果录入',

    ////资质监控消息表字段
    'credential-remind.table.staff-id': '特业人员',
    'credential-remind.table.remind-time': '提醒时间',
    'credential-remind.table.remind-type': '提醒类别',
    'credential-remind.table.content': '消息内容',
    'credential-remind.table.status': '处理状态',

    ////训练详情，此为培训系统的返回结果表字段
    'train-record.table.train-type': '训练类别',
    'train-record.table.train-method': '训练方式',
    'train-record.table.begin-time': '开始日期',
    'train-record.table.end-time': '结束日期',
    'train-record.table.train-staff': '受训人',
    'train-record.table.train-address': '训练地点',
    'train-record.table.train-result': '结论或成绩',
    'train-record.table.oper': '操作',
    'train-record.table.explain': '说明',
    'train-record.table.staffType': '人员类型',
    'train-record.table.trainingInstitutions': '训练机构',

    ////资质监控中心功能类多语言
    'credential-remind.train-record': '训练详情',
    'credential-remind.relate-credential': '关联证照/资质/技术等级',
    'credential-remind.owned-or-not': '是否已具备',
    'credential-remind.message-oper': '消息处理',
    'credential-remind.not-supported': '暂不支持此类型的消息处理',
    'credential-remind.set-handled-or-not': '是否将此提醒置为已处理？',
    'credential-remind.not-support-handle': '暂不支持处理此类型的消息',
    'credential-remind.set-handled': '置为已处理',
    'credential-remind.submit-and-set-handled': '保存',
    'credential-remind.technic-maintain': '技术检查维护',

    //机组号位管理
    'crewschedulepost-info.table.scheduleType': '排班性质',
    'crewschedulepost-info.table.postCode': '号位代码',
    'crewschedulepost-info.table.postName': '号位名称',
    'crewschedulepost-info.table.abbreviation': '号位简写',
    'crewschedulepost-info.table.isEnable': '是否启用',
    'crewschedulepost-info.table.isPartPost': '是否兼职',
    'crewschedulepost-info.table.rank': '排序字段',
    'crewschedulepost-info.table.showInSchedule': '排班显示',
    'crewschedulepost-info.table.isRelationPost': '是否关联其它号位',
    'crewschedulepost-info.table.relationPostId': '关联号位',
    'crewschedulepost-info.table.creater': '创建者',
    'crewschedulepost-info.table.createTime': '创建时间',
    'crewschedulepost-info.table.updater': '更新者',
    'crewschedulepost-info.table.updateTime': '更新时间',
    'crewschedulepost-info.table.isDelete': '是否删除',
    'crewschedulepost-info.table.partPostProperty': '兼职号位',
    'crewschedulepost-info.table.technicStandardProperty': '技术等级',
    'crewschedulepost-info.table.isDefault': '人员唯一时默认勾选',

    //业务班信息管理
    'seatconfig-info.table.businessType': '业务班类别',
    'seatconfig-info.table.seatName': '业务班名称',
    'seatconfig-info.table.affiliated': '所属基地',
    'seatconfig-info.table.checkInLocation': '打卡位置',
    'seatconfig-info.table.isOnDutySeat': '是否值班席位',
    'seatconfig-info.table.isAlcoholDetection': '是否需要酒精检测',
    'seatconfig-info.table.need': '需要',
    'seatconfig-info.table.notNeed': '不需要',
    'seatconfig-info.table.minNumber': '人员下限',
    'seatconfig-info.table.maxNumber': '人员上限',
    'seatconfig-info.table.creater': '创建者',
    'seatconfig-info.table.createTime': '创建时间',
    'seatconfig-info.table.updater': '更新者',
    'seatconfig-info.table.updateTime': '更新时间',
    'seatconfig-info.table.isDelete': '是否删除',
    'seatconfig-info.table.qualificationId': '运行资质',
    'seatconfig-info.table.licenseId': '所需证照',
    'seatconfig-info.table.technicStandardId': '技术等级',
    'seatconfig-info.table.seatConfigRules': '交接班规则',
    'seatconfig-info.table.edit-seat-rule': '请填入完整的交接班规则',
    'seatconfig-info.table.shifts': '班次',
    'seatconfig-info.table.workStartTime': '上班时间',
    'seatconfig-info.table.workEndTime': '下班时间',
    'seatconfig-info.table.isWorkStartInDay': '上班日',
    'seatconfig-info.table.isWorkEndInDay': '下班日',
    'seatconfig-info.table.action': '操作',
    'seatconfig-info.table.isSignIn': '是否打卡',
    'seatconfig-info.table.defaultRestTime': '默认休息时间(小时)',
    'seatconfig-info.actions.create': '业务班生成',
    'seatconfig-info.actions.publish': '业务班发布',
    'seatconfig-info.actions.delete': '业务班删除',
    'seatconfig-info.table.phone': '值班电话',
    'seatconfig-info.table.isShowMobilePhone': '显示个人电话',
    'seatconfig-info.table.isShowInSchedule': '是否在排班界面显示',
    'seatconfig-info.table.rank': '排序',
    'seatconfig-info.table.check-rank': '排序应为有效正整数',

    //机组特殊关系
    'specialrelationship-info.table.relationType': '关系类型',
    'specialrelationship-info.table.dealType': '处理方式',
    'specialrelationship-info.table.aircrewPerson': '机组人员',
    'specialrelationship-info.table.aircrewName': '机组姓名',

    //排班管理
    'schedule.actions.flight-group-loop': '航班组环',
    'schedule.actions.validate': '校验',
    'schedule.actions.validate-all': '校验所有',
    'schedule.actions.validate-selected': '校验所选',
    'schedule.actions.publish': '发布',
    'schedule.actions.publish-all': '发布所有',
    'schedule.actions.publish-selected': '发布所选',
    'schedule.actions.revoke': '撤销',
    'schedule.actions.create': '生成',
    'schedule.actions.delete': '删除',
    'schedule.actions.delete-all': '删除所有',
    'schedule.actions.delete-selected': '删除所选',
    'schedule.actions.empty': '清空',
    'schedule.actions.empty-all': '清空所有',
    'schedule.actions.empty-selected': '清空所选',
    'schedule.actions.revoke-select': '撤销所选',
    'schedule.actions.revoke-all': '撤销所有',
    'schedule.actions.revoke.publish_status-select': '撤销所选发布状态',
    'schedule.actions.revoke.people_change-select': '撤销所选人员更改',
    'schedule.actions.clear.flight_schedule-select': '清空所选航班排班',
    'schedule.actions.revoke.publish_status-all': '撤销所有发布状态',
    'schedule.actions.revoke.people_change-all': '撤销所有人员更改',
    'schedule.actions.clear.flight_schedule-all': '清空所有航班排班',
    'schedule.actions.revoke.publish_status': '撤销所选发布状态',
    'schedule.actions.revoke.group_change': '撤销所选组环更改',
    'schedule.actions.clear.group_schedule': '清空所选组环排班',
    'schedule.actions.export': '导出联线排班',
    'schedule.actions.export-all': '导出',
    'schedule.actions.lock': '锁定',
    'schedule.actions.lock-all': '锁定全部',
    'schedule.actions.lock-selected': '锁定所选',
    'schedule.actions.unlock': '解锁',
    'schedule.actions.unlock-all': '解锁全部',
    'schedule.actions.unlock-selected': '解锁所选',
    'schedule.actions.unsafe-area': '预警区域',
    'schedule.actions.sync': '时间联动',
    'schedule.actions.toggle-driving': '车程',
    'schedule.form.startTime': '开始日期',
    'schedule.form.endTime': '结束日期',
    'schedule.form.team': '所属机队',
    'schedule.form.groupType': '组环性质',
    'schedule.form.groupStatus': '排班状态',
    'schedule.form.flightNo': '航班号',
    'schedule.form.flightModel': '机型',
    'schedule.form.name': '姓名',
    'schedule.form.level': '技术等级',
    'schedule.form.base': '所属基地',
    'schedule.form.dispatch-seat': '业务班名称',
    'schedule.fields.date': '航班日期',
    'schedule.fields.isCanceled': '是否取消',
    'schedule.fields.registrationNums': '飞机号',
    'schedule.fields.flightNo': '航班号',
    'schedule.fields.trips': '航程',
    'schedule.fields.departed': '离港',
    'schedule.fields.arrival': '到港',
    'schedule.fields.duration': '时长',
    'schedule.fields.planningDutyTime': '计划飞值',
    'schedule.fields.actualDutyTime': '实际飞值',
    'schedule.fields.planningFlyTime': '计划飞行',
    'schedule.fields.actualFlyTime': '实际飞行',
    'schedule.fields.number': '人数',
    'schedule.fields.groupStatus': '排班状态',
    'schedule.fields.captain': '机长',
    'schedule.fields.responsible-captain': '责任机长',
    'schedule.fields.cruising-captain': '巡航机长',
    'schedule.fields.first-copilot': '第一副驾驶',
    'schedule.fields.second-copilot': '第二副驾驶',
    'schedule.fields.inspector': '检察员',
    'schedule.fields.fligh-student': '飞行学员',
    'schedule.fields.security-officer': '安全员',
    'schedule.fields.random-staff': '随机人员',
    'schedule.fields.day': '日期',
    'schedule.fields.week': '星期',
    'schedule.fields.begin-time': '开始时间',
    'schedule.fields.end-time': '结束时间',
    'schedule.fields.seat': '席位',
    'schedule.fields.schedule-status': '排班状态',
    'schedule.fields.number-min': '人员下限',
    'schedule.fields.number-max': '人员上限',
    'schedule.fields.on-duty': '值班人',
    'schedule.fields.qualification-require': '资质要求',
    'schedule.fields.technicalGrade-require': '技术等级要求',
    'schedule.fields.licence-require': '证照要求',
    'schedule.actions.combine': '组合航段',
    'schedule.actions.split': '拆分航段',
    'schedule.actions.message.select-groups': '请选择组环数据',
    'schedule.actions.message.select-flights': '请选择航班数据',
    'schedule.form.dispatch-type': '业务班类别',
    'schedule.fields.type': '类型',
    'schedule.fields.shifts': '班次',
    'schedule.form.staffType': '人员类型',
    'schedule.table.staff': '姓名',
    'schedule.table.startTime': '开始日期',
    'schedule.table.endTime': '结束日期',
    'schedule.actions.add-administrstive-schedule': '添加行政班',
    'schedule.actions.delete-administrstive-schedule': '删除行政班',
    'schedule.fields.rest-start-time': '休息开始时间',
    'schedule.fields.rest-end-time': '休息结束时间',
    'schedule.tip.required': '人员类型，姓名，人员证照，人员资质至少必填一项',
    'schedule.operate.verification-success': '校验通过',
    'schedule.message': '规则校验提醒',
    //排班gantt图
    'schedule.gantt.setting': '设置',
    'schedule.gantt.setting-action': '点击设置',
    'schedule.gantt.setting_name': '姓名',
    'schedule.gantt.setting_month-flytime': '月飞行时间',
    'schedule.gantt.setting_year-flytime': '年飞行时间',
    'schedule.gantt.setting_week_onduty': '周飞行值勤时间',
    'schedule.gantt.setting_month_onduty': '月飞行值勤时间',
    'schedule.gantt.setting_sort': '排序',
    'schedule.gantt.setting_sort_type': '顺序',
    'schedule.gantt.setting_sort_asce': '升序',
    'schedule.gantt.setting_sort_desc': '降序',
    'schedule.gantt.setting_monitoring_time': '监控时间',
    'schedule.gantt.loading': '努力渲染中....',
    'schedule.gantt.lock': '请先锁定该人员，再进行操作',
    'schedule.gantt.tip_technic': '技术等级',
    'schedule.gantt.tip_qualification': '人员资质',
    'schedule.gantt.tip_licenses': '人员证照',
    'schedule.gantt.tip_airport': '常住地：',
    'schedule.gantt.lockName': '锁定人：',
    'schedule.gantt.tip_plan_onduty': '计划值勤信息',
    'schedule.gantt.tip_onduty_start': '休息期结束时间：',
    'schedule.gantt.tip_onduty_end': '休息期开始时间：',
    'schedule.gantt.tip_onduty_time': '非休息期时间：',
    'schedule.gantt.tip_fly_start': '飞行值勤开始时间：',
    'schedule.gantt.tip_fly_end': '飞行值勤结束时间：',
    'schedule.gantt.tip_fly_onduty_time': '飞行值勤时间：',
    'schedule.gantt.tip_fly_time': '飞行时间：',
    'schedule.gantt.tip_actual_onduty': '实际值勤信息',
    'schedule.gantt.tip_flight_no': '航班号：',
    'schedule.gantt.tip_takeoff_ap': '起飞机场：',
    'schedule.gantt.arrive_ap': '目的机场：',
    'schedule.gantt.tip_aircraft_no': '飞机号：',
    'schedule.gantt.tip_plane_type': '机型：',
    'schedule.gantt.tip_plan_takeoff_time': '计划起飞时间：',
    'schedule.gantt.tip_plane_land_time': '计划落地时间：',
    'schedule.gantt.tip_actual_takeoff_time': '预计/实际起飞时间：',
    'schedule.gantt.tip_actual_land_time': '预计/实际落地时间：',
    'schedule.gantt.tip_aircraft_post': '机上岗位：',
    'schedule.gantt.tip_dynamic_location': '动态所在地：',
    'schedule.gantt.tip_working_remark': '运行备注：',
    'schedule.gantt.tip_remark': '备注：',
    'schedule.gantt.tip_type': '类型：',
    'schedule.gantt.tip_start': '开始时间：',
    'schedule.gantt.tip_end': '结束时间：',
    'schedule.gantt.tip_train_type': '训练类型：',
    'schedule.gantt.menu_deleteGroup': '删除组环',
    'schedule.gantt.menu_deleteSchedule': '删除排班',
    'schedule.gantt.menu_changeGroup': '组环互换',
    'schedule.gantt.menu_remarksManage': '备注管理',
    'schedule.gantt.menu_updateSign': '签到/车程维护',
    'schedule.gantt.menu_viewActualDuty': '查看实际值勤',
    'schedule.gantt.menu_linkGroup': '组环连线',
    'schedule.gantt. menu_deleteDynamic': '删除动态',
    'schedule.gantt.linkGroup_limit': '只允许对同一人的两个组环进行连线',
    'schedule.gantt.form_signTime': '签到时间',
    'schedule.gantt.form_startDriveTime': '开始车程',
    'schedule.gantt.form_endDriveTime': '结束车程',
    'schedule.gantt.form_hour': '时',
    'schedule.gantt.form_minute': '分',
    'schedule.gantt.form_second': '秒',
    'schedule.gantt.menu_deleteDynamic': '删除机组动态',
    'schedule.gantt.validate_startTime': '开始时间必须早于结束时间',
    'schedule.gantt.validate_endTime': '结束时间必须晚于开始时间',
    'schedule.menu.view-crew': '查看同班机组',
    'schedule.menu.view-people': '查看涉及人员',
    'schedule.menu.view-train': '查看本场训练',
    'schedule.menu.group.staff-arrange': '组环人员安排',
    'schedule.menu.flight.staff-arrange': '单段航班人员安排',
    'schedule.menu.view-flight': '单段航班详情',
    'schedule.dialog.training-name': '训练名称',
    'schedule.dialog.content-introduction': '内容介绍',
    'schedule.dialog.participants': '参训人员',
    'schedule.dialog.staff-arrange': '组环号位安排',
    'schedule.dialog.unlcok-edit': '锁定并编辑',
    'schedule.dialog.partPosts-required': '请选择兼职号位人员',
    'schedule.dialog.name-train': '培训名称',
    'schedule.validate.table.flight-date': '航班日期',
    'schedule.validate.table.flight-no': '航班号',
    'schedule.validate.table.name': '姓名',
    'schedule.validate.table.rule-desc': '规则描述',
    'schedule.validate.table.ignore': '忽略',
    'schedule.validate.dialog.title': '规则校验结果',
    'schedule.warn.search.name': '姓名',
    'schedule.warn.search.flight': '航班',
    'schedule.warn.search.rule': '规则',

    //机组排班操作日志
    'schedule-operation-log.table.scheduleType': '排班性质',
    'schedule-operation-log.table.flightDate': '航班日期',
    'schedule-operation-log.table.registrationNum': '飞机',
    'schedule-operation-log.table.flightNo': '航班号',
    'schedule-operation-log.table.trips': '航程',
    'schedule-operation-log.table.isDelete': '是否删除',
    'schedule-operation-log.table.operatingTime': '操作时间',
    'schedule-operation-log.table.operatingPerson': '操作人',
    'schedule-operation-log.table.actionContent': '操作内容',
    'schedule-operation-log.table.status': '状态',
    'schedule-operation-log.table.startTime': '开始时间',
    'schedule-operation-log.table.endTime': '结束时间',

    //机载急救设施
    'airborneFirstAidFacilities-info.table.planeNo': '飞机号',
    'airborneFirstAidFacilities-info.table.first-aid-equipment-type': '急救设备类别',
    'airborneFirstAidFacilities-info.table.is-back-up': '是否备份',
    'airborneFirstAidFacilities-info.table.equipment-update-time': '设备更新时间',
    'airborneFirstAidFacilities-info.table.equipment-updater': '设备更新人',
    'airborneFirstAidFacilities-info.table.period-of-validity': '有效期',
    'airborneFirstAidFacilities-info.table.status': '设备状态',
    'airborneFirstAidFacilities-info.table.operation': '操作',
    'airborneFirstAidFacilities-info.table.planeType': '机型',
    'airborneFirstAidFacilities-info.table.export': '导出',

    //休假管理
    'recuperation-info.table.plane-name': '机组姓名',
    'recuperation-info.table.year': '年度',
    'recuperation-info.table.total-recuperation': '疗养假总数',
    'recuperation-info.table.total-recuperation-taken': '已休假总数',
    'recuperation-info.table.total-recuperation-surplus': '剩余假期总数',
    'recuperation-info.actions.vacation-history': '休假历史',
    'recuperation-info.table.place-name': '疗养地点',
    'recuperation-info.table.recuperation-begintime': '疗养开始时间',
    'recuperation-info.table.recuperation-endtime': '疗养结束时间',
    'recuperation-info.table.recuperation-day': '疗养天数',

    //过夜统计
    'overnightStatistics-info.table.job-number': '工号',
    'overnightStatistics-info.table.staff-type': '人员类别',
    'overnightStatistics-info.table.name': '姓名',
    'overnightStatistics-info.table.overnight-days': '过夜天数',
    'overnightStatistics-info.table.days-of-preparation': '备勤天数（含外站）',
    'overnightStatistics-info.table.international-flight': '国际航班（0点后落地）',
    'overnightStatistics-info.table.start-time': '开始时间',
    'overnightStatistics-info.table.end-time': '结束时间',
    'overnightStatistics-info.table.export': '导出汇总表',
    'overnightStatistics-info.table.export-overnight-days': '导出过夜天数明细',
    'overnightStatistics-info.table.export-international-flight': '导出国际航班明细（0点后降落）',

    //虚拟充值
    'virtualRecharge-info.table.settings': '设置',
    'virtualRecharge-info.table.departure-breakfast': '出港正餐早餐',
    'virtualRecharge-info.table.departure-dinner': '出港正餐中晚餐',
    'virtualRecharge-info.table.departure-delay-dinner': '出港延误中晚餐',
    'virtualRecharge-info.table.return-regular-dinner': '回港正班中晚餐',
    'virtualRecharge-info.table.return-delay-breakfast': '回港延误早餐',
    'virtualRecharge-info.table.return-delay-dinner': '回港延误中晚餐',
    'virtualRecharge-info.table.backup-meals': '备份餐食',
    'virtualRecharge-info.table.recharge-amount': '充值金额',
    'virtualRecharge-info.table.base': '基地',
    'virtualRecharge-info.table.staff-type': '人员类别',
    'virtualRecharge-info.table.technicStandard': '技术等级',
    'virtualRecharge-info.table.breakfast-standard': '早餐标准',
    'virtualRecharge-info.table.lunch-standard': '午餐标准',
    'virtualRecharge-info.table.dinner-standard': '晚餐标准',

    //120天100小时监控

    'daysFlyHours-info.add': '新增机组监控',
    'daysFlyHours-info.delete': '删除机组监控',
    'daysFlyHours-info.update': '更新监控状态',
    'daysFlyHours-info.history': '查看历史记录',
    'daysFlyHours-info.history-show': '经历历史记录',
    'daysFlyHours-info.begin-time-from': '基准日期从',
    'daysFlyHours-info.begin-time-to': '到',
    'days-fly-hours.table.staff-id': '姓名',
    'days-fly-hours.table.staff-type': '人员类型',
    'days-fly-hours.table.staff-name': '姓名',
    'days-fly-hours.table.technic-id': '技术等级',
    'days-fly-hours.table.begin-time': '基准日期',
    'days-fly-hours.table.end-time': '到期日期',
    'days-fly-hours.table.finish-time': '停飞/成功日期',
    'days-fly-hours.table.actual-hours': '实际飞行小时',
    'days-fly-hours.table.status': '状态',
    'days-fly-hours.table.remark': '备注',

    //90天3次起落
    'daysFlyNums-info.stop': '查看停飞记录',
    'daysFlyNums-info.stop-show': '停飞记录',
    'daysFlyNums-info.begin-time-from': '基准日期从',
    'daysFlyNums-info.begin-time-to': '到',
    'days-fly-nums.table.staff-id': '姓名',
    'days-fly-nums.table.staff-name': '姓名',
    'days-fly-nums.table.technic-id': '技术等级',
    'days-fly-nums.table.technic-name': '技术等级',
    'days-fly-nums.table.begin-time': '基准日期',
    'days-fly-nums.table.end-time': '到期日期',
    'days-fly-nums.table.finish-time': '停飞日期',
    'days-fly-nums.table.actual-nums': '实际起落次数',
    'days-fly-nums.table.status': '状态',
    'days-fly-nums.table.remark': '备注',

    //经历监控
    'flyExperienceMonitor-info.from': '创建时间从',
    'flyExperienceMonitor-info.to': '到',
    'flyExperienceMonitor-info.history': '查看历史记录',
    'flyExperienceMonitor-info.base-info': '基本信息',
    'flyExperienceMonitor-info.restrict': '条件限制',
    'flyExperienceMonitor-info.target': '监控指标',
    'fly-experience-monitor.table.staff-id': '姓名',
    'fly-experience-monitor.table.tech-id': '技术等级',
    'fly-experience-monitor.table.monitor-type': '监控类型',
    'fly-experience-monitor.table.begin-time': '监控开始时间',
    'fly-experience-monitor.table.end-time': '监控结束时间',
    'fly-experience-monitor.table.need-post-id': '号位要求',
    'fly-experience-monitor.table.need-tech-id': '技术等级要求',
    'fly-experience-monitor.table.need-hours': '需经历时间',
    'fly-experience-monitor.table.need-up-and-downs': '需起落次数',
    'fly-experience-monitor.table.need-nops': '需操作次数',
    'fly-experience-monitor.table.actual-hours': '实际经历时间',
    'fly-experience-monitor.table.actual-up-and-downs': '实际起落次数',
    'fly-experience-monitor.table.actual-nops': '实际操作次数',
    'fly-experience-monitor.table.status-update-time': '结束日期',
    'fly-experience-monitor.table.remark': '备注说明',
    'fly-experience-monitor.table.status': '状态',
    'fly-experience-monitor.table.creater': '创建人',
    'fly-experience-monitor.table.createTime': '创建时间',
    'fly-experience-monitor.table.updater': '更新人',
    'fly-experience-monitor.table.updateTime': '更新时间',

    // 配置表
    'config-info.table.code': '代码',
    'config-info.table.name': '名称',
    'config-info.table.value': '值',
    'config-info.table.isHide': '是否隐藏',
    'config-info.table.rank': '排序',
    'config-info.table.updaterName': '更新人',
    'config-info.table.updateTime': '更新时间',
    'config-info.table.createrName': '创建人',
    'config-info.table.createTime': '创建时间',

    //号位组管理
    'crewSchedulePostGroup-info.table.scheduleType': '排班性质',
    'crewSchedulePostGroup-info.table.name': '号位组名称',
    'crewSchedulePostGroup-info.table.minNumber': '人数下限',
    'crewSchedulePostGroup-info.table.maxNumber': '人数上限',
    'crewSchedulePostGroup-info.table.planeType': '关联机型',
    'crewSchedulePostGroup-info.table.route': '关联航线',
    'crewSchedulePostGroup-info.table.plane': '关联飞机',
    'crewSchedulePostGroup-info.table.ruleLevel': '规则等级',
    'crewSchedulePostGroup-info.table.explain': '号位组说明',
    'crewSchedulePostGroup-info.table.allocation': '号位配置',
    'crewSchedulePostGroup-info.table.crewScheduleNo': '机组号位',
    'crewSchedulePostGroup-info.table.crewSchedule': '号位代码',
    'crewSchedulePostGroup-info.table.parttimePosition': '兼职号位',
    'crewSchedulePostGroup-info.table.route-name': '航线名称',
    'crewSchedulePostGroup-info.table.departureAirport': '起飞',
    'crewSchedulePostGroup-info.table.arriveAirport': '落地',
    'crewSchedulePostGroup-info.table.effectiveTime': '生效时间',
    'crewSchedulePostGroup-info.table.expiryTime': '截止时间',

    // 数据字典
    'data-dictionary-info.table.dataCode': '编码',
    'data-dictionary-info.table.dataName': '名称',
    'data-dictionary-info.table.dataEnglishName': '英文名称',
    'data-dictionary-info.table.rank': '排序',
    'data-dictionary-info.table.isModify': '可修改类型',
    'data-dictionary-info.table.extra-info': '扩展信息',
    'data-dictionary-info.table.ramark': '备注',
    'data-dictionary-info.table.updater': '更新人',
    'data-dictionary-info.table.updateTime': '更新时间',
    'data-dictionary-info.table.creater': '创建人',
    'data-dictionary-info.table.createTime': '创建时间',
    'data-dictionary-info.table.no-operation': '此数据不能操作',

    // 机组动态
    'aircrew-dynamic-info.table.staffIds': '姓名',
    'aircrew-dynamic-info.table.staffType': '人员类型',
    'aircrew-dynamic-info.table.beginDatetime': '开始时间',
    'aircrew-dynamic-info.table.dynamicType': '动态类型',
    'aircrew-dynamic-info.table.secondDynamicType': '二级动态类型',
    'aircrew-dynamic-info.table.endDatetime': '结束时间',
    'aircrew-dynamic-info.table.place': '动态所在地点',
    'aircrew-dynamic-info.table.isOnDuty': '是否计入值勤',
    'aircrew-dynamic-info.table.planeType': '机型',
    'aircrew-dynamic-info.table.isOnFlyDuty': '是否可计入飞行值勤期',
    'aircrew-dynamic-info.table.isContinuousCheck': '是否参加任务连续校验',
    'aircrew-dynamic-info.table.isSelfFlight': '是否本航',
    'aircrew-dynamic-info.table.remark': '补充内容',
    'aircrew-dynamic-info.table.departureAp': '起飞站',
    'aircrew-dynamic-info.table.arriveAp': '降落站',
    'aircrew-dynamic-info.table.flightDate': '航班日期',
    'aircrew-dynamic-info.table.flightNo': '航班号',
    'aircrew-dynamic-info.table.flightDynamicIds': '航班动态',
    'aircrew-dynamic-info.table.status': '发布状态',
    'aircrew-dynamic-info.table.post': '号位',
    'aircrew-dynamic-info.table.Updater': '更新人',
    'aircrew-dynamic-info.table.UpdateTime': '更新时间',
    'aircrew-dynamic-info.table.number': '人数',
    'aircrew-dynamic-info.table.routeName': '航线',
    'aircrew-dynamic-info.table.dateTimeRange': '时间段',

    //机组排班规则
    'flight-crew-schedule-rule-config-info.table.rule-name': '规则名称',
    'flight-crew-schedule-rule-config-info.table.tip-level': '提示等级',
    'flight-crew-schedule-rule-config-info.table.rank': '排序',
    'flight-crew-schedule-rule-config-info.table.is-effective': '是否有效',
    'flight-crew-schedule-rule-config-info.table.reload': '重新加载规则',
    'flight-crew-schedule-rule-config-info.table.is-send-message': '是否推送消息提示',
    'flight-crew-schedule-rule-config-info.table.deleted-rule': '该操作将正式启用新加入代码中的规则，并初始化所有已删除的规则配置，是否继续？',

    //机组动态操作日志
    'aircrew-dynamic-operation-log.table.scheduleType': '排班性质',
    'aircrew-dynamic-operation-log.table.staffType': '分类',
    'aircrew-dynamic-operation-log.table.dynamicType': '动态类型',
    'aircrew-dynamic-operation-log.table.secondDynamicType': '二级动态类型',
    'aircrew-dynamic-operation-log.table.startTime': '开始时间',
    'aircrew-dynamic-operation-log.table.endTime': '结束时间',
    'aircrew-dynamic-operation-log.table.place': '动态所在地点',
    'aircrew-dynamic-operation-log.table.planeType': '机型',
    'aircrew-dynamic-operation-log.table.isOnDuty': '是否计入值勤',
    'aircrew-dynamic-operation-log.table.isOnFlyDuty': '是否计入飞行值勤',
    'aircrew-dynamic-operation-log.table.remark': '补充内容',
    'aircrew-dynamic-operation-log.table.isDelete': '是否删除',
    'aircrew-dynamic-operation-log.table.createTypeName': '创建方式',
    'aircrew-dynamic-operation-log.table.routeName': '航线',

    //个人中心
    'personal-center-info.tab.personal-base-info': '基本信息',
    'personal-center-info.tab.personal-experience-info': '经历信息',
    'personal-center-info.tab.personal-training-info': '训练情况',
    'personal-center-info.tab.personal-accident-info': '不安全事件',
    'personal-center-info.tab.personal-reward-and-punishment-info': '奖惩记录',
    'personal-center-info.tab.personal-schedule-info': '排班情况',
    'personal-center-info.tab.message-center': '消息中心',
    'personal-center-info.tab.base-info': '基本信息',
    'personal-center-info.tab.technical-grade-info': '技术等级',
    'personal-center-info.tab.qualifications-info': '运行资质',
    'personal-center-info.tab.licence-center': '证照信息',
    'personal-center-info.tab.growth-history': '成长履历',
    'personal-center-info.tab.time': '时间',
    'personal-center-info.tab.variation-classification': '变动分类',
    'personal-center-info.tab.variable-content': '变动内容',
    'personal-center-info.tab.variable-number': '变动文号',
    'personal-center-info.tab.training-plan': '训练计划',
    'personal-center-info.tab.training-record': '训练记录',
    'personal-center-info.tab.view-record-details': '查看记录详情',
    'personal-center-info.tab.title': '标题',
    'personal-center-info.tab.text': '正文',
    'personal-center-info.tab.message-class': '消息类别',
    'personal-center-info.tab.read-state': '读取状态',
    'personal-center-info.tab.push-time': '推送时间',
    'personal-center-info.tab.query-keywords': '查询关键字',
    'personal-center-info.tab.isRead': '是否已读',
    'personal-center-info.tab.queryDate': '查询时间',

    //酒精检测监控
    'alcohol-check-info.tab.flight-date': '航班日期',
    'alcohol-check-info.tab.flight-number': '航班号',
    'alcohol-check-info.tab.originating-station': '始发站点',
    'alcohol-check-info.tab.crew-name': '机组姓名',
    'alcohol-check-info.tab.prepare-begintime': '准备开始时间',
    'alcohol-check-info.tab.prepare-endtime': '准备截止时间',
    'alcohol-check-info.tab.overdue-reminder': '逾期提醒',
    'alcohol-check-info.tab.detection-time': '检测时间',
    'alcohol-check-info.tab.alcohol-test-value': '酒精检测值',
    'alcohol-check-info.tab.alcohol-test-value-number': '酒精检测值必须为数字值',
    'alcohol-check-info.tab.alcohol-test-results': '酒精检测结果',
    'alcohol-check-info.tab.photographic-compare': '酒测照片对比',
    'alcohol-check-info.tab.isArtificial': '人工标识',
    'alcohol-check-info.tab.flight-surgeon': '航医',
    'alcohol-check-info.tab.operation': '操作',
    'alcohol-check-info.tab.only-exceptions': '只看异常',
    'alcohol-check-info.tab.artificial-matching': '人工匹配',
    'alcohol-check-info.tab.manual-entry': '人工录入',
    'alcohol-check-info.tab.manual-entry-results': '测试结果人工录入',
    'alcohol-check-info.tab.artificial-matching-results': '人工匹配检测数据',
    'alcohol-check-info.tab.begintime': '开始时间',
    'alcohol-check-info.tab.endtime': '结束时间',
    'alcohol-check-info.tab.instrument-address': '仪器地址',
    'alcohol-check-info.tab.alcohol-value': '酒精值',
    'alcohol-check-info.tab.name': '姓名',
    'alcohol-check-info.tab.time': '时间',
    'alcohol-check-info.tab.job-number': '工号/卡号',
    'alcohol-check-info.tab.status': '状态',
    'alcohol-check-info.tab.revoke': '撤销',
    'alcohol-check-info.tab.grounding-of-aircraft': '停飞处理',
    'alcohol-check-info.tab.select-name': '当前所选机组姓名：',
    'alcohol-check-info.tab.ID-Photo': '证件照',
    'alcohol-check-info.tab.alcohol-Photo': '酒测照片',
    'alcohol-check-info.tab.select-confirm': '确认关联',
    'alcohol-check-info.tab.select-instrument-address': '请选择仪器地址',

    //特殊限制维护
    'special-restriction-info.tab.staff-type': '人员类别',
    'special-restriction-info.tab.crew-name': '机组姓名',
    'special-restriction-info.tab.restriction-type': '限制类型',
    'special-restriction-info.tab.restriction-crew': '限制号位',
    'special-restriction-info.tab.restriction-airpot': '限制机场',
    'special-restriction-info.tab.restriction-flight-hour': '限制月飞行小时',
    'special-restriction-info.tab.begintime': '生效开始时间',
    'special-restriction-info.tab.endtime': '生效结束时间',
    'special-restriction-info.tab.remark': '备注',

    //停复飞管理
    'stop-recover-fly-info.tab.staff-type': '人员类别',
    'stop-recover-fly-info.tab.crew-name': '机组姓名',
    'stop-recover-fly-info.tab.stop-fly-type': '停飞类型',
    'stop-recover-fly-info.tab.stop-fly-time': '停飞时间',
    'stop-recover-fly-info.tab.stop-fly-diagnosis': '停飞诊断',
    'stop-recover-fly-info.tab.stop-fly-diagnosis-person': '停飞诊断人',
    'stop-recover-fly-info.tab.stop-fly-diagnosis-time': '停飞诊断时间',
    'stop-recover-fly-info.tab.recover-fly-diagnosis-person': '复飞诊断人',
    'stop-recover-fly-info.tab.recover-fly-diagnosis-time': '复飞时间',
    'stop-recover-fly-info.tab.estimate-recover-fly-diagnosis-time': '预计复飞时间',
    'stop-recover-fly-info.tab.stop-fly-day': '停飞天数',
    'stop-recover-fly-info.tab.export': '导出',
    'stop-recover-fly-info.tab.release': '发布',
    'stop-recover-fly-info.tab.status': '发布状态',
    'stop-recover-fly-info.tab.releaseSuccess': '发布成功',

    //乘务实力报表
    'crew-strength-report.tab.queryMonth': '查询月份',
    'crew-strength-report.tab.month': '月份',
    'crew-strength-report.tab.planeType': '机型',
    'crew-strength-report.tab.crew-allocation': '客舱乘务人员配备',
    'crew-strength-report.tab.fly-time': '飞机飞行小时',
    'crew-strength-report.tab.product': '乘积',
    'crew-strength-report.tab.total-of-crew': '月末客舱乘务员总数',
    'crew-strength-report.tab.total-of-purser': '月末乘务长总人数',
    'crew-strength-report.tab.form.startTime': '请选择查询月份',
    //首页图表
    'main-home.chart.personnel-strength-change-query': '人员实力变化查询',
    'main-home.chart.beginTime': '开始时间',
    'main-home.chart.endTime': '结束时间',
    'main-home.chart.staff-type': '人员类别',
    'main-home.chart.technical-grade': '技术等级',
    'main-home.chart.promotion-this-month-query': '技术级别变动人员',
    'main-home.chart.pilot': '飞行员',
    'main-home.chart.flight-attendant': '乘务员',
    'main-home.chart.safety-director ': '安全员',
    'main-home.chart.despatcher ': '签派员',
    'main-home.chart.maintenance-staff ': '维修人员',
    'main-home.chart.special-airport-qualifications-query': '特殊机场资质查询',
    'main-home.chart.international-route-qualifications-query': '国际航线资质查询',
    'main-home.chart.number-of-people': '人数',
    'main-home.chart.time': '时间',
    'main-home.chart.people': '人',
    'main-home.chart.name': '姓名',
    'main-home.chart.seatName': '席位',
    'main-home.chart.phone': '电话',
    'main-home.chart.userName': '值班人',
    'main-home.chart.promotion-time': '晋级时间',
    'main-home.chart.personnel-strength-query': '人员实力查询',
    'main-home.chart.query-base-time': '查询基准日期',
    'main.home-index.layout.action.retry-btn': '重试',
    'main.home-index.layout.request.init-failed': '请求失败',
    'main.home-index.layout.action.edit-btn': '修改',
    'main-home.chart.get-staff-recent-flights': '我的航班计划',
    'main-home.chart.get-staff-recent-flights-describe': '获取未来7天及过去3天的航班任务',
    'main-home.chart.get-staff-recent-flights-future-7-days': '未来7天',
    'main-home.chart.get-staff-recent-flights-history-3-days': '过去3天',
    'form.common.new': '新建',
    'item.name': '名字',
    'main.home-index.layout.widget.failed': '无权限',
    'main.home-index.layout.panel.close-btn': '关闭',
    'item.description': '描述',
    'main.home-index.layout.dialog.title': '选择小部件',
    'main.home-index.layout.dialog.widget-name': '小部件名称',
    'main.home-index.layout.dialog.widget-description': '小部件描述',
    'msg.save.success': '保存成功',
    'msg.save.fail': '保存失败',
    'main-home.chart.flight-no': '航班号',
    'main-home.chart.plane-no': '飞机号',
    'main-home.chart.flight-date': '航班日期',
    'main-home.chart.plane-type': '飞机型号',
    'main-home.chart.get-today-seat-duty': '今日值班',
    'main-home.chart.get-last-flight-dynamic-adjust-notice': '航班调整通知',
    'main-home.chart.get-fly-experience': '重要人员监控',
    'main-home.chart.get-alcohol-detection-today': '今日航班准备情况',
    'main-home.chart.theme': '主题',
    'main-home.chart.contact-information': '联系方式',
    'main-home.chart.send-date': '发送日期',
    'main-home.chart.get-seat-duty': '值班安排',
    'main-home.chart.time-span': '时间段',
    'main-home.chart.get-more-seat-duty': '更多',

    //角色管理
    'role-management.table.index': '序号',
    'role-management.table.code': '代码',
    'role-management.table.name': '名称',
    'role-management.table.roleName': '角色名称',
    'role-management.table.roleType': '角色类型',
    'role-management.table.select-role': '请选择角色',
    'role-management.table.state': '状态',
    'role-management.table.description': '描述',
    'role-management.table.language': '语言',
    'role-management.table.enabled': '启用',
    'role-management.table.disabled': '禁用',

    //权限管理
    'auth-management.tab.basic': '基本信息',
    'auth-management.table.code': '代码',
    'auth-management.table.parent-code': '父节点代码',
    'auth-management.table.parent-code-none': '父节点不存在',
    'auth-management.table.uri': '节点地址',
    'auth-management.table.languageType': '语言类别',
    'auth-management.table.name': '名称翻译',
    'auth-management.table.description': '描述翻译',
    'auth-management.tab.menu': '菜单信息',
    'auth-management.table.showType': '显示类型',
    'auth-management.table.styleClassName': '样式名称',
    'auth-management.table.icon': '图标',
    'auth-management.table.optionList': '扩展信息',
    'auth-management.table.tabsViewName': '选择卡名称翻译',
    'auth-management.table.menuViewName': '菜单名称翻译',
    'auth-management.table.addCode': '添加节点',
    'auth-management.table.editCode': '修改节点',

    //用户管理
    'user-management.table.account': '用户账号',
    'user-management.table.state': '启用状态',
    'user-management.table.isDeleted': '是否删除',
    'user-management.table.rank': '排序',
    'user-management.table.password': '用户密码',
    'user-management.table.type': '用户类型',
    'user-management.enable': '启用',
    'user-management.disable': '禁用',
    'user-management.table.extraInfo': '附加信息',
    'user-management.table.key': '键',
    'user-management.table.value': '值',
    'user-management.table.action': '操作',
    'user-management.placeholder.password': '留空表示不修改密码',
    'user-management.tip.not-allow-edit': '不允许修改该用户',

    //用户授权
    'user-authorization.table.name': '姓名',
    'user-authorization.table.email': '邮箱',
    'user-authorization.table.mobile': '移动电话',
    'user-authorization.table.organization': '(组织机构)',
    'user-authorization.table.user': '(用户)',
    'user-authorization.table.account': '账号',
    'user-authorization.table.global-search': '全局搜索',

    //角色数据权限管理
    'role-data-management.table.role-name': '角色名称',
    'role-data-management.table.data-type': '数据权限类型',
    'role-data-management.table.mobile': '移动电话',

    'calebdar.next-month': '下个月',
    'calebdar.prev-month': '上个月',

    //消息推送配置
    'message-push-configuration.table.account': '帐号',
    'message-push-configuration.table.name': '姓名',
    'message-push-configuration.table.department': '部门',
    'message-push-configuration.table.message-type': '消息类型',
    'message-push-configuration.table.send-type': '发送方式',
    'message-push-configuration.table.batch-modification-send-type': '批量修改发送方式',

    //同步任务触发菜单
    'synchronized-trigger.button.special-staff-trigger': '特业定时任务触发',
    'synchronized-trigger.button.technical-flight-matching': '技术检查航班匹配任务',
    'synchronized-trigger.button.reminder-technical-result': '技术检查结果录入提醒',
    'synchronized-trigger.button.calculate-training-results': '计算本场训练结果',
    'synchronized-trigger.button.notify-results-to-trainingSystem': '通知本场训练的结果给训练系统',
    'synchronized-trigger.button.synchronization-experience': '同步教育/工作经历',
    'synchronized-trigger.button.hour-report-timing': '120天100小时报表定时任务',
    'synchronized-trigger.button.fly-report-timing': '90天3次起落报表定时任务',
    'synchronized-trigger.button.experience-monitoring': '经历监控定时任务',
    'synchronized-trigger.button.synchronized-training-system': '同步培训系统的大纲',
    'synchronized-trigger.button.trainingSystem-dynamic-results': '通知培训系统机组动态结果',
    'synchronized-trigger.button.medical-certificate': '同步体检合格证信息',
    'synchronized-trigger.button.alcohol-detection-data': '同步所有酒精检测数据',
    'synchronized-trigger.button.check-grounding-information': '检查所有停飞信息',
    'synchronized-trigger.button.personnel-authorization-status': '更新人员授权状态',
    'synchronized-trigger.button.virtual-recharge': '虚拟充值定时任务',
    'synchronized-trigger.button.administrative-class': '定时生成行政班',
    'synchronized-trigger.button.staff-info': '同步所有特业人员的信息',
    'synchronized-trigger.button.credential-base-info': '同步所有凭证基础信息',
    'synchronized-trigger.button.user-credential-info': '同步一段时间里的用户凭证信息',
    'synchronized-trigger.button.post-schedule': '同步所有号位',
    'synchronized-trigger.button.group-loop': '同步一段时间里的组环信息',
    'synchronized-trigger.button.flight-schedule': '同步一段时间里的排班信息',
    'synchronized-trigger.button.aircrew-dynamic': '同步一段时间里的机组动态信息',
    'synchronized-trigger.button.AOC-update-trigger': 'AOC同步任务触发',
    'synchronized-trigger.button.beginTime': '开始时间',
    'synchronized-trigger.button.endTime': '结束时间',
    'synchronized-trigger.button.trigger-condition': '触发条件',
    'synchronized-trigger.button.check-Release-Schedule': '定时检验次日未发布排班及动态',
    'synchronized-trigger.button.set-user-credential-status': '更新人员凭证状态',
    'synchronized-trigger.button.maintain-schedule': '按照车间轮换生成维修人员业务班',

    //白名单
    'white-list.table.configurationCategory1': '配置类别（1）',
    'white-list.table.type1': '类型（1）',
    'white-list.table.configurationCategory2': '配置类别（2）',
    'white-list.table.type2': '类型（2）',
    'white-list.table.effective': '是否有效',
    'white-list.table.noUpdate': '此数据不可修改',
    'white-list.table.configuration1': '配置（1）',
    'white-list.table.configuration2': '配置（2）',
    'white-list.table.configurationCategory': '配置类别',
    'white-list.table.staffType': '人员类型',
    'white-list.table.dynamicType': '一级动态类型',
    'white-list.table.secondDynamicType': '二级动态类型',
    'white-list.table.stop-fly-type': '停飞类型',
    'white-list.table.seatName': '业务班名称',
    'white-list.table.staffType1': '人员类型（1）',
    'white-list.table.dynamicType1': '一级动态类型（1）',
    'white-list.table.secondDynamicType1': '二级动态类型（1）',
    'white-list.table.stop-fly-type1': '停飞类型（1）',
    'white-list.table.seatName1': '业务班名称（1）',
    'white-list.table.staffType2': '人员类型（2）',
    'white-list.table.dynamicType2': '一级动态类型（2）',
    'white-list.table.secondDynamicType2': '二级动态类型（2）',
    'white-list.table.stop-fly-type2': '停飞类型（2）',
    'white-list.table.seatName2': '业务班名称（2）',
    'white-list.table.staffTypeS': '人员类型*',
    'white-list.table.dynamicTypeS': '一级动态类型*',
    'white-list.table.secondDynamicTypeS': '二级动态类型*',
    'white-list.table.stop-fly-typeS': '停飞类型*',
    'white-list.table.seatNameS': '业务班名称*',
    'white-list.table.configurationCategoryS': '配置类别*',

    //餐食配置和统计
    'halal-meal-config.table.staff': '机组姓名',
    'halal-meal-config.table.meal-type': '餐食类型',
    'halal-meal-config.table.remark': '备注',
    'halal-meal-config.table.staff-type': '人员类型',
    'halal-meal-config.info.flight-date': '航班日期',
    'halal-meal-config.info.plane-type': '机型',
    'halal-meal-config.info.registration-num': '机号',
    'halal-meal-config.info.flight-no': '航班号',
    'halal-meal-config.info.flight-type': '航班性质',
    'halal-meal-config.info.pda': '起站',
    'halal-meal-config.info.paa': '落站',
    'halal-meal-config.info.ptd': '计划离港时间',
    'halal-meal-config.info.pta': '计划到港时间',
    'halal-meal-config.info.delay': '延误时间',
    'halal-meal-config.info.passenger-num': '旅客人数',
    'halal-meal-config.info.vip-num': '要客人数',
    'halal-meal-config.info.parking-place': '停机位',
    'halal-meal-config.info.responsible-captin': '机长(C/C)',
    'halal-meal-config.info.other-captin': '其余飞行',
    'halal-meal-config.info.attendant': '乘务',
    'halal-meal-config.info.security': '安全',
    'halal-meal-config.info.follow': '随机',
    'halal-meal-config.info.other-crew': '机务',
    'halal-meal-config.info.meal-total': '机组餐总人数',
    'halal-meal-config.info.halal-responsible-captin': '清真餐机长(C/C)',
    'halal-meal-config.info.halal-other-captin': '清真餐其余飞行',
    'halal-meal-config.info.halal-attendant': '清真餐乘务',
    'halal-meal-config.info.halal-security': '清真餐安全',
    'halal-meal-config.info.halal-follow': '清真餐随机',
    'halal-meal-config.info.halal-other-crew': '清真餐机务',
    'halal-meal-config.info.halal-meal-total': '清真餐总人数',
    'halal-meal-config.info.flight-date-begin': '航班日期从',
    'halal-meal-config.info.flight-date-end': '到',
    'halal-meal-config.info.flight-completed-status': '完成状态',
    'halal-meal-config.info.flight-dynamic-status': '执行状态',
    'halal-meal-config.info.birthday-crew': '生日人员',
    'halal-meal-config.info.only-export-days-limit': '每次只能导出不大于30天的数据',

    //机组小时统计
    'hour-statistics-staff-type': '人员类型',
    'hour-statistics-job-number': '工号',
    'hour-statistics-name': '姓名',
    'hour-statistics-post-name': '机上岗位',
    'hour-statistics-max-technical': '技术授权',
    'hour-statistics-check-or-instructional': '检查|带飞',
    'hour-statistics-flight-date': '航班日期',
    'hour-statistics-flight-no': '航班号',
    'hour-statistics-flight-type': '航班性质',
    'hour-statistics-flight-route-type': '航段性质',
    'hour-statistics-plane-type': '机型',
    'hour-statistics-registration-num': '注册机号',
    'hour-statistics-flight-execution-status': '航班执行状态',
    'hour-statistics-planning-departureAp': '离港航站',
    'hour-statistics-planning-arriveAp': '到港航站',
    'hour-statistics-ptd': '计划离港时刻',
    'hour-statistics-pta': '计划到港时刻',
    'hour-statistics-acars-out': 'Acars滑出时间',
    'hour-statistics-acars-off': 'Acars起飞时间',
    'hour-statistics-acars-on': 'Acars降落时间',
    'hour-statistics-acars-in': 'Acars滑入时间',
    'hour-statistics-qar-out': 'Qar滑出时间',
    'hour-statistics-qar-off': 'Qar起飞时间',
    'hour-statistics-qar-on': 'Qar落地时间',
    'hour-statistics-qar-in': 'Qar滑入时间',
    'hour-statistics-mission-out': '任务书滑出',
    'hour-statistics-mission-off': '任务书起飞',
    'hour-statistics-mission-on': '任务书降落',
    'hour-statistics-mission-in': '任务书滑入',
    'hour-statistics-qar-air-time': 'Qar空时',
    'hour-statistics-qar-land-time': 'Qar地时',
    'hour-statistics-qar-fly-time': 'Qar飞行时间',
    'hour-statistics-mission-air-time': '任务书空时',
    'hour-statistics-mission-land-time': '任务书地时',
    'hour-statistics-mission-fly-time': '任务书经历时间',
    'hour-statistics-plan-fly-time': '计划飞行时间',
    'hour-statistics-night-fly-air-time-qar-one': 'Qar空时夜航时间1',
    'hour-statistics-night-fly-air-time-qar-two': 'Qar空时夜航时间2',
    'hour-statistics-night-fly-time-qar-one': 'Qar夜航时间1',
    'hour-statistics-night-fly-time-qar-two': ' Qar夜航时间2',
    'hour-statistics.table.staffType': '人员类型',
    'hour-statistics.table.name': '姓名',
    'hour-statistics.table.start-time': '开始时间',
    'hour-statistics.table.end-time': '结束时间',
    'hour-statistics.table.flight-No': '航班号',
    //机组月小时统计
    'monthly-hour-statistics-staff-type': '人员类型',
    'monthly-hour-statistics-name': '姓名',
    'monthly-hour-statistics-january-qar-time': '一月Qar时间',
    'monthly-hour-statistics-january-mission-time': '一月任务书时间',
    'monthly-hour-statistics-february-qar-time': '二月Qar时间',
    'monthly-hour-statistics-february-mission-time': '二月任务书时间',
    'monthly-hour-statistics-march-qar-time': '三月Qar时间',
    'monthly-hour-statistics-march-mission-time': '三月任务书时间',
    'monthly-hour-statistics-april-qar-time': '四月Qar时间',
    'monthly-hour-statistics-april-mission-time': '四月任务书时间',
    'monthly-hour-statistics-may-qar-time': '五月Qar时间',
    'monthly-hour-statistics-may-mission-time': '五月任务书时间',
    'monthly-hour-statistics-june-qar-time': '六月任务书时间',
    'monthly-hour-statistics-june-mission-time': '六月任务书时间',
    'monthly-hour-statistics-jul-qar-time': '七月任务书时间',
    'monthly-hour-statistics-jul-mission-time': '七月任务书时间',
    'monthly-hour-statistics-august-qar-time': '八月Qar时间',
    'monthly-hour-statistics-august-mission-time': '八月任务书时间',
    'monthly-hour-statistics-september-qar-time': '九月Qar时间',
    'monthly-hour-statistics-september-mission-time': '九月任务书时间',
    'monthly-hour-statistics-october-qar-time': '十月任务书时间',
    'monthly-hour-statistics-october-mission-time': '十月任务书时间',
    'monthly-hour-statistics-november-qar-time': '十一月任务书时间',
    'monthly-hour-statistics-november-mission-time': '十一月任务书时间',
    'monthly-hour-statistics-december-qar-time': '十二月任务书时间',
    'monthly-hour-statistics-december-mission-time': '十二月任务书时间',
    'monthly-hour-statistics-total-qar-time': '总计Qar时间',
    'monthly-hour-statistics-total-mission-time': '总计任务书时间',
    'monthly-hour-statistics-total-mission.table.staffType': '人员类型',
    'monthly-hour-statistics-total-mission.table.name': '姓名',
    'monthly-hour-statistics-total-mission.table.year': '年份',
    'monthly-hour-statistics-total-mission.table.select-year': '请选择年份',
    //飞行机组飞行小时
    'flight-hour-statistics-left-sitting-time': '任务书左坐时间',
    'flight-hour-statistics-right-sitting-time': '任务书右坐时间',
    'flight-hour-statistics-total-sitting-time': '经历时间',
    'flight-hour-statistics-operate-count': '操作次数',
    'flight-hour-statistics-takeoff-count': '本场训练任务书起飞次数',
    'flight-hour-statistics-landing-count': '本场训练任务书降落次数',
    'flight-hour-statistics-night-fly-time-qar-total': 'Qar总夜航时间',
    //机组飞行值勤期统计
    'crew-fly-duty-fly-time': '飞行时间',
    'crew-fly-duty-fly-duty-time': '飞行值勤期',
    'fatigue-coefficient-fly-time': '当月所有持有效合格证的人员飞行时间',
    'fatigue-coefficient-total-person-number': '当月所有持有效合格证人数',
    'fatigue-coefficient-avg-fly-time': '平均飞行时间',

    //飞行任务书录入
    'flight-task-book.table.beginTime': '开始时间',
    'flight-task-book.table.endTime': '结束时间',
    'flight-task-book.table.task-book-status': '任务书状态',
    'flight-task-book.table.flightNo': '航班号',
    'flight-task-book.table.staff-name': '人员姓名',
    'flight-task-book.table.flight-date': '航班日期',
    'flight-task-book.table.flight-type': '航班性质',
    'flight-task-book.table.departureAp': '起飞站',
    'flight-task-book.table.arriveAp': '到达站',
    'flight-task-book.table.departure-Acars': 'Acars离港',
    'flight-task-book.table.arrive-Acars': 'Acars到港',
    'flight-task-book.table.acars-out': 'Acars滑出',
    'flight-task-book.table.acars-off': 'Acars起飞',
    'flight-task-book.table.acars-on': 'Acars落地',
    'flight-task-book.table.acars-in': 'Acars滑入',
    'flight-task-book.table.qar-out': 'Qar滑出',
    'flight-task-book.table.qar-off': 'Qar起飞',
    'flight-task-book.table.qar-on': 'Qar落地',
    'flight-task-book.table.qar-in': 'Qar滑入',
    'flight-task-book.table.out': '滑出时间',
    'flight-task-book.table.in': '滑入时间',
    'flight-task-book.table.off': '到港时间',
    'flight-task-book.table.on': '离港时间',
    'flight-task-book.table.confirmed': '是否审核',
    'flight-task-book.table.operation': '操作',
    'flight-task-book.table.examine': '审核',
    'flight-task-book.table.input-task-book': '录入任务书',
    'flight-task-book.table.train-task-book': '录入本场训练任务书',
    'flight-task-book.table.examine-confirm': '审核确认',
    'flight-task-book.table.examine-recall': '撤回审核',
    'flight-task-book.confirm.examine-recall': '确定撤回审核？',
    'flight-task-book.table.basic-information': '基本信息',
    'flight-task-book.table.operation-record': '操作记录',
    'flight-task-book.table.take-off-operator': '起飞操纵者',
    'flight-task-book.table.landing-operator': '着陆操纵者',
    'flight-task-book.table.TTG': '航时',
    'flight-task-book.table.fuel': '燃油',
    'flight-task-book.table.out-time': '滑出时刻',
    'flight-task-book.table.off-time': '起飞时刻',
    'flight-task-book.table.on-time': '降落时刻',
    'flight-task-book.table.in-time': '到位时刻',
    'flight-task-book.table.out-time-right': '正确的滑出时刻',
    'flight-task-book.table.off-time-right': '正确的起飞时刻',
    'flight-task-book.table.on-time-right': '正确的降落时刻',
    'flight-task-book.table.in-time-right': '正确的到位时刻',
    'flight-task-book.table.begin-time-right': '正确的开始时间',
    'flight-task-book.table.end-time-right': '正确的结束时间',
    'flight-task-book.table.guard-time-right': '正确的地面时间',
    'flight-task-book.table.flying-time': '空中时间',
    'flight-task-book.table.total-time': '总时间',
    'flight-task-book.table.consult-original-stock': '参考离港油量',
    'flight-task-book.table.consult-remain-oil': '参考剩油量',
    'flight-task-book.table.original-stock': '原存量',
    'flight-task-book.table.new-dosage': '新加量',
    'flight-task-book.table.total': '合计',
    'flight-task-book.table.remain-oil': '剩油量',
    'flight-task-book.table.staff': '人员',
    'flight-task-book.table.seat': '座位',
    'flight-task-book.table.subtotal': '小计',
    'flight-task-book.table.weight': '起飞权重',
    'flight-task-book.table.guard-time': '地面时间',
    'flight-task-book.table.landing-times': '着陆次数',
    'flight-task-book.table.go-around-times': '复飞次数',
    'flight-task-book.table.night-flight-time': '夜航时间',
    'flight-task-book.table.addition-person': '修改人',
    'flight-task-book.table.addition-time': '修改时间',
    'flight-task-book.table.auditor': '审核人',
    'flight-task-book.table.auditor-time': '审核时间',
    'flight-task-book.table.total-fly-time': '飞行总时间：',
    'flight-task-book.table.total-night-time': '夜航总时间：',
    'flight-task-book.table.total-times': '飞行总次数：',
    'flight-task-book.table.minute': '分钟',
    'flight-task-book.table.time': '次',

    //凭证规则配置
    'credential-rule-config-info.table.credentialType': '凭证类型',
    'credential-rule-config-info.table.country': '国家',
    'credential-rule-config-info.table.icaoCode': '机场',
    'credential-rule-config-info.table.ruleType': '凭证检测类型',
    'credential-rule-config-info.table.staffType': '人员类型',
    'credential-rule-config-info.table.credential': '凭证',
    'credential-rule-config-info.table.releaseCountry': '签发国',
    'credential-rule-config-info.table.level': '等级',
    'credential-rule-config-info.table.staffCredential': '人员凭证',
    'credential-rule-config-info.table.post': '号位',
    //菜单管理
    'MenuManagement.editCode': '编辑菜单',
    'MenuManagement.addCode': '添加菜单',
    'menuManagement.uri': '地址',
    'menuManagement.parentUri': '父级地址',
    'menuManagement.addCode': '地址',
    'menuManagement.languageType': '语言类别',
    'menuManagement.name': '名称',
    'menuManagement.menuViewName': '菜单名',
    'menuManagement.tabsViewName': '标签名',
    'menuManagement.rank': '排序',
    'menuManagement.isShow': '是否显示',

    //飞行操纵比配置
    'oper-rate-config.table.instructorTech': '教员技术级别',
    'oper-rate-config.table.cadetTech': '学员技术级别',
    'oper-rate-config.table.rate': '教员操纵百分比',
    'oper-rate-config.table.tech': '技术等级',

    //带飞检查操纵比
    'check-oper-rate.flightDate': '航班日期',
    'check-oper-rate.flightNo': '航班号',
    'check-oper-rate.pda': '起飞站',
    'check-oper-rate.paa': '到达站',
    'check-oper-rate.landLeftStaffName': '着陆时左座',
    'check-oper-rate.landRightStaffName': '着陆时右座',
    'check-oper-rate.leftSeatVStr': '左座操纵比',
    'check-oper-rate.rightSeatVStr': '右座操纵比',
    'check-oper-rate.checkStaffName': '带飞/检查',
    'check-oper-rate.checkedStaffName': '被带飞/被检查',
    'check-oper-rate.validNumStr': '参训人有效落地次数',
    'check-oper-rate.beginDate': '开始时间',
    'check-oper-rate.endDate': '结束时间',
    'check-oper-rate.staff': '姓名',

    //维修岗位管理
    'maintain-post-info.table.name': '岗位名称',
    'maintain-post-info.table.code': '岗位编码',
    'maintain-post-info.table.short-name': '岗位简称',
    'maintain-post-info.table.english-name': '岗位英文名',
    'maintain-post-info.table.category': '岗位类别',
    'maintain-post-info.table.max-number': '人员上限',
    'maintain-post-info.table.min-number': '人员下限',
    'maintain-post-info.table.post': '授权岗位',
    'maintain-post-info.table.project': '授权项目',
    'maintain-post-info.table.technical': '技术等级',
    'maintain-post-info.table.qualification': '运行资质',
    'maintain-post-info.table.license': '所需证照',
    'maintain-post-info.table.is-auto-schedule': '是否参与自动排班',
    'maintain-post-info.table.base': '所属基地',
    'maintain-post-info.table.rank': '排序',

    //安全疲劳系数
    'safety-fatigue-coefficient.table.year': '年份',
    'safety-fatigue-coefficient.table.name': '姓名',
    'safety-fatigue-coefficient.table.january': '一月',
    'safety-fatigue-coefficient.table.february': '二月',
    'safety-fatigue-coefficient.table.march': '三月',
    'safety-fatigue-coefficient.table.april': '四月',
    'safety-fatigue-coefficient.table.may': '五月',
    'safety-fatigue-coefficient.table.june': '六月',
    'safety-fatigue-coefficient.table.july': '七月',
    'safety-fatigue-coefficient.table.august': '八月',
    'safety-fatigue-coefficient.table.september': '九月',
    'safety-fatigue-coefficient.table.october': '十月',
    'safety-fatigue-coefficient.table.november': '十一月',
    'safety-fatigue-coefficient.table.december': '十二月',
    'safety-fatigue-coefficient.table.average': '月平均值勤时间',
    'safety-fatigue-coefficient.table.sum': '总值勤时间',
    'safety-fatigue-coefficient.table.color': '疲劳区',

    // 机组动态
    'workshop-group-info.table.release': '放行人员',
    'workshop-group-info.table.technician': '技术员',
    'workshop-group-info.table.mechanic': '机械员',
    'workshop-group-info.table.attendant': '勤务员',
    'workshop-group-info.table.student': '学员',
    'workshop-group-info.table.name': '姓名',
    'workshop-group-info.table.number': '班次',
    'workshop-group-info.table.maintenanceShift': '当前维修班次：',
    'workshop-group-info.table.used-power-vehicles': '已使用电源车数：',
    'workshop-group-info.table.switchingMaintenanceShift': '切换维修班次',
    'workshop-group-info.table.personnelSetUp': '在岗人员设置',
    'workshop-group-info.table.fixedPostArrange': '固定岗安排',
    'workshop-group-info.table.automaticScheduling': '自动排班',
    'workshop-group-info.table.validate': '校验',
    'workshop-group-info.table.validate-all': '校验所有',
    'workshop-group-info.table.validate-select': '校验所选',
    'workshop-group-info.table.empty': '清空',
    'workshop-group-info.table.empty-all': '校验所有',
    'workshop-group-info.table.empty-select': '校验所选',
    'workshop-group-info.table.publish': '发布',
    'workshop-group-info.table.publish-all': '校验所有',
    'workshop-group-info.table.publish-select': '校验所选',
    'workshop-group-info.table.lock': '锁定',
    'workshop-group-info.table.unlock': '解锁',
    'workshop-group-info.table.modify-staffing': '修改人员安排',
    'workshop-group-info.table.change-information': '信息变更',
    'workshop-group-info.table.pass': '放：',
    'workshop-group-info.table.technology': '技：',
    'workshop-group-info.table.machine': '机：',
    'workshop-group-info.table.duty': '勤：',
    'workshop-group-info.table.learn': '学：',
    'workshop-group-info.table.post': '岗位',
    'workshop-group-info.table.delete': '删除',
    'workshop-group-info.table.choice': '维修班次选择',
    'workshop-group-info.table.workSpace': '站点：',
    'workshop-group-info.table.workData': '日期：',
    'workshop-group-info.table.workType': '班种：',
    'workshop-group-info.table.workshopLeader': '车间带班：',
    'workshop-group-info.table.masterOnDuty': '主值班：',
    'workshop-group-info.table.deputyOnDuty': '副值班：',
    'workshop-group-info.table.materialGuarantee': '航材保障：',
    'workshop-group-info.table.changeGuarantee': '换轮保障：',
    'workshop-group-info.table.workshopStaff': '本车间人员',
    'workshop-group-info.table.supportStaff': '支援班人员',
    'workshop-group-info.table.workshop': '车间',
    'workshop-group-info.table.authorizedPosition': '授权岗位',
    'workshop-group-info.table.positionStatus': '在岗状态',
    'workshop-group-info.table.positionName': '岗位名称',
    'workshop-group-info.table.remark': '备注',
    'workshop-group-info.table.onDuty': '置为在岗',
    'workshop-group-info.table.notOnDuty': '置为不在岗',
    //航班岗位组管理
    'maintain-post-group-info.table.name': '岗位组名称',
    'maintain-post-group-info.table.remark': '岗位组说明',
    'maintain-post-group-info.table.min-number': '人员下限',
    'maintain-post-group-info.table.max-number': '人员上限',
    'maintain-post-group-info.table.plane-type': '关联机型',
    'maintain-post-group-info.table.plane': '关联飞机',
    'maintain-post-group-info.table.rule-level': '提示等级',
    'maintain-post-group-info.table.relate-category': '关联类别',
    'maintain-post-group-info.table.schedule-category': '排班类别',
    'maintain-post-group-info.table.department': '关联车间',
    'maintain-post-group-info.table.maintain-post-group-config': '岗位组配置',
    'maintain-post-group-info.table.maintain-post': '岗位',
    'maintain-post-group-info.table.remind-level': '提示级别',
    'maintain-post-group-info.table.check-max-number': '各岗位的人员下限之和不能大于岗位组配置的人员上限',
    'maintain-post-group-info.table.check-min-number': '各岗位的人员上限之和不能小于岗位组配置的人员下限',

    //维修设置
    'flightAnalysisInfo.flightDynamics': '航班动态(如:yyyymmdd xxxx ,其中xxxx为四位航班号)',
    'maintainMission.airportCode': '站点',
    'maintainMission.missionDate': '日期',
    'maintainMission.scheduleType': '班种',
    'maintainMission.switchMaintain': '切换维修班次',
    'maintainMission.addTask': '添加任务',
    'maintainMission.editTask': '编辑任务',
    'maintainMission.deleteTask': '删除任务',
    'maintainMission.transferDown': '交给下班次',
    'maintainMission.cancelHandover': '取消交接',
    'maintainMission.pleaseSwitchMaintain': '请先切换维修班次',
    'maintainMission.scheduleCategory': '排班类别',
    'maintainMission.registrationNum': '飞机号',
    'maintainMission.parkingPlace': '停机位',
    'maintainMission.flightType': '类型',
    'maintainMission.flightDate': '航班日期',
    'maintainMission.flightNo': '航班号',
    'maintainMission.flightArrivalTime': '计划进港',
    'maintainMission.flightDepartureTime': '计划出港',
    'maintainMission.route': '航线',
    'maintainMission.planStartTime': '计划到岗',
    'maintainMission.planEndTime': '计划离岗',
    'maintainMission.handoverType': '交接班',
    'maintainMission.isOurCompany': '外司',
    'maintainMission.remark': '备注',
    'maintainMission.task': '任务',
    'maintainMission.flightStart': '航班状态',
    'maintainMission.missionStart': '任务状态',
    'maintainMission.deleteMessage': '所选记录只能是外司和和本司航班类别为‘其他’排班',
    'maintainMission.isOurAirline': '是否本航',
    'maintainMission.planningArriveAp': '进港机场',
    'maintainMission.planningDepartureAp': '离岗机场',
    'maintainMission.Form.flightArrivalTime': '计划进港时间',
    'maintainMission.Form.flightDepartureTime': '计划出港时间',
    'maintainMission.Form.planStartTime': '计划到岗时间',
    'maintainMission.Form.planEndTime': '计划离岗时间',
    'maintainMission.flightArrivalTimeMustBeLessThanFlightDepartureTime': '计划进港时间必须小于计划离港时间',
    'maintainMission.personStartTimeMustBeLessThanPersonEndTime': '计划到岗时间必须小于计划离岗时间',
    'maintainMission.IsItNecessaryTransferDown': '确认交给下班次',
    //连续日历日监控表
    'continuous-calendar-day-monitor.table.staff-type': '人员类型',
    'continuous-calendar-day-monitor.table.job-number': '工号',
    'continuous-calendar-day-monitor.table.name': '姓名',
    'continuous-calendar-day-monitor.table.max-technical': '最高技术等级',
    'continuous-calendar-day-monitor.table.day': '连续工作天数',
    'continuous-calendar-day-monitor.table.start-time': '连续工作开始时间',
    'continuous-calendar-day-monitor.table.end-time': '连续工作结束时间',
    'continuous-calendar-day-monitor.table.resaon': '原因',

    //业扩
    businessApply: {
        form: {
            section1: '非居民客户新装、增/减容用电业务申请表',
            customerInfo: '用电方信息',
            customerName: '客户名称',
            useAddr: '用电地址',
            contactAddr: '通邮地址',
            zipCode: '邮政编码',
            faxes: '传真',
            customerType: '客户类型',
            customerIdentity: '客户身份',
            customerIndustry: '客户类别',
            idType: '证件类型',
            idNumber: '证件号码',
            agent: '业务经办人',
            agentTel: '联系电话',
            agentNo: '身份证号码',
            useRelName: '用电联系人',
            useRelTel: '联系电话',
            chargeRelName: '电费联系人',
            chargeRelTel: '联系电话',
            billEmail: '电子账单邮箱地址',
            smsTel: '手机短信号码',
            elecAssureType: '电费担保方式(租赁户)',
            businessContent: '业务内容',
            businessType: '业务类别',
            voltage: '供电电压',
            powerRequire: '电源要求',
            elecNature: '用电性质',
            tempDate: '临时用电时间',
            loadLevel: '负荷级别',
            elecType: '用电类别',
            oldCapacity: '原有容量(kW/kVA)',
            updateCapacity: '增/减容量(kW/kVA)',
            totalCapacity: '容量合计(kW/kVA)',
            elecBill: '电费结算',
            payType: '缴费方式',
            payTypeOther: '其它缴费方式',
            accountName: '账户名称',
            bankName: '银行名称',
            invoiceAccount: '发票账号',
            invoiceInfo: '增值税开票资料',
            invoiceType: '发票类型',
            taxpayerName: '纳税人名称',
            taxNumber: '税号',
            account: '账号',
            bank: '开户银行',
            declarationNote: '申请说明',
            customerNote: '客户说明',
            customerNoteContent: '本单位（个人）提供的申请信息和资料真实准确，如有虚假错漏，愿意承担相应的责任。',
            clickToSignDlg: '点击进入电子签章',
            equipmentName: '设备名称',
            equipmentType: '型号',
            nominalVoltage: '额定电压(kV)',
            singleCapacity: '单台容量(kW)',
            num: '数量',
            capacityCount: '容量小计',
            note: '备注',
            equipmentInfoList: '用电设备容量清单',
            equipment: '设备',
            addEquipment: '增加设备',
            total1: '用电负荷合计(kW)',
            total2: '装见容量合计(kVA)'
        }
    },

    reconnaissance: {
        form: {
            section1: '电力客户供电方案勘查表',
        }
    },

    electricPlan: {
        form: {
            electricSubstationAddr: '变电所所址型式',
            electricAccessWay: '高压侧电气主接线方式'

        }
    },
    //验收申请
    completionApply: {
        title: '验收申请文件',
    },
    completionAcceptance: {
        completionApplyFiles: '验收申请文件',
        operationTable: '现场作业表',
        customerInfo: '基本信息',
        customerCommitment: '客户承诺',
        standard: '竣工检验项目是否符合标准',
        rectify: '整改内容',
    }
};
