// 黄绿色
const yellowGreen = '#ccff00';
// 蓝色
const blue = '#0099ff';
// 绿色
const green = '#00ff99';
// 青色
const cyan = '#00ffff';
// 红色
const red = '#ff3366';
// 紫色
const purple = '#9011e1';
// 黄色
const yellow = '#ffff00';
// 浅蓝色
const lightBlue = '#00ccff';
// 白色
const white = '#ffffff';
//白色-透明度0.1
const whiteOpacity = 'rgba(255, 255, 255, 0.1)';
//灰色
const grey = '#545764';
// 浅灰色
const lightGrey = '#cccccc';
// echart中实心圆颜色
const filledCircle = 'rgba(0, 250, 247, 0.1)';

//dataV轮播图
const headerBGC = 'rgba(0, 250, 247, 0.3)';
const oddRowBGC = 'rgba(0, 250, 247, 0.2)';
const evenRowBGC = 'rgba(0, 250, 247, 0.1)';

const tooltip = {
    backgroundColor: '#01818c',
    borderColor: '#01ffff',
    extraCssText: 'opacity: 0.9',
    borderWidth: 1
};

export {
    yellowGreen,
    blue,
    green,
    cyan,
    red,
    purple,
    yellow,
    lightBlue,
    white,
    whiteOpacity,
    grey,
    lightGrey,
    filledCircle,
    headerBGC,
    oddRowBGC,
    evenRowBGC,
    tooltip
};
