import {cyan} from '@/components/widgets/constant';

export default {
    color: [
        '#007ed5',
        '#01d2d6',
        '#fefe00',
        '#a0a7e6',
        '#c4ebad',
        '#96dee8',
    ],
    backgroundColor: 'rgba(0,0,0,0)',
    textStyle: {},
    title: {
        textStyle: {
            color: '#666666',
        },
        subtextStyle: {
            color: '#999999',
        },
    },
    line: {
        itemStyle: {
            normal: {
                borderWidth: '2',
            },
        },
        lineStyle: {
            normal: {
                width: '3',
            },
        },
        symbolSize: '10',
        symbol: 'emptyCircle',
        smooth: true,
    },
    radar: {
        itemStyle: {
            normal: {
                borderWidth: '2',
            },
        },
        lineStyle: {
            normal: {
                width: '3',
            },
        },
        symbolSize: '10',
        symbol: 'emptyCircle',
        smooth: true,
    },
    bar: {
        itemStyle: {
            normal: {
                barBorderWidth: '0',
                barBorderColor: '#444444',
            },
            emphasis: {
                barBorderWidth: '0',
                barBorderColor: '#444444',
            },
        },
    },
    pie: {
        itemStyle: {
            normal: {
                borderWidth: '0',
                borderColor: '#444444',
            },
            emphasis: {
                borderWidth: '0',
                borderColor: '#444444',
            },
        },
    },
    scatter: {
        itemStyle: {
            normal: {
                borderWidth: '0',
                borderColor: '#444444',
            },
            emphasis: {
                borderWidth: '0',
                borderColor: '#444444',
            },
        },
    },
    boxplot: {
        itemStyle: {
            normal: {
                borderWidth: '0',
                borderColor: '#444444',
            },
            emphasis: {
                borderWidth: '0',
                borderColor: '#444444',
            },
        },
    },
    parallel: {
        itemStyle: {
            normal: {
                borderWidth: '0',
                borderColor: '#444444',
            },
            emphasis: {
                borderWidth: '0',
                borderColor: '#444444',
            },
        },
    },
    sankey: {
        itemStyle: {
            normal: {
                borderWidth: '0',
                borderColor: '#444444',
            },
            emphasis: {
                borderWidth: '0',
                borderColor: '#444444',
            },
        },
    },
    funnel: {
        itemStyle: {
            normal: {
                borderWidth: '0',
                borderColor: '#444444',
            },
            emphasis: {
                borderWidth: '0',
                borderColor: '#444444',
            },
        },
    },
    gauge: {
        itemStyle: {
            normal: {
                borderWidth: '0',
                borderColor: '#444444',
            },
            emphasis: {
                borderWidth: '0',
                borderColor: '#444444',
            },
        },
    },
    candlestick: {
        itemStyle: {
            normal: {
                color: '#d0648a',
                color0: '#ffffff',
                borderColor: '#d0648a',
                borderColor0: '#22c3aa',
                borderWidth: 1,
            },
        },
    },
    graph: {
        itemStyle: {
            normal: {
                borderWidth: '0',
                borderColor: '#444444',
            },
        },
        lineStyle: {
            normal: {
                width: 1,
                color: '#aaa',
            },
        },
        symbolSize: '10',
        symbol: 'emptyCircle',
        smooth: true,
        color: [
            '#4ea397',
            '#22c3aa',
            '#7bd9a5',
        ],
        label: {
            normal: {
                textStyle: {
                    color: '#ffffff',
                },
            },
        },
    },
    map: {
        itemStyle: {
            normal: {
                areaColor: '#eeeeee',
                borderColor: '#999999',
                borderWidth: '0.5',
            },
            emphasis: {
                areaColor: 'rgba(34,195,170,0.25)',
                borderColor: '#22c3aa',
                borderWidth: '0.5',
            },
        },
        label: {
            normal: {
                textStyle: {
                    color: '#28544e',
                },
            },
            emphasis: {
                textStyle: {
                    color: 'rgb(52,158,142)',
                },
            },
        },
    },
    geo: {
        itemStyle: {
            normal: {
                areaColor: '#eeeeee',
                borderColor: '#999999',
                borderWidth: '0.5',
            },
            emphasis: {
                areaColor: 'rgba(34,195,170,0.25)',
                borderColor: '#22c3aa',
                borderWidth: '0.5',
            },
        },
        label: {
            normal: {
                textStyle: {
                    color: '#28544e',
                },
            },
            emphasis: {
                textStyle: {
                    color: 'rgb(52,158,142)',
                },
            },
        },
    },
    categoryAxis: {
        axisLine: {
            show: true,
            lineStyle: {
                color: '#cccccc',
            },
        },
        axisTick: {
            show: false,
            lineStyle: {
                color: '#333333',
            },
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: '#999999',
            },
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: [
                    '#eeeeee',
                ],
            },
        },
        splitArea: {
            show: false,
            areaStyle: {
                color: [
                    'rgba(250,250,250,0.3)',
                    'rgba(200,200,200,0.3)',
                ],
            },
        },
    },
    valueAxis: {
        axisLine: {
            show: true,
            lineStyle: {
                color: '#cccccc',
            },
        },
        axisTick: {
            show: false,
            lineStyle: {
                color: '#333333',
            },
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: '#999999',
            },
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: [
                    '#eeeeee',
                ],
            },
        },
        splitArea: {
            show: false,
            areaStyle: {
                color: [
                    'rgba(250,250,250,0.3)',
                    'rgba(200,200,200,0.3)',
                ],
            },
        },
    },
    logAxis: {
        axisLine: {
            show: true,
            lineStyle: {
                color: '#cccccc',
            },
        },
        axisTick: {
            show: false,
            lineStyle: {
                color: '#333333',
            },
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: '#999999',
            },
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: [
                    '#eeeeee',
                ],
            },
        },
        splitArea: {
            show: false,
            areaStyle: {
                color: [
                    'rgba(250,250,250,0.3)',
                    'rgba(200,200,200,0.3)',
                ],
            },
        },
    },
    timeAxis: {
        axisLine: {
            show: true,
            lineStyle: {
                color: '#cccccc',
            },
        },
        axisTick: {
            show: false,
            lineStyle: {
                color: '#333333',
            },
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: '#999999',
            },
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: [
                    '#eeeeee',
                ],
            },
        },
        splitArea: {
            show: false,
            areaStyle: {
                color: [
                    'rgba(250,250,250,0.3)',
                    'rgba(200,200,200,0.3)',
                ],
            },
        },
    },
    toolbox: {
        iconStyle: {
            normal: {
                borderColor: '#aaaaaa',
            },
            emphasis: {
                borderColor: '#666',
            },
        },
    },
    legend: {
        textStyle: {
            color: '#999999',
        },
    },
    tooltip: {
        axisPointer: {
            lineStyle: {
                color: '#ccc',
                width: 1
            },
            crossStyle: {
                color: '#ccc',
                width: 1
            }
        }
    },
    timeline: {
        lineStyle: {
            color: '#349e8e',
            width: 1,
        },
        itemStyle: {
            normal: {
                color: '#349e8e',
                borderWidth: '1',
            },
            emphasis: {
                color: '#57e8d2',
            },
        },
        controlStyle: {
            normal: {
                color: '#349e8e',
                borderColor: '#349e8e',
                borderWidth: '0',
            },
        },
        checkpointStyle: {
            color: '#22c3aa',
            borderColor: 'rgba(34,195,170,0.25)',
        },
        label: {
            normal: {
                textStyle: {
                    color: '#349e8e',
                },
            },
        },
    },
    visualMap: {
        color: [
            '#d0648a',
            '#22c3aa',
            'rgba(123,217,165,0.2)',
        ],
    },
    dataZoom: {
        backgroundColor: '#fff',
        dataBackgroundColor: '#dedede',
        fillerColor: 'rgba(34,195,170,0.25)',
        handleColor: '#dddddd',
        handleSize: '100%',
        textStyle: {
            color: '#999',
        },
    },
    markPoint: {
        label: {
            normal: {
                textStyle: {
                    color: '#ffffff',
                },
            },
            emphasis: {
                textStyle: {
                    color: '#ffffff',
                },
            },
        },
    },
};
